var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',[_c('div',{staticClass:"container content-padding"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-item flex flex-col lg:flex-row w-full"},[_c('div',{staticClass:"w-full mb-16 xl:mb-0"},[_c('BlogItemLarge',_vm._b({},'BlogItemLarge',_vm.trendingPosts[0],false))],1),_vm._v(" "),_c('div',{staticClass:"header-sidebar flex-none lg:pl-12 lg:border-l border-navy-100 border-opacity-10 lg:ml-12"},_vm._l((_vm.trendingPosts.slice(1)),function(post,index){return _c('div',{key:index,class:index > 0
                  ? 'pt-6 border-t border-navy-100 border-opacity-10 mt-6'
                  : ''},[_c('BlogItemCompact',_vm._b({},'BlogItemCompact',post,false))],1)}),0)])])])]),_vm._v(" "),_c('section',[(_vm.featuredGuides.length)?_c('div',{staticClass:"container content-padding"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-item w-full"},[(_vm.hasMobileLayout)?_c('div',{staticClass:"px-6 md:px-12 py-12 -mx-6 md:-mx-12 bg-orange-100"},[_c('div',{staticClass:"mb-10"},[_c('h2',{staticClass:"heading-6 font-medium mb-2"},[_vm._v("\n                "+_vm._s(_vm.$t('pages.blogIndex.titles.featuredGuides'))+"\n              ")]),_vm._v(" "),_c('p',{staticClass:"mb-5 text-black text-opacity-50"},[_vm._v("\n                "+_vm._s(_vm.$t(
                    'pages.blogIndex.text.learnEverythingYouNeedToKnowAboutDeathInTheEyesOfT'
                  ))+"\n              ")]),_vm._v(" "),_c('NuxtLink',{staticClass:"font-medium uppercase underline opacity-50 hover:opacity-100 transition-opacity duration-75",attrs:{"to":_vm.localePath('/blog/guides')}},[_vm._v("\n                "+_vm._s(_vm.$t('pages.blogIndex.links.viewAllGuides'))+"\n              ")])],1),_vm._v(" "),_c('BlogGuideSlider',{attrs:{"items":_vm.featuredGuides}})],1):_c('div',{staticClass:"relative"},[_c('div',{staticClass:"absolute inset-0 rounded-xl -mx-12 bg-orange-100"}),_vm._v(" "),_c('div',{staticClass:"grid-container relative z-10 py-10"},[_c('div',{staticClass:"grid-item w-full"},[_c('div',{staticClass:"flex justify-between items-baseline"},[_c('div',[_c('h2',{staticClass:"heading-6 font-medium mb-2"},[_vm._v("\n                      "+_vm._s(_vm.$t('pages.blogIndex.titles.featuredGuides'))+"\n                    ")]),_vm._v(" "),_c('p',{staticClass:"mb-5 text-black text-opacity-50"},[_vm._v("\n                      "+_vm._s(_vm.$t(
                          'pages.blogIndex.text.learnEverythingYouNeedToKnowAboutDeathInTheEyesOfT'
                        ))+"\n                    ")])]),_vm._v(" "),_c('NuxtLink',{staticClass:"font-medium uppercase underline opacity-50 hover:opacity-100 transition-opacity duration-75",attrs:{"to":_vm.localePath('/blog/guides')}},[_vm._v("\n                    "+_vm._s(_vm.$t('pages.blogIndex.links.viewAllGuides'))+"\n                  ")])],1)]),_vm._v(" "),_vm._l((_vm.featuredGuides),function(guide,index){return _c('div',{key:index,staticClass:"grid-item md:w-6/12 lg:w-4/12"},[_c('BlogItemGuide',_vm._b({},'BlogItemGuide',guide,false))],1)})],2)])])])]):_vm._e(),_vm._v(" "),(_vm.featuredPost)?_c('div',{staticClass:"container content-padding"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-item w-full"},[_c('div',{staticClass:"pt-12 border-t border-navy-100 border-opacity-10"},[_c('h2',{staticClass:"mb-8 heading-6 font-medium"},[_vm._v("\n              "+_vm._s(_vm.$t('pages.blogIndex.titles.featuredArticle'))+"\n            ")]),_vm._v(" "),_c('BlogItemFeatured',_vm._b({staticClass:"mb-8"},'BlogItemFeatured',_vm.featuredPost,false))],1)])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"container content-padding"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-item w-full mb-10"},[_c('h2',{staticClass:"mb-8 heading-6 font-medium"},[_vm._v("\n            "+_vm._s(_vm.$t('pages.blogIndex.titles.latestArticles'))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap"},[_c('NuxtLink',{attrs:{"to":_vm.localePath('/blog/posts')}},[_c('BlogTopic',{staticClass:"mr-2 mb-2",attrs:{"active":""}},[_vm._v("\n                "+_vm._s(_vm.$t('common.titles.all'))+"\n              ")])],1),_vm._v(" "),_vm._l((_vm.blogTopics),function(ref){
                        var id = ref.id;
                        var title = ref.title;
return _c('NuxtLink',{key:id,staticClass:"inline-flex mr-2 mb-2",attrs:{"to":_vm.localePath(("/blog/posts?topic=" + id))}},[_c('BlogTopic',[_vm._v("\n                "+_vm._s(title)+"\n              ")])],1)})],2)]),_vm._v(" "),_vm._l((_vm.trimmedBlogPosts),function(post,index){return _c('div',{key:index,staticClass:"grid-item w-full md:w-6/12 lg:w-4/12 mb-8"},[_c('BlogItem',_vm._b({},'BlogItem',post,false))],1)}),_vm._v(" "),_c('div',{staticClass:"grid-item w-full text-center"},[_c('NuxtLink',{staticClass:"font-medium uppercase underline opacity-50 hover:opacity-100 transition-opacity duration-75",attrs:{"to":_vm.localePath('/blog/posts')}},[_vm._v("\n            "+_vm._s(_vm.$t('pages.blogIndex.links.seeMoreArticles'))+"\n          ")])],1)],2)]),_vm._v(" "),_c('div',{staticClass:"container content-padding"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-item w-full"},[_c('h2',{staticClass:"pt-12 border-t border-navy-100 border-opacity-10 mb-8 xl:mb-16 heading-6 xl:body-1 font-medium"},[_vm._v("\n            "+_vm._s(_vm.$t('pages.blogIndex.titles.writtenByTheSafewillTeam'))+"\n          ")])]),_vm._v(" "),_vm._l((_vm.featuredAuthors),function(author){return _c('div',{key:author.id,staticClass:"grid-item w-full md:w-6/12 mb-16"},[_c('BlogAuthorLarge',_vm._b({attrs:{"details":""}},'BlogAuthorLarge',author,false))],1)})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }