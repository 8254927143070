<template>
  <div
    class="ru:navigation__container"
    :class="`${navigationIsExpandedClass} ${mobileMenuIsOpenClass}`"
  >
    <div class="ru:navigation__backdrop" @click="$emit('toggleMobileMenu')" />
    <div ref="navigation" class="ru:navigation">
      <div class="ru:navigation__parents">
        <template v-for="(item, index) in navigationItems">
          <NavigationItemParent
            :key="`navigation__parent--${index}`"
            :ref="`navigation__parent--${index}`"
            :to="item.to"
            :is-active="isNavigationItemActive(item.label)"
            :has-children="!!item.children"
            @click="setActiveNavigationItem(item.label, !!item.children)"
          >
            {{ item.label }}
          </NavigationItemParent>
          <div
            v-if="item.children"
            :key="`navigation__children--${index}`"
            :ref="`navigation__children--${index}`"
            class="ru:navigation__children"
            :class="activeNavigationChildrenClass(item.label)"
          >
            <NavigationItemChild
              v-for="(child, index) in item.children"
              :key="`navigation__child--${index}`"
              :to="child.to"
            >
              {{ child.label }}
            </NavigationItemChild>
          </div>
        </template>
      </div>
      <div class="ru:navigation__app">
        <a
          v-if="!isReturning"
          class="ru:navigation__button ru:navigation__button--login"
          :href="loginLink"
          rel="noreferrer"
          target="_blank"
        >
          {{ $t('common.links.login') }}
        </a>
        <client-only>
          <OnboardingButton
            :to="isPoaPage ? poaLink : null"
            classes="ru:navigation__button ru:navigation__button--get-started"
          >
            {{ buttonLabel }}
          </OnboardingButton>
        </client-only>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import OnboardingButton from '~/components/OnboardingButton';
import NavigationItemParent from '~/components/atoms/NavigationItemParent';
import NavigationItemChild from '~/components/atoms/NavigationItemChild';

export default {
  name: 'OrganismsNavigation',
  components: {
    OnboardingButton,
    NavigationItemParent,
    NavigationItemChild,
  },
  props: {
    mobileMenuIsOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeNavigationItem: null,
      activeNavigationItemIsParent: false,
      navigationItems: [
        {
          label: 'Estate Planning',
          children: [
            {
              label: 'Wills',
              to: this.localePath('/wills'),
            },
            {
              label: 'Power of Attorney',
              to: this.localePath('/power-of-attorney'),
            },
            {
              label: 'Legal Advice',
              to: this.localePath('/legal-advice'),
            },
            {
              label: 'How it Works',
              to: this.localePath('/how-it-works'),
            },
            {
              label: 'Pricing',
              to: this.localePath('/pricing'),
            },
          ],
        },
        {
          label: 'Probate',
          children: [
            {
              label: 'Probate',
              to: 'https://safewilllegal.com.au/probate',
            },
            {
              label: 'Letters of Administration',
              to: 'https://safewilllegal.com.au/letters-of-administration',
            },
          ],
        },
        {
          label: 'Cremations',
          children: [
            {
              label: 'At Need Cremations',
              to: 'https://safewillcremations.com.au/',
            },
            {
              label: 'Prepaid Cremations',
              to: 'https://safewillcremations.com.au/prepaid',
            },
          ],
        },
        {
          label: 'Resources',
          children: [
            {
              label: 'Blog',
              to: this.localePath('/blog'),
            },
            {
              label: 'About Us',
              to: this.localePath('/about-us'),
            },
          ],
        },
        {
          label: 'For Charities',
          to: this.localePath('/charities'),
        },
      ],
      hasScrolled: false,
    };
  },
  computed: {
    ...mapGetters(['hasOffer', 'isReturning', 'webfontActive']),
    isPoaPage() {
      return this.$route.path === this.localePath('/power-of-attorney');
    },
    poaLink() {
      return `${this.$config.safewillAppUrl}${this.localePath(
        `/get-started?redirect=/poa`
      )}`;
    },
    flatNavigationItemArray() {
      return this.navigationItems.flatMap((parent) => {
        return parent.to
          ? parent.to
          : parent.children.flatMap((child) => child.to);
      });
    },
    activeLinkIndex() {
      return this.flatNavigationItemArray.findIndex(
        (link) => link === this.$route.path
      );
    },
    buttonLabel() {
      return this.isReturning ? 'Finish your Will' : 'Get started';
    },
    hasBlogLayout() {
      return this.$route.fullPath.includes('/blog');
    },
    logoAriaLabel() {
      return this.hasBlogLayout ? 'blog' : 'homepage';
    },
    logoLink() {
      return this.localePath(this.hasBlogLayout ? '/blog' : '/');
    },
    loginLink() {
      return `${this.$config.safewillAppUrl}${this.localePath('/log-in')}`;
    },
    navigationIsExpandedClass() {
      return this.activeNavigationItemIsParent ? '--parent-menu-open' : '';
    },
    mobileMenuIsOpenClass() {
      return this.mobileMenuIsOpen ? '--mobile-menu-open' : '';
    },
  },
  watch: {
    activeLinkIndex(to, from) {
      this.setTransitionDirection({ from, to });
    },
    $route() {
      if (this.mobileMenuIsOpen) this.$emit('toggleMobileMenu');
      this.identifyActiveNavigation();
    },
    webfontActive() {
      if (this.webfontActive) {
        this.measureNavigationParents();
      }
    },
  },

  mounted() {
    this.identifyActiveNavigation();
    if (this.webfontActive) {
      this.measureNavigationParents();
    }
  },
  methods: {
    ...mapActions(['setTransitionDirection']),
    measureNavigationParents() {
      let cumulativeOffset = 0;
      for (let index = 0; index < this.navigationItems.length; index++) {
        const parentRef = this.$refs[`navigation__parent--${index}`];
        const childRef = this.$refs[`navigation__children--${index}`];
        if (childRef) {
          childRef[0].style.setProperty('--offset', `${cumulativeOffset}px`);
        }
        cumulativeOffset += parentRef[0].$el.offsetWidth;
      }
    },
    activeNavigationItemClass(item) {
      return this.isNavigationItemActive(item)
        ? 'ru:navigation__parent--active'
        : '';
    },
    activeNavigationChildrenClass(item) {
      return this.isNavigationItemActive(item)
        ? 'ru:navigation__children--active'
        : '';
    },
    isNavigationItemActive(item) {
      return item === this.activeNavigationItem;
    },
    identifyActiveNavigation() {
      const path = this.$route.path;
      if (path !== this.localePath('/')) {
        const activeNavigationItem = this.navigationItems.find((item) => {
          return (
            item.to === path ||
            item.children?.find((child) => child.to === path)
          );
        });
        if (activeNavigationItem) {
          this.setActiveNavigationItem(
            activeNavigationItem.label,
            !!activeNavigationItem.children
          );
        }
      } else {
        this.setActiveNavigationItem(null, false);
      }
    },
    setActiveNavigationItem(item, hasChildren) {
      this.activeNavigationItem = item;
      this.activeNavigationItemIsParent = hasChildren;
    },
  },
};
</script>

<style lang="scss">
$navigation-max-width: 1010px;
.ru\: {
  &navigation {
    display: flex;
    flex-direction: column;
    transition: --out();
    @include mq('max-lg') {
      position: fixed;
      top: 0;
      height: 100vh;
      left: 100%;
      width: 20rem;
      background-color: --rgba(white);
      border-radius: 2rem 0 0 2rem;
      padding: 5rem #{calc(var(--gutter-outer) + var(--gutter-inner))} 5rem 2rem;
      @at-root .--mobile-menu-open & {
        transform: translateX(-100%);

        &__backdrop {
          display: block;
          position: fixed;
          width: 100vw;
          height: 100vh;
          left: 0;
          top: 0;
          background-color: rgb(0 0 0 / 25%);
        }
      }
    }
    @include mq('min-lg') {
      height: 100%;
      overflow-x: hidden;
      align-items: center;
      flex-direction: row;

      &__container {
        height: 100%;
        width: 100%;
      }
    }
    @at-root .--has-scrolled & {
      @include mq('max-lg') {
        top: 1.5rem;
        padding-top: 3.5rem;
      }
    }

    &__parents {
      transition: --out();
      position: relative;
      display: flex;
      @include mq('max-lg') {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 2rem;
        margin-right: #{calc(var(--gutter-outer) + var(--gutter-inner))};
      }
      @include mq('min-lg') {
        margin: 0 auto;
      }
      @at-root .--parent-menu-open & {
        @include mq('min-lg') {
          transform: translateY(-50%);
          transition: --in();
        }
      }
    }

    &__children {
      transition: --out();
      pointer-events: none;

      &--active {
        pointer-events: all;
      }
      @include mq('max-lg') {
        display: none;
        padding-bottom: 0.25rem;
        opacity: 0;
        transition: --out();

        &--active {
          transition: --in();
          display: block;
          opacity: 1;
        }
      }
      @include mq('min-lg') {
        display: flex;
        position: absolute;
        top: 0;
        margin-left: var(--offset);
        z-index: -1;
        transform: translateY(0%);
        opacity: 0;
        width: 100%;

        &--active {
          opacity: 1;
          transform: translateY(100%);
        }
      }
    }

    &__app {
      display: flex;
      flex-direction: column;
      margin-top: 0.25rem;
      @include mq('min-lg') {
        margin-top: 0;
        position: relative;
        flex-direction: row;
        background: linear-gradient(
          to bottom,
          --rgba(water) #{calc(50% + 1px)},
          transparent #{calc(50% + 1px)}
        );
      }
    }

    &__item {
      color: --rgb(basalt);
      line-height: 1;
      text-align: left;
    }

    &__parent {
      position: relative;
      transition: --out();

      &--active {
        pointer-events: none;
        @at-root .--parent-menu-open & {
          @include mq('min-lg') {
            &::before {
              transform: scaleX(1);
              transition: --in(0.35);
            }
          }
        }
      }
      @include mq('max-lg') {
        margin-bottom: 0.375rem;
        width: 100%;
      }

      &-content {
        display: block;
        position: relative;
        z-index: 1;
        padding: 0.875rem 0;
        border-radius: 1.5rem;
        overflow: hidden;
        @include mq('min-lg') {
          padding: 0.875rem 1.25rem;
          margin-bottom: 0;

          &::before,
          &::after {
            @include __pseudo;
            transition: --out();
            position: absolute;
          }

          &::before {
            top: 100%;
            left: 50%;
            width: 100%;
            height: 100%;
            background-color: rgb(0 217 192);
            z-index: -2;
            transform: rotate(0deg);
            transform-origin: top left;
          }

          &::after {
            border-radius: #{calc(1.5rem - 3px)};
            inset: 3px;
            z-index: -1;
          }
        }
      }
      @include mq('max-lg') {
        &:not(&--childless)::after {
          transition: --out();
          display: block;
          content: '';
          width: 20px;
          height: 12px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: #{calc(50% - 6px)};
          right: 10px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 12'%3E %3Cpath fill='%230F1548' d='M1.77.115 0 1.885l10 10 10-10-1.77-1.77L10 8.345 1.77.115Z'/%3E %3C/svg%3E ");
        }

        &--active:not(&--childless)::after {
          transition: --in();
          transform: rotate(-180deg);
        }
      }
      @include mq('min-lg') {
        &--active &-content {
          &::before {
            transition: --in();
            transform: rotate(-180deg) translateX(-50%) scale(3);
          }
        }

        &--active &-content,
        &:hover &-content {
          &::after {
            background-color: rgb(204 246 232);
            transition: --in();
          }
        }

        &::before {
          @include __pseudo;
          position: absolute;
          width: $navigation-max-width;
          bottom: 0;
          left: 50%;
          height: 3px;
          background-color: --rgba(turquoise);
          transform: scaleX(0);
          transform-origin: center left;
          transition: --out();
        }
      }
    }

    &__child {
      display: block;
      transition: --out();
      @include mq('max-lg') {
        padding: 0.5rem 0.875rem;
        margin-bottom: 0.25rem;

        &.nuxt-link-exact-active {
          color: --rgba(teal);
          border-radius: 1rem;
        }
      }
      @include mq('min-lg') {
        padding: 0.5rem 1.0625rem;
        margin: 0.375rem 0.1875rem;
        border-radius: 1rem;

        &.nuxt-link-exact-active {
          background: --rgba(ice);
        }
      }

      &.nuxt-link-exact-active,
      &:hover {
        transition: --in();
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;
      font-weight: 500;

      &--login {
        color: --rgba(navy);
        outline: 2px solid --rgba(navy);
        outline-offset: -2px;
        margin-bottom: 0.5rem;
        padding: 0.875rem 2.0625rem;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: rgb(204 246 232);
          outline: none;
        }

        @include mq('min-lg') {
          outline: none;
          margin-right: 1rem;
          margin-bottom: 0;
        }

        @include mq('min-sm') {
          padding: 0.5rem 1.0625rem;
        }
      }

      &--get-started {
        padding: 0.875rem 2.0625rem;
        background-color: --rgba(navy);
        color: white;

        &:hover {
          background-color: --rgba(ebony);
        }

        @include mq('min-sm') {
          padding: 0.5rem 2.0625rem;
        }
      }
    }
  }
}
</style>
