<template>
  <div>
    <h3 v-text="title" />
    <div class="text-black">
      <h4 class="mb-5 xl:mb-8 heading-5 md:heading-4 lg:heading-5 xl:heading-4">
        <MarkedText
          :text="heading"
          class="text-white"
          stroke-width="20"
          type="line-b"
        />
      </h4>
      <!-- eslint-disable vue/no-v-html -->
      <p class="whitespace-pre-line" v-html="body" />
      <!--eslint-enable-->
    </div>
  </div>
</template>

<script>
import MarkedText from '~/components/MarkedText';

export default {
  name: 'WillFeatureText',
  components: {
    MarkedText,
  },
  props: {
    body: {
      required: true,
      type: String,
    },
    heading: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
  },
};
</script>
