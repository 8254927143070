var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseHeader',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('h1',{staticClass:"mb-6 xl:mb-16 heading-3 xl:heading-1"},[_c('MarkedText',{staticClass:"text-teal-100",attrs:{"text":_vm.headerContent.heading,"type":"circle-fill"}})],1),_vm._v(" "),_c('p',{staticClass:"mb-6 xl:mb-10 body-5 xl:body-3 whitespace-pre-line",domProps:{"textContent":_vm._s(_vm.headerContent.body)}}),_vm._v(" "),_c('div',{staticClass:"mb-4 lg:mb-0"},[_c('OnboardingButton',{attrs:{"to":_vm.poaLink,"arrow":"","size":"large"}},[_vm._v("\n          "+_vm._s(_vm.$t('common.buttons.getStartedNow'))+"\n        ")])],1)]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"h-1/3vh lg:h-auto"},[_c('BaseAsset',{attrs:{"asset":_vm.headerContent.image,"fit":"cover","rounded":""}})],1)]},proxy:true}])}),_vm._v(" "),_c('section',[_c('div',{staticClass:"content-padding"},[_c('div',{staticClass:"bg-teal-100 bg-opacity-50 content-padding"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-item w-full"},[_c('div',{staticClass:"bg-white rounded-xl overflow-hidden"},[_c('h2',{staticClass:"text-3xl text-center pt-6 pb-12 px-12 leading-snug"},[_vm._v("\n                  "+_vm._s(_vm.$t(
                      'pages.powerOfAttorney.text.theEasiestWayToChooseSomeoneToLookAfterYourFinanci'
                    ))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"grid-container px-12"},[_c('div',{staticClass:"grid-item lg:w-2/3 leading-snug"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-item w-full md:w-6/12 mb-10"},[_c('BaseGlyph',{attrs:{"type":"wallet-bg","classes":"mb-4 lg:mb-5 w-8 h-8"}}),_vm._v(" "),_c('h4',{staticClass:"text-lg lg:text-xl mb-1 lg:mb-2"},[_vm._v("\n                          "+_vm._s(_vm.$t(
                              'pages.powerOfAttorney.titles.financialDecisions'
                            ))+"\n                        ")]),_vm._v(" "),_c('p',{staticClass:"text-base"},[_vm._v("\n                          "+_vm._s(_vm.$t(
                              'pages.powerOfAttorney.text.appointSomeoneToManageYourPropertyAndFinances'
                            ))+"\n                        ")])],1),_vm._v(" "),_c('div',{staticClass:"grid-item w-full md:w-6/12 mb-10"},[_c('BaseGlyph',{attrs:{"type":"pen-bg","classes":"mb-4 lg:mb-5 w-8 h-8"}}),_vm._v(" "),_c('h4',{staticClass:"text-lg lg:text-xl mb-1 lg:mb-2"},[_vm._v("\n                          "+_vm._s(_vm.$t('pages.powerOfAttorney.titles.legalAffairs'))+"\n                        ")]),_vm._v(" "),_c('p',{staticClass:"text-base"},[_vm._v("\n                          "+_vm._s(_vm.$t(
                              'pages.powerOfAttorney.text.chooseSomeoneYouTrustToManageLegalAffairs'
                            ))+"\n                        ")])],1),_vm._v(" "),_c('div',{staticClass:"grid-item w-full md:w-6/12 mb-10"},[_c('BaseGlyph',{attrs:{"type":"aidkit-bg","classes":"mb-4 lg:mb-5 w-8 h-8"}}),_vm._v(" "),_c('h4',{staticClass:"text-lg lg:text-xl mb-1 lg:mb-2"},[_vm._v("\n                          "+_vm._s(_vm.$t(
                              'pages.powerOfAttorney.titles.medicalTreatment'
                            ))+"\n                        ")]),_vm._v(" "),_c('p',{staticClass:"text-base"},[_vm._v("\n                          "+_vm._s(_vm.$t(
                              'pages.powerOfAttorney.text.giveInstructionsInAdvanceAroundMedicalTreatmentAnd'
                            ))+"\n                        ")])],1),_vm._v(" "),_c('div',{staticClass:"grid-item w-full md:w-6/12 mb-10"},[_c('BaseGlyph',{attrs:{"type":"house-bg","classes":"mb-4 lg:mb-5 w-8 h-8"}}),_vm._v(" "),_c('h4',{staticClass:"text-lg lg:text-xl mb-1 lg:mb-2"},[_vm._v("\n                          "+_vm._s(_vm.$t(
                              'pages.powerOfAttorney.titles.lifestyleDecisions'
                            ))+"\n                        ")]),_vm._v(" "),_c('p',{staticClass:"text-base"},[_vm._v("\n                          "+_vm._s(_vm.$t(
                              'pages.powerOfAttorney.text.protectYourLifestyleDecisionsIncludingWhereYouLive'
                            ))+"\n                        ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"grid-item hidden lg:block w-1/3"},[_c('BaseGlyph',{attrs:{"type":"shield-bg","classes":"w-full h-auto max-h-full mb-10"}})],1)])])])])])])]),_vm._v(" "),_c('div',{staticClass:"container content-padding"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-item w-full mx-auto mb-8 text-center"},[_c('SectionHeading',[_c('TextMarker',{staticClass:"text-teal-100",attrs:{"stroke-width":"9","type":"line-a"}},[_vm._v("\n              "+_vm._s(_vm.$t('pages.powerOfAttorney.titles.frequently'))+"\n            ")]),_vm._v("\n            "+_vm._s(_vm.$t('pages.powerOfAttorney.titles.askedQuestions'))+"\n          ")],1)],1),_vm._v(" "),_c('div',{staticClass:"grid-item w-full mb-10 md:mb-12 xl:mb-16"},[_c('Faqs',{attrs:{"items":_vm.questions}})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }