<template>
  <a
    :href="url"
    target="_blank"
    rel="noreferrer"
    class="button inline-block text-black hover:opacity-75 focus:opacity-100"
  >
    <svg
      class="fill-current"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        v-if="platform === 'facebook'"
        d="M17.563 1.005L14.786 1c-3.12 0-5.137 2.125-5.137 5.414v2.497H6.857c-.242 0-.437.2-.437.448v3.617c0 .248.196.449.437.449h2.792v9.126c0 .248.195.449.437.449h3.643a.443.443 0 00.437-.449v-9.126h3.265c.241 0 .436-.2.436-.449l.002-3.617a.456.456 0 00-.128-.317.431.431 0 00-.31-.131h-3.265V6.794c0-1.017.236-1.533 1.525-1.533h1.871c.241 0 .437-.202.437-.45V1.454a.443.443 0 00-.436-.448z"
      />
      <path
        v-else-if="platform === 'instagram'"
        d="M2 12c0-4.191 0-6.287 1.05-7.733A5.5 5.5 0 014.267 3.05C5.713 2 7.81 2 12 2s6.287 0 7.733 1.05a5.5 5.5 0 011.217 1.217C22 5.713 22 7.81 22 12s0 6.287-1.05 7.733a5.5 5.5 0 01-1.217 1.217C18.287 22 16.19 22 12 22s-6.287 0-7.733-1.05a5.5 5.5 0 01-1.217-1.217C2 18.287 2 16.19 2 12zm10-5.294a5.294 5.294 0 100 10.589 5.294 5.294 0 000-10.59zm0 8.603A3.313 3.313 0 018.691 12 3.313 3.313 0 0112 8.691 3.313 3.313 0 0115.309 12 3.313 3.313 0 0112 15.309zm6.397-9a.705.705 0 11-1.411 0 .705.705 0 011.41 0z"
        fill-rule="evenodd"
        clip-rule="evenodd"
      />
      <path
        v-else-if="platform === 'linkedin'"
        d="M21.995 22v-.001H22v-7.335c0-3.588-.772-6.352-4.967-6.352-2.017 0-3.37 1.106-3.923 2.155h-.058v-1.82H9.074v13.352h4.142v-6.612c0-1.74.33-3.424 2.486-3.424 2.124 0 2.156 1.987 2.156 3.536v6.5h4.137zM2.33 8.647h4.147V22H2.33V8.647zM4.402 2A2.402 2.402 0 002 4.402c0 1.325 1.076 2.424 2.402 2.424 1.325 0 2.401-1.098 2.401-2.424A2.403 2.403 0 004.402 2z"
      />
      <path
        v-else-if="platform === 'messenger'"
        d="M12 2C6.478 2 2 6.145 2 11.259c0 2.913 1.454 5.512 3.726 7.21V22l3.405-1.869c.909.252 1.871.388 2.869.388 5.523 0 10-4.145 10-9.259C22 6.146 17.523 2 12 2zm.994 12.469l-2.546-2.716-4.97 2.716 5.467-5.803 2.609 2.716 4.906-2.716-5.466 5.803z"
      />
    </svg>
  </a>
</template>

<script>
export default {
  name: 'SocialButton',
  props: {
    /* eslint-disable-next-line vue/prop-name-casing */
    __typename: {
      required: true,
      type: String,
    },
    platform: {
      required: true,
      type: String,
      validate(platform) {
        ['facebook', 'instagram', 'linkedin', 'messenger'].includes(platform);
      },
    },
    url: {
      required: true,
      type: String,
    },
  },
};
</script>
