<template>
  <section class="bg-teal-100 selection-bg-teal-100">
    <div class="container content-padding">
      <div class="grid-container lg:items-baseline">
        <div
          class="grid-item w-full md:w-10/12 lg:w-9/12 xl:w-6/12 2xl:w-5/12 mb-16 xl:mb-0"
        >
          <SectionHeading
            v-if="partnership"
            class="mb-8 lg:mb-10 xl:mb-8 heading-5 md:heading-4"
          >
            {{
              $t('components.onboardingSection.text.writeYourWillOnlineToday')
            }}
          </SectionHeading>
          <SectionHeading v-else>
            {{ onboardingSection.heading }}
          </SectionHeading>
          <p v-if="partnership" class="text-black text-opacity-70">
            {{
              $t(
                'components.onboardingSection.text.getStartedToSeeJustHowEasyWeveMadeItToWriteYourOwn'
              )
            }}
          </p>
          <!-- eslint-disable vue/no-v-html -->
          <p
            v-else
            class="text-black text-opacity-70"
            v-html="onboardingSection.body"
          />
          <!--eslint-enable-->
          <OnboardingButton class="my-8" arrow size="large">
            {{ $t('common.buttons.getStartedNow') }}
          </OnboardingButton>
          <ReviewSummary stars />
        </div>
        <div class="grid-item w-full lg:w-9/12 xl:w-6/12 xl:ml-auto">
          <h3 class="mb-6 xl:mb-8 2xl:mb-12 text-emerald-200 text-opacity-40">
            {{ $t('common.text.readAboutUsOn') }}
          </h3>
          <div class="grid-container">
            <div
              v-for="(brand, index) in filteredLogos"
              :key="index"
              class="grid-item flex justify-center md:justify-start items-center w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/3 mb-6 lg:mb-10 xl:mb-16"
            >
              <BrandLogos
                :brand="brand"
                :fill="
                  require('~/tailwind.config.js').theme.colors.emerald[200]
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import BrandLogos from '~/components/BrandLogos';
import OnboardingButton from '~/components/OnboardingButton';
import ReviewSummary from '~/components/ReviewSummary';
import SectionHeading from '~/components/SectionHeading';

export default {
  name: 'OnboardingSection',
  components: {
    BrandLogos,
    OnboardingButton,
    ReviewSummary,
    SectionHeading,
  },
  computed: {
    ...mapGetters(['brandLogos', 'partnership']),
    ...mapGetters('globals', ['onboardingSection']),
    filteredLogos() {
      return this.brandLogos.filter((logo) => !['vogue'].includes(logo));
    },
  },
};
</script>
