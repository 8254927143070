<template>
  <div class="relative py-40 md:py-48 select-none" />
</template>

<script>
import anime from 'animejs';
import { shuffle } from 'lodash';

const MIN_ANGLE = 20;
const MAX_ANGLE = 50;
const PIXELS_PER_MS = 1 / 75;

class Bubble {
  constructor({ x, y, radius, logo }) {
    this.then = +new Date();
    this.dx = 0;

    this.logo = logo;

    this.createElement();
    this.setRadius(radius);
    this.setPosition(x, y);
    this.createOscillators();

    if (logo) {
      this.createImage(logo.image[0].original);
    }
  }

  createElement() {
    this.el = document.createElement('div');
    this.el.classList.add('absolute');

    this.xContainer = document.createElement('div');
    this.xContainer.classList.add('w-full', 'h-full');
    this.yContainer = document.createElement('div');
    this.yContainer.classList.add(
      'w-full',
      'h-full',
      'p-1',
      'rounded-full',
      'overflow-hidden',
      'bg-white',
      'shadow-lg'
    );

    this.el.appendChild(this.xContainer);
    this.xContainer.appendChild(this.yContainer);
  }

  createImage(src) {
    const image = document.createElement('img');
    image.classList.add('w-full', 'h-full', 'rounded-full', 'object-cover');
    image.src = src;
    this.yContainer.appendChild(image);
  }

  createOscillators() {
    const xDuration = Bubble.getRandomDuration();
    const xRange = Bubble.getRandomRange();

    this.xOscillator = anime({
      targets: this.xContainer,
      translateX: [`${-xRange}%`, `${xRange}%`],
      translateZ: 0,
      duration: xDuration,
      easing: 'easeInOutQuad',
      direction: 'alternate',
      loop: true,
    });

    const yDuration = Bubble.getRandomDuration();
    const yRange = Bubble.getRandomRange();

    this.yOscillator = anime({
      targets: this.yContainer,
      translateY: [`${-yRange}%`, `${yRange}%`],
      translateZ: 0,
      duration: yDuration,
      easing: 'easeInOutQuad',
      direction: 'alternate',
      loop: true,
    });
  }

  destroy() {
    window.cancelAnimationFrame(this.requestId);
    this.el.remove();
    anime.remove(this.el);
    anime.remove(this.xContainer);
    anime.remove(this.yContainer);

    this.el = null;
  }

  setPosition(x, y) {
    this.x = x;
    this.translateX = this.x - this.radius;
    this.y = y;
    this.el.style.top = `${y - this.radius}px`;
    this.el.style.left = '0px';
    this.applyTransform();
  }

  setRadius(radius) {
    this.radius = radius;
    this.el.style.width = `${radius * 2}px`;
    this.el.style.height = `${radius * 2}px`;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  update(cb = () => {}) {
    const now = +new Date();
    const delta = now - this.then;
    this.dx -= delta * PIXELS_PER_MS;
    this.applyTransform();

    this.then = now;

    cb();
  }

  applyTransform() {
    this.translateX = this.x - this.radius + this.dx;
    this.el.style.transform = `translate3d(${this.translateX}px, 0, 0)`;
  }

  static getRandomDuration() {
    return 5000 + 5000 * Math.random();
  }

  static getRandomRange() {
    return 2 + 5 * Math.random();
  }
}

export default {
  name: 'LogoBubbles',
  props: {
    logos: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      bubbles: [],
      logoBuffer: [...shuffle(this.logos)],
      radii: {
        small: 40,
        medium: 64,
        large: 75,
      },
      requestId: null,
    };
  },
  mounted() {
    this.generateBubbles();

    anime({
      targets: shuffle(this.bubbles.map((bubble) => bubble.el)),
      opacity: [0, 1],
      duration: 600,
      delay: anime.stagger(75),
      easing: 'linear',
    });
  },
  beforeDestroy() {
    window.cancelAnimationFrame(this.requestId);
  },
  methods: {
    generateBubbles() {
      this.resetBubbles();
      let previous;

      while (
        this.logoBuffer.length &&
        (!previous || previous.x - previous.radius < this.$el.offsetWidth)
      ) {
        this.bubbles.push(this.getBubble(this.logoBuffer.shift()));
        previous = this.bubbles[this.bubbles.length - 1];
      }

      this.bubbles.forEach((bubble) => {
        this.$el.appendChild(bubble.el);
      });

      this.updateBubbles();
    },
    degToRad(angle) {
      return (angle * Math.PI) / 180;
    },
    getBubble(logo) {
      let angle = this.degToRad(this.getRandomInt(MIN_ANGLE, MAX_ANGLE));
      const previous = this.bubbles.length
        ? this.bubbles[this.bubbles.length - 1]
        : null;
      let radius = this.radii[logo.size];

      if (this.$mq === 'default') {
        radius = radius / 1.25;
      }

      const maxY = this.$el.offsetHeight - radius;
      const safeZone = radius * 1.5;
      const yRange = [safeZone, maxY - safeZone];

      let x = safeZone;
      let y = this.getRandomInt(yRange[0], yRange[1]);

      if (previous) {
        const isAbove = previous.y < maxY / 2;
        const delta =
          (previous.radius + radius) * (1.35 + Math.random() * 0.25);
        x = yRange[0];
        y = yRange[1];

        while (
          (y <= yRange[0] || y >= yRange[1]) &&
          angle >= this.degToRad(MIN_ANGLE) &&
          angle <= this.degToRad(MAX_ANGLE)
        ) {
          angle += this.degToRad(isAbove ? 1 : -1);
          x = previous.translateX + previous.radius + delta * Math.cos(angle);
          y = isAbove
            ? previous.y + delta * Math.sin(angle)
            : previous.y - delta * Math.sin(angle);
        }
      }

      return new Bubble({ x, y, radius, logo });
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    resetBubbles() {
      this.bubbles.forEach((bubble) => {
        bubble.destroy();
      });

      this.bubbles = [];
    },
    updateBubbles() {
      this.bubbles.forEach((bubble) => {
        // Update bubble position if it has .el
        // otherwise push the logo to the buffer.
        if (!bubble.el) {
          this.logoBuffer.push(this.bubbles.shift().logo);
        } else {
          bubble.update(() => {
            if (bubble.translateX < bubble.radius * -2 - 10) {
              bubble.destroy();
            }
          });
        }
      });

      if (
        this.logoBuffer.length &&
        this.bubbles[this.bubbles.length - 1].translateX < this.$el.offsetWidth
      ) {
        this.bubbles.push(this.getBubble(this.logoBuffer.shift()));
        this.$el.appendChild(this.bubbles[this.bubbles.length - 1].el);
      }

      this.requestId = window.requestAnimationFrame(() => {
        this.updateBubbles();
      });
    },
  },
};
</script>
