<template>
  <div>
    <BaseHeader>
      <template #left>
        <h1 class="mb-4 xl:mb-6 md:-mt-4 lg:-mt-6 xl:-mt-8 text-slate-100">
          {{ $t('pages.charityDirectoryIndex.titles.charityDirectory') }}
        </h1>
        <p class="mb-6 heading-3">
          <TextMarker
            class="text-teal-100"
            stroke-width="9"
            type="circle-stroke"
          >
            {{ $t('pages.charityDirectoryIndex.text.discover') }}
          </TextMarker>
          {{
            $t(
              'pages.charityDirectoryIndex.text.aWideRangeOfCharitiesAndSupportTheirInitiatives'
            )
          }}
        </p>
        <p class="mb-6 xl:mb-10 body-5 xl:body-3">
          {{
            $t(
              'pages.charityDirectoryIndex.text.withoutCharitableGiftsManyOfTheOrganisationsWeKnow'
            )
          }}
        </p>
      </template>
      <template #right>
        <div class="h-1/3vh lg:h-auto">
          <BaseAsset :asset="headerContent.image" fit="cover" rounded />
        </div>
      </template>
    </BaseHeader>
    <div class="container py-8 xl:py-16">
      <div class="grid-container">
        <div class="grid-item w-full">
          <div class="max-w-3xl mx-auto">
            <div class="text-center">
              <SectionHeading>
                <TextMarker
                  class="text-teal-100"
                  stroke-width="9"
                  type="line-a"
                >
                  {{ $t('pages.charityDirectoryIndex.text.leaveALegacy') }}
                </TextMarker>
                <br />
                {{
                  $t('pages.charityDirectoryIndex.text.withoutLeavingYourHome')
                }}
              </SectionHeading>
            </div>
            <div v-for="letter in entriesByFirstLetter" :key="letter[0]">
              <h2 class="heading-6 font-medium mb-2" v-text="letter.letter" />
              <ul class="mb-8">
                <li v-for="entry in letter.entries" :key="entry.slug">
                  <NuxtLink
                    :to="localePath(`/${entry.slug}`)"
                    class="text-green-500 hover:text-green-300"
                    v-text="entry.title"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GET_PARTNERSHIPS_QUERY from '~/graphql/queries/GetPartnerships';

import BaseAsset from '~/components/BaseAsset';
import BaseHeader from '~/components/BaseHeader';
import SectionHeading from '~/components/SectionHeading';
import TextMarker from '~/components/TextMarker';

import { craft, seomatic, transition, partnerships } from '~/mixins';

export default {
  name: 'CharityDirectory',
  components: {
    BaseAsset,
    BaseHeader,
    SectionHeading,
    TextMarker,
  },
  mixins: [
    craft({
      query: GET_PARTNERSHIPS_QUERY,
      transform({ entries }) {
        const entriesUnsorted = [];
        for (const i in entries) {
          if (
            // test charities
            !['swt-', '-123'].some((exclude) =>
              entries[i].slug.includes(exclude)
            ) &&
            // charity exclusives
            !entries[i].title.toLowerCase().includes('exclusive offer')
          ) {
            entriesUnsorted.push({
              title: entries[i].title,
              slug: entries[i].partnerships_vanityUrl,
            });
          }
        }
        const entriesSorted = [...entriesUnsorted].sort(function (a, b) {
          if (a.title < b.title) {
            return -1;
          } else if (a.title > b.title) {
            return 1;
          } else {
            return 0;
          }
        });
        const entriesByFirstLetter = [];
        const alphabet = [];
        for (const entryIndex in entriesSorted) {
          const entry = entriesSorted[entryIndex];
          const firstLetter = entry.title[0].toLocaleUpperCase();
          if (!alphabet.includes(firstLetter)) {
            entriesByFirstLetter.push({
              letter: firstLetter,
              entries: [entry],
            });
            alphabet.push(firstLetter);
          } else {
            entriesByFirstLetter[alphabet.length - 1].entries.push(entry);
          }
        }
        return { entriesByFirstLetter };
      },
    }),
    seomatic,
    transition,
    partnerships,
  ],
  data() {
    return {
      entriesByFirstLetter: [],
      headerContent: {
        image: {
          title: 'Home header',
          filename: 'charities-directory-hero-image.png',
          kind: 'image',
          original:
            'https://assets.safewill.com/images/charities-directory-hero-image.png',
          xs: 'https://assets.safewill.com/images/charities-directory-hero-image.png',
          sm: 'https://assets.safewill.com/images/charities-directory-hero-image.png',
          md: 'https://assets.safewill.com/images/charities-directory-hero-image.png',
          lg: 'https://assets.safewill.com/images/charities-directory-hero-image.png',
          xl: 'https://assets.safewill.com/images/charities-directory-hero-image.png',
          __typename: 's3Bucket_Asset',
        },
      },
    };
  },
  head() {
    return {
      title: 'Charities Directory - Safewill',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Discover a wide range of charities and support their initiatives.',
        },
      ],
    };
  },
};
</script>
