<template>
  <div
    v-scroll="{ callback: onScroll }"
    class="relative p-6 pt-8 md:p-12 lg:p-10 xl:p-16 rounded-xl overflow-hidden bg-navy-200 text-white selection-bg-navy-100"
  >
    <div
      class="relative z-20 max-w-sm md:max-w-none lg:max-w-sm mb-8 md:mb-12 lg:mb-0"
    >
      <h3
        class="xl:max-w-sm mb-3 md:mb-8 lg:mb-6 heading-5 md:heading-4"
        v-text="heading"
      />
      <div class="max-w-xs md:max-w-md lg:max-w-xs body-7 md:body-5">
        <slot />
      </div>
    </div>
    <div class="lg:absolute lg:inset-0">
      <div v-if="!hasMobileLayout" class="absolute inset-0 z-10 vignette" />
      <BaseAsset
        ref="image"
        :asset="asset"
        :fit="hasMobileLayout ? 'cover' : 'width'"
        :rounded="hasMobileLayout"
        crop
        sizes="(min-width: 1440px) 1260px, (min-width: 1280px) 90vw, (min-width: 1024px) 95vw, (min-width: 768px) 80vw, 75vw"
      />
    </div>
  </div>
</template>

<script>
import anime from 'animejs';
import { mapGetters } from 'vuex';

import BaseAsset from '~/components/BaseAsset';

export default {
  name: 'BaseParallaxBlock',
  components: {
    BaseAsset,
  },
  props: {
    asset: {
      required: true,
      type: Object,
    },
    heading: {
      default: '',
      type: String,
    },
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
  },
  methods: {
    onScroll() {
      const deltaMax = Math.abs(
        this.$refs.image.$el.offsetHeight - this.$el.offsetHeight
      );

      if (!this.hasMobileLayout && deltaMax > 0) {
        const rect = this.$rect(this.$el, true);
        const translateY =
          ((rect.top + rect.height) / (1 + rect.height)) * deltaMax;

        anime.set(this.$refs.image.$el, {
          translateY: Math.min(deltaMax, Math.max(0, translateY)) * -1,
          translateZ: 0,
        });
      } else {
        anime.set(this.$refs.image.$el, {
          translateY: 0,
        });
      }
    },
  },
};
</script>
