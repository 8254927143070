<template>
  <figure
    class="lg:flex lg:justify-between p-6 pt-8 lg:p-16 rounded-xl bg-babyblue-100 body-4 selection-bg-babyblue-100"
  >
    <div class="flex flex-col max-w-xl mb-4">
      <blockquote>
        <QuoteMarks class="h-12 mb-6 text-teal-200" />
        <p class="mb-8 lg:body-2 xl:body-1" v-text="activeTestimonial.quote" />
        <cite class="flex flex-col not-italic">
          <span class="lg:body-3" v-text="activeTestimonial.name" />
          <span class="body-7 lg:body-6 text-black text-opacity-60">
            {{ $t('common.text.safewillCustomer') }}
          </span>
        </cite>
      </blockquote>
      <div
        class="hidden lg:flex items-center mt-8 xl:mt-auto body-3 text-black text-opacity-30 hover:text-opacity-100 transition transition-color duration-100"
      >
        <ArrowButton
          class="text-black"
          direction="<"
          @click="prevTestimonial"
        />
        <span class="w-8 mx-5 leading-none text-center">
          {{ currentIndex + 1 }}/{{ testimonials.length }}
        </span>
        <ArrowButton
          class="text-black"
          direction=">"
          @click="prevTestimonial"
        />
      </div>
    </div>
    <div class="max-w-sm lg:ml-16">
      <BaseAsset
        :asset="activeTestimonial.portrait[0]"
        rounded
        sizes="(min-width: 768px) 384px, 75vw"
      />
    </div>
  </figure>
</template>

<script>
import ArrowButton from '~/components/ArrowButton';
import BaseAsset from '~/components/BaseAsset';
import QuoteMarks from '~/components/QuoteMarks';

export default {
  name: 'Testimonials',
  components: {
    ArrowButton,
    BaseAsset,
    QuoteMarks,
  },
  props: {
    testimonials: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    activeTestimonial() {
      return this.testimonials[this.currentIndex];
    },
  },
  methods: {
    nextTestimonial() {
      this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
    },
    prevTestimonial() {
      this.currentIndex =
        this.currentIndex === 0
          ? this.testimonials.length - 1
          : this.currentIndex - 1;
    },
  },
};
</script>
