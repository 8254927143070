<template>
  <div>
    <div class="grid-container">
      <div v-if="topicFilters" class="grid-item w-full mb-10">
        <div class="flex flex-wrap">
          <NuxtLink :to="localePath('/blog/posts')">
            <BlogTopic :active="!$route.query.topic" class="mr-2 mb-2">
              {{ $t('common.titles.all') }}
            </BlogTopic>
          </NuxtLink>
          <NuxtLink
            v-for="{ id, title } in topics"
            :key="id"
            :to="localePath(`/blog/posts?topic=${id}`)"
            class="inline-flex mr-2 mb-2"
          >
            <BlogTopic :active="$route.query.topic === id">
              {{ title }}
            </BlogTopic>
          </NuxtLink>
        </div>
      </div>
      <template v-if="isLoading">
        <div
          v-for="n in postsPerPage"
          :key="n"
          class="grid-item w-full md:w-6/12 lg:w-4/12 mb-8"
        >
          <BlogItemPlaceholder />
        </div>
      </template>
      <template v-else>
        <div
          v-for="(post, index) in blogPosts"
          :key="index"
          class="grid-item w-full md:w-6/12 lg:w-4/12 mb-8"
        >
          <BlogItem v-bind="post" />
        </div>
      </template>
    </div>
    <div class="grid-container">
      <div class="grid-item w-10/12 md:w-full mx-auto">
        <div class="pagination flex flex-wrap justify-center space-x-10 body-3">
          <button
            :disabled="disablePrevious"
            class="my-2 disabled:opacity-50"
            type="button"
            @click="prevPage"
          >
            {{ $t('common.buttons.previous') }}
          </button>
          <template v-for="(n, index) in pageNumbers">
            <button
              v-if="typeof n === 'number'"
              :key="index"
              :class="n !== currentPage && 'opacity-50 hover:opacity-100'"
              class="my-2 transtiion-opacity duration-75"
              @click="goToPage(n)"
              v-text="n"
            />
            <span v-else :key="index"> ... </span>
          </template>
          <button
            :disabled="disableNext"
            class="my-2 disabled:opacity-50"
            type="button"
            @click="nextPage"
          >
            {{ $t('common.buttons.next') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogItem from '~/components/BlogItem';
import BlogItemPlaceholder from '~/components/BlogItemPlaceholder';
import BlogTopic from '~/components/BlogTopic';

import GET_BLOG_POSTS_QUERY from '~/graphql/queries/GetBlogPosts';
import GET_BLOG_TOPICS_QUERY from '~/graphql/queries/GetBlogTopics';
import GET_BLOG_POST_COUNT_QUERY from '~/graphql/queries/GetBlogPostCount';

export default {
  name: 'PaginatedBlogPosts',
  components: {
    BlogItem,
    BlogItemPlaceholder,
    BlogTopic,
  },
  props: {
    authorId: {
      default: null,
      type: [Number, String],
    },
    topicFilters: {
      default: false,
      type: Boolean,
    },
  },
  apollo: {
    blogPosts: {
      query: GET_BLOG_POSTS_QUERY,
      variables() {
        return {
          authorId: this.authorId,
          limit: this.postsPerPage,
          offset: (this.currentPage - 1) * this.postsPerPage,
          topic: this.topicsFilter,
        };
      },
      update: (data) => data.blogPosts,
      loadingKey: 'isLoading',
      prefetch: false,
    },
    postCount: {
      query: GET_BLOG_POST_COUNT_QUERY,
      variables() {
        return {
          authorId: this.authorId,
        };
      },
      update: (data) => data.count,
    },
    topics: {
      query: GET_BLOG_TOPICS_QUERY,
      update: (data) => {
        return data.topics.filter((topic) => topic.id);
      },
    },
  },
  data() {
    return {
      isLoading: 0,
    };
  },
  computed: {
    currentPage() {
      return this.$route.query.page ? parseInt(this.$route.query.page, 10) : 1;
    },
    disableNext() {
      return this.currentPage === this.totalPageCount;
    },
    disablePrevious() {
      return this.currentPage === 1;
    },
    pageNumbers() {
      const delta = 3;
      const left = this.currentPage - delta;
      const right = this.currentPage + delta + 1;
      const range = [];
      const rangeWithDots = [];
      let l;

      for (let i = 1; i <= this.totalPageCount; i++) {
        if (i === 1 || i === this.totalPageCount || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (const i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }

        rangeWithDots.push(i);
        l = i;
      }

      l = delta;

      return rangeWithDots;
    },
    postsPerPage() {
      let count;

      switch (this.$mq) {
        case 'default':
        case 'sm':
          count = 5;
          break;
        case 'md':
          count = 6;
          break;
        default:
          count = 9;
          break;
      }

      return count;
    },
    topicsFilter() {
      return this.topicFilters && this.$route.query.topic
        ? [parseInt(this.$route.query.topic, 10)]
        : null;
    },
    totalPageCount() {
      return Math.ceil(this.postCount / this.postsPerPage);
    },
  },
  methods: {
    goToPage(page) {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page,
        },
      });
    },
    nextPage() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: this.currentPage + 1,
        },
      });
    },
    prevPage() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: this.currentPage - 1,
        },
      });
    },
  },
};
</script>

<style lang="postcss" scoped>
.pagination button:disabled {
  @apply opacity-50 cursor-not-allowed;
}
</style>
