<template>
  <div class="text-left">
    <button
      class="group flex justify-between md:items-center w-full py-1 xl:py-2 pl-3 xl:pl-4 text-left"
      type="button"
      :aria-label="$t('components.faqsItem.buttons.toggleQuestion')"
      @click="$emit('click')"
    >
      <h2 class="text-lg font-medium" v-text="question" />
      <div
        class="flex flex-none justify-center items-center w-8 h-8 rounded-full ml-4 text-teal-200 transition transition-bg duration-100"
      >
        <svg
          ref="svg"
          class="stroke-current"
          width="26"
          height="26"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path ref="pathA" d="M18 0v15" />
          <path ref="pathB" d="M10 8h15" />
        </svg>
      </div>
    </button>
    <div v-show="active" class="overflow-hidden">
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="answer"
        class="max-w-2xl pb-6 pl-3 xl:pl-4 rich-text text-black text-opacity-70"
        v-html="answer"
      />
      <!--eslint-enable-->
      <slot v-else />
    </div>
  </div>
</template>

<script>
import anime from 'animejs';

export default {
  name: 'FaqsItem',
  props: {
    /* eslint-disable-next-line vue/prop-name-casing */
    __typename: {
      required: true,
      type: String,
    },
    active: {
      default: false,
      type: Boolean,
    },
    answer: {
      required: false,
      type: String,
      default: '',
    },
    question: {
      required: true,
      type: String,
    },
  },
  watch: {
    active(active) {
      const anim = anime.timeline({
        duration: 350,
        easing: 'easeInOutQuad',
      });

      if (active) {
        this.$emit('active');
        anim.add({
          targets: [this.$refs.pathA, this.$refs.pathB],
          rotate: 45,
        });
      } else {
        anim.add({
          targets: [this.$refs.pathA, this.$refs.pathB],
          rotate: 0,
        });
      }
    },
  },
};
</script>
