<template>
  <div>
    <BaseHeader>
      <template #left>
        <h1 class="mb-6 xl:mb-16 heading-3 xl:heading-1">
          <MarkedText
            class="text-teal-100"
            :text="headerContent.heading"
            type="circle-fill"
          />
        </h1>
        <p
          class="mb-6 xl:mb-10 body-5 xl:body-3 whitespace-pre-line"
          v-text="headerContent.body"
        />
        <div class="mb-4 lg:mb-0">
          <OnboardingButton :to="poaLink" arrow size="large">
            {{ $t('common.buttons.getStartedNow') }}
          </OnboardingButton>
        </div>
      </template>
      <template #right>
        <div class="h-1/3vh lg:h-auto">
          <BaseAsset :asset="headerContent.image" fit="cover" rounded />
        </div>
      </template>
    </BaseHeader>
    <section>
      <div class="content-padding">
        <div class="bg-teal-100 bg-opacity-50 content-padding">
          <div class="container">
            <div class="grid-container">
              <div class="grid-item w-full">
                <div class="bg-white rounded-xl overflow-hidden">
                  <h2
                    class="text-3xl text-center pt-6 pb-12 px-12 leading-snug"
                  >
                    {{
                      $t(
                        'pages.powerOfAttorney.text.theEasiestWayToChooseSomeoneToLookAfterYourFinanci'
                      )
                    }}
                  </h2>
                  <div class="grid-container px-12">
                    <div class="grid-item lg:w-2/3 leading-snug">
                      <div class="grid-container">
                        <div class="grid-item w-full md:w-6/12 mb-10">
                          <BaseGlyph
                            type="wallet-bg"
                            classes="mb-4 lg:mb-5 w-8 h-8"
                          />
                          <h4 class="text-lg lg:text-xl mb-1 lg:mb-2">
                            {{
                              $t(
                                'pages.powerOfAttorney.titles.financialDecisions'
                              )
                            }}
                          </h4>
                          <p class="text-base">
                            {{
                              $t(
                                'pages.powerOfAttorney.text.appointSomeoneToManageYourPropertyAndFinances'
                              )
                            }}
                          </p>
                        </div>
                        <div class="grid-item w-full md:w-6/12 mb-10">
                          <BaseGlyph
                            type="pen-bg"
                            classes="mb-4 lg:mb-5 w-8 h-8"
                          />
                          <h4 class="text-lg lg:text-xl mb-1 lg:mb-2">
                            {{
                              $t('pages.powerOfAttorney.titles.legalAffairs')
                            }}
                          </h4>
                          <p class="text-base">
                            {{
                              $t(
                                'pages.powerOfAttorney.text.chooseSomeoneYouTrustToManageLegalAffairs'
                              )
                            }}
                          </p>
                        </div>
                        <div class="grid-item w-full md:w-6/12 mb-10">
                          <BaseGlyph
                            type="aidkit-bg"
                            classes="mb-4 lg:mb-5 w-8 h-8"
                          />
                          <h4 class="text-lg lg:text-xl mb-1 lg:mb-2">
                            {{
                              $t(
                                'pages.powerOfAttorney.titles.medicalTreatment'
                              )
                            }}
                          </h4>
                          <p class="text-base">
                            {{
                              $t(
                                'pages.powerOfAttorney.text.giveInstructionsInAdvanceAroundMedicalTreatmentAnd'
                              )
                            }}
                          </p>
                        </div>
                        <div class="grid-item w-full md:w-6/12 mb-10">
                          <BaseGlyph
                            type="house-bg"
                            classes="mb-4 lg:mb-5 w-8 h-8"
                          />
                          <h4 class="text-lg lg:text-xl mb-1 lg:mb-2">
                            {{
                              $t(
                                'pages.powerOfAttorney.titles.lifestyleDecisions'
                              )
                            }}
                          </h4>
                          <p class="text-base">
                            {{
                              $t(
                                'pages.powerOfAttorney.text.protectYourLifestyleDecisionsIncludingWhereYouLive'
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="grid-item hidden lg:block w-1/3">
                      <BaseGlyph
                        type="shield-bg"
                        classes="w-full h-auto max-h-full mb-10"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container content-padding">
        <div class="grid-container">
          <div class="grid-item w-full mx-auto mb-8 text-center">
            <SectionHeading>
              <TextMarker class="text-teal-100" stroke-width="9" type="line-a">
                {{ $t('pages.powerOfAttorney.titles.frequently') }}
              </TextMarker>
              {{ $t('pages.powerOfAttorney.titles.askedQuestions') }}
            </SectionHeading>
          </div>
          <div class="grid-item w-full mb-10 md:mb-12 xl:mb-16">
            <Faqs :items="questions" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseAsset from '~/components/BaseAsset';
import BaseGlyph from '~/components/BaseGlyph';
import BaseHeader from '~/components/BaseHeader';
import Faqs from '~/components/Faqs';
import MarkedText from '~/components/MarkedText';
import OnboardingButton from '~/components/OnboardingButton';
import SectionHeading from '~/components/SectionHeading';
import TextMarker from '~/components/TextMarker';

export default {
  name: 'PowerOfAttorney',
  components: {
    BaseAsset,
    BaseGlyph,
    BaseHeader,
    Faqs,
    MarkedText,
    OnboardingButton,
    SectionHeading,
    TextMarker,
  },
  data() {
    return {
      headerContent: {
        heading: 'Appoint your Powers of <em>Attorney</em>',
        body: 'Your Enduring Powers of Attorney are important legal tools that allow you to appoint someone to make financial and medical decisions on your behalf should you be living but unable to make your own decisions.',
        image: {
          title: 'Home header',
          filename: 'power-of-attorney__couple.jpg',
          kind: 'image',
          original:
            'https://assets.safewill.com/images/power-of-attorney__couple.jpg',
          xs: 'https://assets.safewill.com/images/power-of-attorney__couple.jpg',
          sm: 'https://assets.safewill.com/images/power-of-attorney__couple.jpg',
          md: 'https://assets.safewill.com/images/power-of-attorney__couple.jpg',
          lg: 'https://assets.safewill.com/images/power-of-attorney__couple.jpg',
          xl: 'https://assets.safewill.com/images/power-of-attorney__couple.jpg',
          __typename: 's3Bucket_Asset',
        },
      },
      questions: [
        {
          __typename: 'faqs_probate',
          question: `What is an Enduring Power of Attorney?`,
          answer: `<p class="mb-4">
            Enduring Powers of Attorney are legal documents that allow you to
            appoint someone to make decisions on your behalf if you are unable
            to do so yourself. There are two types of Enduring Powers of Attorney
            you can make on the Safewill Platform:
            </p>
            <ul class="list-disc pl-6 text-lg">
              <li class="mb-4">
                <strong>Financial Powers of Attorney</strong> allow you to choose
                someone to manage your financial and legal affairs, which includes
                buying and selling real estate, shares and other assets, operating
                your bank accounts and spending money on your behalf.
              </li>
              <li class="mb-4">
                <strong>Medical Powers of Attorney</strong> allow you to choose
                someone to manage your health and lifestyle matters, including the
                type of medical care you receive, or the nature of your living
                arrangements. Depending on the State you live in, this medical Power
                of Attorney document is commonly known as either an ‘Enduring
                Guardianship', ‘Advance Personal Plan’ or ‘Advance Care Directive’.
              </li>
            </ul>
            <p class="mb-4">
              Your appointed decision maker(s) will be subject to a number of legal
              obligations and duties, including to always act in your best interests
              and in accordance with your written instructions.
            </p>`,
        },
        {
          __typename: 'faqs_probate',
          question: `What is the difference between a General and Enduring Power of Attorney?`,
          answer: `<p class="mb-4">
            A General Power of Attorney operates from the date of signing and becomes invalid
            when you lose the mental capacity to make your own decisions. In contrast, an
            Enduring Power of Attorney written on the Safewill platform only comes into effect
            if you lose capacity to make decisions for yourself.
            </p>`,
        },
        {
          __typename: 'faqs_probate',
          question: ` When will my Powers of Attorney take effect?`,
          answer: `<p class="mb-4">
              At Safewill, the Powers of Attorney you create will only come into
              effect if you lose your decision-making capacity.
            </p>
            <p class="mb-4">
              The authority given under the Power of Attorney will continue
              throughout the time you lack decision-making capacity, and will come
              to an end when you pass away.
            </p>`,
        },
        {
          __typename: 'faqs_probate',
          question: ` Do I need witnesses when signing my POA?`,
          answer: `<p class="mb-4">
            Yes, all POAs in Australia need to be signed in the presence of witnesses.
            The requirements of who constitutes a valid witness will vary from state to state,
            although generally includes a Justice of the Peace, or in the case of NSW,
            requires a solicitor. Please take this into account when considering the finalisation of your POA.
            </p>`,
        },
        {
          __typename: 'faqs_probate',
          question: ` What’s the difference between this and my Will?`,
          answer: `<p class="mb-4">
              Your Will and your Powers of Attorney function at different times to
              carry out and safeguard your wishes.
            </p>
            <p class="mb-4">
              Powers of Attorney come into effect while you are still alive but have
              lost the ability to make decisions for yourself.
              <strong>
                The effect of the Power of Attorney ends when you pass away.
              </strong>
            </p>
            <p class="mb-4">
              Your Will, on the other hand,
              <strong>only comes into effect once you pass away</strong>. Under your
              Will, you appoint an Executor to carry out the wishes in your Will,
              and manage the transfer of your assets to the required beneficiaries
              upon your death.
            </p>`,
        },
        {
          __typename: 'faqs_probate',
          question: `Do I need Enduring Powers of Attorney?`,
          answer: `<p class="mb-4">
              People tend to assume that because their loved ones know what their
              wishes would be in certain circumstances, those same people would have
              the ability to make decisions on their behalf. However, without a
              valid Power of Attorney appointment in place, this is unlikely to be
              the case.
            </p>
            <p class="mb-4">
              Completing a Power of Attorney document provides certainty that
              someone you trust is able to help manage your affairs and make
              decisions on your behalf when it matters most.
            </p>
            <p class="mb-4">
              For financial matters, this means your chosen attorney can readily
              access your solely held assets, such as bank accounts that may be
              required to pay for medical treatment or supporting your dependents.
              For health matters, a medical Power of Attorney will allow you to
              appoint someone to speak with doctors and nurses on your behalf about
              the types of treatment you receive. In some states you can also give
              your decision maker the ability to make decisions about your personal
              matters, such as who may visit you, whether you prefer a specific diet
              (e.g. vegetarian), and which long-term care facility you move to (if
              necessary).
            </p>`,
        },
        {
          __typename: 'faqs_probate',
          question: `What happens if I don’t have Enduring Powers of Attorney?`,
          answer: `<p class="mb-4">
              If there is no valid Power of Attorney and you lose capacity, your
              loved ones may have to go through alternative, lengthy Court or
              Tribunal processes to be legally appointed as your decision maker.
              This means that even if the right person is eventually granted
              decision making power, there may be significant delay between you
              losing the ability to make decisions and your decision maker being
              appointed. Often this can result in bills going unpaid, dependents not
              being cared for, and medical decisions being made that may not be what
              you would have wanted.
            </p>
            <p class="mb-4">
              There is also a chance that a Court or Tribunal will not appoint the
              person you would have otherwise chosen to make these decisions for
              you, and an alternative decision maker is appointed. In all these
              cases, it is much better to have certainty over who will be appointed,
              and grant them the necessary powers to quickly make decisions if the
              need ever arose.
            </p>`,
        },
        {
          __typename: 'faqs_probate',
          question: `What do I need to get started?`,
          answer: `<p class="mb-4">
              You’ll just need 10 minutes and an idea of who you’d trust with
              decisions around your finances or healthcare. We’ll guide you through
              the rest.
            </p>`,
        },
        {
          __typename: 'faqs_probate',
          question: `How much does it normally cost?`,
          answer: `<p class="mb-4">
              With a lawyer, Power of Attorney documents can cost hundreds, if not
              thousands of dollars. It can also be a lengthy and difficult process
              to go through. You can access your medical and financial Power of
              Attorney documents at a price of just $99.
            </p>`,
        },
        {
          __typename: 'faqs_probate',
          question: `How long will it take?`,
          answer: `<p class="mb-4">
              With Safewill, completing your Power of Attorney can take as little as
              10 minutes.
            </p>`,
        },
        {
          __typename: 'faqs_probate',
          question: ` What powers will my decision makers legally have?`,
          answer: `<p class="mb-4">
              The details are slightly different in each state, but in all states
              the principle is the same and your chosen decision makers have the
              power to make decisions on your behalf. Remember that in all cases,
              they are legally bound to act in your best interests. Even so, you
              should make sure to appoint people that you trust completely to fill
              the role.
            </p>`,
        },
        {
          __typename: 'faqs_probate',
          question: ` Can I place any limitations or exclusions on their power?`,
          answer: `<p class="mb-4">
                Yes, it’s up to you. We’ll ask you if there are any specific
                limitations or exclusions you want to include in your Powers of
                Attorney for your decision makers.
              </p>`,
        },
        {
          __typename: 'faqs_probate',
          question: ` What if I’ve previously created Powers of Attorney?`,
          answer: `<p class="mb-4">
              The rules are different in each state. In Victoria, creating a new
              Powers of Attorney document will override any previous documents. In
              the remaining states, creating a new document will not automatically
              override any previous documents — if you’d like those previous
              documents to be terminated, you’ll need to revoke your previous
              documents separately.
            </p>`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['appQuery']),
    poaLink() {
      return `${this.$config.safewillAppUrl}${this.localePath(
        `/get-started?redirect=/poa`
      )}`;
    },
  },
  head() {
    return {
      title: 'Power of Attorney - Safewill',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Easily appoint your Powers of Attorney today with Safewill.',
        },
      ],
    };
  },
};
</script>
