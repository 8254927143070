<template>
  <div class="ru:base-video">
    <video ref="video" autoplay controls class="focus:outline-none">
      <source :src="src" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: 'BaseVideo',
  props: {
    src: {
      required: true,
      type: String,
    },
  },
};
</script>

<style lang="scss">
.ru\: {
  &base-video {
    position: relative;

    video {
      width: 100%;
    }
  }
}
</style>
