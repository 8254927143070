<template>
  <client-only v-if="bannerLogo || bannerText">
    <div
      :style="bannerStyles"
      class="flex flex-col md:flex-row items-center p-4 rounded-xl md:rounded-full selection-partner"
    >
      <div
        v-if="bannerLogo"
        :style="logoStyles"
        class="flex-none h-16 px-4 py-3 rounded-full mb-4 md:mb-0 md:mr-5 overflow-hidden"
      >
        <BaseAsset :asset="bannerLogo" fit="height" />
      </div>
      <BannerIcon v-if="!bannerLogo && bannerText" />
      <p
        v-if="bannerText"
        class="mb-4 md:mb-0 md:mr-8 font-medium body-6 md:body-7 lg:body-6 text-center md:text-left"
      >
        <span class="inline-block leading-snug">
          {{ bannerText }}
        </span>
      </p>
      <OnboardingButton
        class="group flex-shrink-0 w-full md:w-auto border-2 border-current hover:border-navy-100 md:ml-auto bg-transparent hover:bg-navy-100 text-current"
      >
        <span class="group-hover:text-white">{{
          $t('common.buttons.getStartedNow')
        }}</span>
      </OnboardingButton>
    </div>
  </client-only>
</template>

<script>
import { mapGetters } from 'vuex';

import BannerIcon from '~/components/BannerIcon';
import BaseAsset from '~/components/BaseAsset';
import OnboardingButton from '~/components/OnboardingButton';

export default {
  name: 'Banner',
  components: {
    BannerIcon,
    BaseAsset,
    OnboardingButton,
  },
  computed: {
    ...mapGetters(['theme']),
    ...mapGetters('banner', ['bannerLogo', 'bannerText']),
    bannerStyles() {
      return this.theme
        ? {
            backgroundColor: this.theme.background,
            color: this.theme.text,
          }
        : null;
    },
    logoStyles() {
      return this.theme
        ? {
            backgroundColor: this.theme.logoBackground,
          }
        : null;
    },
  },
};
</script>
