<template>
  <div class="flex flex-col">
    <div class="shimmer aspect-wide rounded-xl mb-6 bg-grey-100" />
    <div class="flex items-baseline space-x-3 mb-3">
      <BlogItemType class="shimmer bg-grey-100">
        &nbsp;&nbsp;&nbsp;&nbsp;
      </BlogItemType>
    </div>
    <div class="flex flex-col font-medium space-y-1 xl:space-y-2">
      <div class="shimmer w-full rounded bg-grey-100 h-8" />
      <div class="shimmer w-2/5 h-6 rounded bg-grey-100" />
    </div>
  </div>
</template>

<script>
import BlogItemType from '~/components/BlogItemType';

export default {
  name: 'BlogItemPlaceholder',
  components: {
    BlogItemType,
  },
};
</script>

<style lang="postcss" scoped>
.shimmer {
  background-image: linear-gradient(
    to right,
    rgb(221 221 221 / 0%) 8%,
    rgb(221 221 221 / 50%) 18%,
    rgb(221 221 221 / 0%) 33%
  );
  background-size: 800px 100%;
  animation: linear 1s shimmer infinite;
}

@keyframes shimmer {
  0% {
    background-position: -400px 0;
  }

  100% {
    background-position: 400px 0;
  }
}
</style>
