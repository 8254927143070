<template>
  <ContentPage v-bind="entry" />
</template>

<script>
import GET_GUIDANCE_NOTES_QUERY from '~/graphql/queries/GetGuidanceNotes';

import ContentPage from '~/components/ContentPage';

import { craft, seomatic, transition, partnerships } from '~/mixins';

export default {
  name: 'PageGuidanceNotes',
  components: {
    ContentPage,
  },
  mixins: [
    craft({
      query: GET_GUIDANCE_NOTES_QUERY,
    }),
    seomatic,
    transition,
    partnerships,
  ],
  data() {
    return {
      entry: null,
    };
  },
};
</script>
