<template>
  <form class="md:inline-block" @submit.prevent="onSubmit">
    <div
      class="flex md:inline-flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3"
    >
      <input
        v-model="email"
        :disabled="success"
        class="input border-2 border-grey-300 border-solid"
        placeholder="your-email@example.com"
        required
        type="email"
      />
      <div class="relative">
        <input
          :class="isLoading ? 'text-transparent' : null"
          :disabled="isLoading || success"
          class="button button-lg button-navy w-full md:w-48 rounded-xl"
          type="submit"
          :value="submitLabel"
        />
        <div
          v-if="isLoading"
          class="absolute inset-0 z-10 flex justify-center items-center"
        >
          <LoadingSpinner :size="32" color="#ffffff" />
        </div>
      </div>
    </div>
    <div class="mt-1 text-base text-black text-opacity-60">
      <p v-if="success">
        {{ $t('common.forms.contact.hints.success.thanksWellBeInTouchSoon') }}
      </p>
      <p v-else-if="hasError">
        {{
          $t('common.forms.contact.hints.errors.anErrorOccurredPleaseTryAgain')
        }}
      </p>
    </div>
  </form>
</template>

<script>
import LoadingSpinner from '~/components/LoadingSpinner';

export default {
  name: 'CharitiesContactForm',
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      email: '',
      hasError: false,
      isLoading: false,
      success: false,
    };
  },
  computed: {
    submitLabel() {
      return this.success ? 'Done!' : 'Sign Me Up!';
    },
    payload() {
      return {
        email: this.email,
      };
    },
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      this.hasError = false;

      const res = await this.$http
        .post(`${process.env.SAFEWILL_API}/newsletter/contacts`, this.payload)
        .catch(() => {
          this.success = true;
          this.isLoading = false;
        });

      const { success } = await res.json();
      this.success = success;
      this.hasError = !success;
      this.isLoading = false;
    },
  },
};
</script>
