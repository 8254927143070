<template>
  <span class="relative inline-block">
    <span class="text-black">
      <slot />
    </span>
    <div class="absolute inset-0 flex items-center">
      <svg
        class="w-full opacity-75 stroke-current"
        viewBox="0 0 151 13"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path stroke-width="12" d="M.206 6.281h150" />
      </svg>
    </div>
  </span>
</template>

<script>
export default {
  name: 'Strikethrough',
};
</script>
