<template>
  <a
    :href="url"
    target="_blank"
    class="group flex flex-col lg:flex-row-reverse lg:justify-between py-8 lg:pl-8 greyscale transition transition-filter duration-200"
  >
    <BaseAsset
      :asset="logo[0]"
      :class="classes"
      class="flex-none mb-4 xl:mb-0 opacity-50 group-hover:opacity-100 transition transition-opacity duration-200"
      fit="height"
      sizes="(min-width: 1440px) 350px, (min-width: 1280px) 25vw, (min-width: 1024px) 18vw, (min-width: 768px) 22vw, 50vw"
    />
    <h3
      class="opacity-70 group-hover:opacity-100 body-4 lg:body-3 xl:body-2 transition transition-opacity duration-200"
      v-text="heading"
    />
  </a>
</template>

<script>
import BaseAsset from '~/components/BaseAsset';

export default {
  name: 'NewsItem',
  components: {
    BaseAsset,
  },
  props: {
    /* eslint-disable-next-line vue/prop-name-casing */
    __typename: {
      required: true,
      type: String,
    },
    logo: {
      required: true,
      type: Array,
    },
    logoAspectRatio: {
      required: true,
      type: String,
    },
    heading: {
      required: true,
      type: String,
    },
    url: {
      required: true,
      type: String,
    },
  },
  computed: {
    classes() {
      return {
        'h-10 md:h-8 xl:h-10': this.logoAspectRatio === 'narrow',
        'h-8 md:h-6 xl:h-8': this.logoAspectRatio === 'normal',
        'h-5 md:h-4 xl:h-6': this.logoAspectRatio === 'wide',
      };
    },
  },
};
</script>
