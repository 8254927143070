<template>
  <div class="selection-bg-teal-100">
    <div v-if="$slots.default" class="mb-5 xl:mb-8">
      <slot />
    </div>
    <div>
      <h3
        v-if="heading"
        class="mb-2 xl:mb-3 heading-7 xl:heading-6 whitespace-pre-line"
        v-text="heading"
      />
      <!-- eslint-disable vue/no-v-html -->
      <p
        v-if="body"
        class="body-7 md:body-4 xl:body-5 text-black text-opacity-70 whitespace-pre-line"
        v-html="body"
      />
      <!--eslint-enable-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTextBlock',
  props: {
    body: {
      default: '',
      type: String,
    },
    heading: {
      default: '',
      type: String,
    },
  },
};
</script>
