<template>
  <div>
    <header>
      <div class="container content-padding">
        <div class="grid-container">
          <div class="grid-item w-full">
            <h1
              class="heading-3 md:heading-2 xl:heading-1 text-center"
              v-text="title"
            />
          </div>
        </div>
      </div>
    </header>
    <section v-if="textContent">
      <div class="container content-padding pt-0 xl:pt-12">
        <div class="grid-container">
          <div class="grid-item w-full">
            <!-- eslint-disable vue/no-v-html -->
            <div class="rich-text max-w-3xl mx-auto" v-html="textContent" />
            <!--eslint-enable-->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ContentPage',
  props: {
    /* eslint-disable-next-line vue/prop-name-casing */
    __typename: {
      required: true,
      type: String,
    },
    textContent: {
      default: '',
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
  },
};
</script>
