<template>
  <div>
    <header>
      <div class="container content-padding">
        <div class="grid-container">
          <div class="grid-item flex flex-col lg:flex-row w-full">
            <div class="w-full mb-16 xl:mb-0">
              <BlogItemLarge v-bind="trendingPosts[0]" />
            </div>
            <div
              class="header-sidebar flex-none lg:pl-12 lg:border-l border-navy-100 border-opacity-10 lg:ml-12"
            >
              <div
                v-for="(post, index) in trendingPosts.slice(1)"
                :key="index"
                :class="
                  index > 0
                    ? 'pt-6 border-t border-navy-100 border-opacity-10 mt-6'
                    : ''
                "
              >
                <BlogItemCompact v-bind="post" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <section>
      <div v-if="featuredGuides.length" class="container content-padding">
        <div class="grid-container">
          <div class="grid-item w-full">
            <div
              v-if="hasMobileLayout"
              class="px-6 md:px-12 py-12 -mx-6 md:-mx-12 bg-orange-100"
            >
              <div class="mb-10">
                <h2 class="heading-6 font-medium mb-2">
                  {{ $t('pages.blogIndex.titles.featuredGuides') }}
                </h2>
                <p class="mb-5 text-black text-opacity-50">
                  {{
                    $t(
                      'pages.blogIndex.text.learnEverythingYouNeedToKnowAboutDeathInTheEyesOfT'
                    )
                  }}
                </p>
                <NuxtLink
                  :to="localePath('/blog/guides')"
                  class="font-medium uppercase underline opacity-50 hover:opacity-100 transition-opacity duration-75"
                >
                  {{ $t('pages.blogIndex.links.viewAllGuides') }}
                </NuxtLink>
              </div>
              <BlogGuideSlider :items="featuredGuides" />
            </div>
            <div v-else class="relative">
              <div class="absolute inset-0 rounded-xl -mx-12 bg-orange-100" />
              <div class="grid-container relative z-10 py-10">
                <div class="grid-item w-full">
                  <div class="flex justify-between items-baseline">
                    <div>
                      <h2 class="heading-6 font-medium mb-2">
                        {{ $t('pages.blogIndex.titles.featuredGuides') }}
                      </h2>
                      <p class="mb-5 text-black text-opacity-50">
                        {{
                          $t(
                            'pages.blogIndex.text.learnEverythingYouNeedToKnowAboutDeathInTheEyesOfT'
                          )
                        }}
                      </p>
                    </div>
                    <NuxtLink
                      :to="localePath('/blog/guides')"
                      class="font-medium uppercase underline opacity-50 hover:opacity-100 transition-opacity duration-75"
                    >
                      {{ $t('pages.blogIndex.links.viewAllGuides') }}
                    </NuxtLink>
                  </div>
                </div>
                <div
                  v-for="(guide, index) in featuredGuides"
                  :key="index"
                  class="grid-item md:w-6/12 lg:w-4/12"
                >
                  <BlogItemGuide v-bind="guide" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="featuredPost" class="container content-padding">
        <div class="grid-container">
          <div class="grid-item w-full">
            <div class="pt-12 border-t border-navy-100 border-opacity-10">
              <h2 class="mb-8 heading-6 font-medium">
                {{ $t('pages.blogIndex.titles.featuredArticle') }}
              </h2>
              <BlogItemFeatured class="mb-8" v-bind="featuredPost" />
            </div>
          </div>
        </div>
      </div>
      <div class="container content-padding">
        <div class="grid-container">
          <div class="grid-item w-full mb-10">
            <h2 class="mb-8 heading-6 font-medium">
              {{ $t('pages.blogIndex.titles.latestArticles') }}
            </h2>
            <div class="flex flex-wrap">
              <NuxtLink :to="localePath('/blog/posts')">
                <BlogTopic active class="mr-2 mb-2">
                  {{ $t('common.titles.all') }}
                </BlogTopic>
              </NuxtLink>
              <NuxtLink
                v-for="{ id, title } in blogTopics"
                :key="id"
                :to="localePath(`/blog/posts?topic=${id}`)"
                class="inline-flex mr-2 mb-2"
              >
                <BlogTopic>
                  {{ title }}
                </BlogTopic>
              </NuxtLink>
            </div>
          </div>
          <div
            v-for="(post, index) in trimmedBlogPosts"
            :key="index"
            class="grid-item w-full md:w-6/12 lg:w-4/12 mb-8"
          >
            <BlogItem v-bind="post" />
          </div>
          <div class="grid-item w-full text-center">
            <NuxtLink
              :to="localePath('/blog/posts')"
              class="font-medium uppercase underline opacity-50 hover:opacity-100 transition-opacity duration-75"
            >
              {{ $t('pages.blogIndex.links.seeMoreArticles') }}
            </NuxtLink>
          </div>
        </div>
      </div>
      <div class="container content-padding">
        <div class="grid-container">
          <div class="grid-item w-full">
            <h2
              class="pt-12 border-t border-navy-100 border-opacity-10 mb-8 xl:mb-16 heading-6 xl:body-1 font-medium"
            >
              {{ $t('pages.blogIndex.titles.writtenByTheSafewillTeam') }}
            </h2>
          </div>
          <div
            v-for="author in featuredAuthors"
            :key="author.id"
            class="grid-item w-full md:w-6/12 mb-16"
          >
            <BlogAuthorLarge details v-bind="author" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BlogAuthorLarge from '~/components/BlogAuthorLarge';
import BlogGuideSlider from '~/components/BlogGuideSlider';
import BlogItem from '~/components/BlogItem';
import BlogItemCompact from '~/components/BlogItemCompact';
import BlogItemFeatured from '~/components/BlogItemFeatured';
import BlogItemGuide from '~/components/BlogItemGuide';
import BlogItemLarge from '~/components/BlogItemLarge';
import BlogTopic from '~/components/BlogTopic';

import GET_BLOG_INDEX_QUERY from '~/graphql/queries/GetBlogIndex';
import { craft, transition } from '~/mixins';

export default {
  name: 'PageBlogIndex',
  components: {
    BlogAuthorLarge,
    BlogGuideSlider,
    BlogItem,
    BlogItemCompact,
    BlogItemFeatured,
    BlogItemGuide,
    BlogItemLarge,
    BlogTopic,
  },
  mixins: [
    craft({
      query: GET_BLOG_INDEX_QUERY,
      transform({
        blogPosts,
        blogTopics,
        page: { featuredAuthors, featuredGuides, featuredPost, trendingPosts },
      }) {
        const visibleTrendingPosts = trendingPosts.filter(
          (post) => !post.isHidden
        );
        const visibleFeaturedPost = featuredPost.filter(
          (post) => !post.isHidden
        );

        return {
          blogPosts,
          blogTopics,
          featuredAuthors,
          featuredGuides,
          featuredPost: visibleFeaturedPost[0],
          trendingPosts: visibleTrendingPosts,
        };
      },
    }),
    transition,
  ],
  data() {
    return {
      blogPosts: [],
      blogTopics: [],
      featuredAuthors: [],
      featuredGuides: [],
      featuredPost: null,
      trendingPosts: [],
    };
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
    trimmedBlogPosts() {
      let posts;

      switch (this.$mq) {
        case 'default':
        case 'sm':
        case 'md':
          posts = this.blogPosts.slice(0, 8);
          break;
        default:
          posts = this.blogPosts;
          break;
      }

      return posts;
    },
  },
};
</script>

<style lang="postcss" scoped>
@screen lg {
  .header-sidebar {
    width: 440px;
  }
}

@screen xl {
  .header-sidebar {
    width: 500px;
  }
}
</style>
