<template>
  <button
    class="flex justify-center items-center w-6 h-6 opacity-30 hover:opacity-100 transition transition-color duration-100"
    type="button"
    :aria-label="ariaLabel"
    @click="$emit('click', $event)"
  >
    <svg
      class="stroke-current"
      width="23"
      height="22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke-width="3" stroke-miterlimit="10">
        <path :d="pathA" />
        <path :d="pathB" stroke-linecap="square" />
      </g>
    </svg>
  </button>
</template>

<script>
export default {
  name: 'ArrowButton',
  props: {
    direction: {
      default: null,
      type: String,
      validate(direction) {
        return ['<', '>'].includes(direction);
      },
    },
  },
  computed: {
    ariaLabel() {
      return this.direction === '<' ? 'previous' : 'next';
    },
    pathA() {
      return this.direction === '<' ? 'M23 11H3' : 'M0 11h20';
    },
    pathB() {
      return this.direction === '<' ? 'M11 3l-8 8 8 8' : 'M12 3l8 8-8 8';
    },
  },
};
</script>
