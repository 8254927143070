<template>
  <div class="flex items-center space-x-4">
    <div
      :class="!photo && 'border border-black border-opacity-10'"
      class="flex-none w-16 h-16 rounded-full overflow-hidden bg-grey-100"
    >
      <BaseAsset
        v-if="photo"
        :asset="photo"
        sizes="64px"
        border
        fit="cover"
        class="rounded-full"
      />
    </div>
    <div class="flex flex-col space-y-2">
      <h5 class="text-xl leading-none" v-text="fullName" />
      <span
        v-if="role"
        class="text-base leading-none text-navy-100 text-opacity-60"
        v-text="role"
      />
    </div>
  </div>
</template>

<script>
import BaseAsset from '~/components/BaseAsset';
import authorProps from '~/modules/author-props';

export default {
  name: 'BlogAuthorSmall',
  components: {
    BaseAsset,
  },
  props: {
    ...authorProps(),
  },
};
</script>
