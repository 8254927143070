<template>
  <div>
    <slot name="promoBanner"></slot>
    <section class="mb-6 xl:mb-10">
      <BaseHeader>
        <template #left>
          <h1
            :class="partnership ? 'xl:heading-2' : 'xl:heading-1'"
            class="mb-6 xl:mb-16 heading-3"
          >
            <MarkedText
              class="text-teal-100"
              :style="markerStyles"
              :text="entry.headerContent.heading"
              type="circle-fill"
            />
          </h1>
          <p
            class="mb-6 xl:mb-10 body-5 xl:body-3 whitespace-pre-line"
            v-text="entry.headerContent.body"
          />
          <div class="mb-4 lg:mb-8">
            <OnboardingButton :to="onboardingTo" arrow size="large">
              {{ $t('common.buttons.getStartedNow') }}
            </OnboardingButton>
          </div>
          <ReviewSummary stars />
        </template>
        <template #right>
          <ImageSlider :assets="entry.headerImage" />
        </template>
      </BaseHeader>
    </section>
    <section class="selection-bg-teal-100">
      <div class="container content-padding">
        <div class="grid-container">
          <div class="grid-item w-full md:w-8/12 lg:w-full">
            <SectionHeading>
              <MarkedText
                :text="entry.steps.heading"
                class="text-teal-100"
                stroke-width="9"
                type="circle-stroke"
              />
            </SectionHeading>
          </div>
          <div
            v-for="(item, index) in entry.steps.items"
            :key="index"
            class="grid-item w-full md:w-8/12 lg:w-4/12 mb-12 xl:mb-0"
          >
            <BaseTextBlock :heading="item.heading" :body="item.body">
              <div
                :class="
                  index === entry.steps.items.length - 1
                    ? null
                    : 'justify-between'
                "
                class="flex items-baseline selection-text-white"
              >
                <span
                  class="inline-block counter text-teal-100"
                  v-text="`0${index + 1}`"
                />
                <ArrowGraphic
                  v-if="!hasMobileLayout"
                  :class="
                    index === entry.steps.items.length - 1 ? 'ml-4' : null
                  "
                  :direction="
                    index === entry.steps.items.length - 1 ? null : 'right'
                  "
                  class="w-32 xl:w-40 mr-4 text-teal-100"
                />
              </div>
            </BaseTextBlock>
          </div>
        </div>
      </div>
    </section>
    <section class="container grid-item w-full mt-8 md:mt-16">
      <div
        class="relative pt-8 xl:py-12 border-t border-b border-navy-100 border-opacity-10"
      >
        <div
          class="absolute top-0 left-0 right-0 flex justify-center items-center h-0 text-lg xl:text-xl leading-none text-center"
        >
          <span
            class="inline px-6 xl:px-8 pb-1 bg-water text-navy-100 text-opacity-50"
          >
            {{ $t('common.text.asSeenOn') }}
          </span>
        </div>
        <BrandLogoSwitcher />
      </div>
    </section>
    <section class="container px-16 grid-item w-full mt-8 md:mt-16">
      <div class="mb-4 text-xl font-bold">
        {{ $t('common.text.frequentlyAskedQuestions') }}
      </div>
      <Faqs :items="entry.questions" />
    </section>
    <section class="selection-bg-teal-100">
      <div class="container content-padding">
        <div class="grid-container">
          <div
            class="grid-item w-full md:w-10/12 lg:w-full mx-auto mb-8 xl:mb-16 text-center"
          >
            <SectionHeading>
              <TextMarker class="text-teal-100" stroke-width="9" type="line-a">
                {{ $t('common.text.trustedByThousands') }}
              </TextMarker>
              {{ $t('common.text.ofAustraliansEachMonth') }}
            </SectionHeading>
          </div>
          <div class="grid-item w-full">
            <ReviewSlider />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ArrowGraphic from '~/components/ArrowGraphic';
import BaseHeader from '~/components/BaseHeader';
import BaseTextBlock from '~/components/BaseTextBlock';
import BrandLogoSwitcher from '~/components/BrandLogoSwitcher';
import MarkedText from '~/components/MarkedText';
import OnboardingButton from '~/components/OnboardingButton';
import ReviewSlider from '~/components/ReviewSlider';
import ReviewSummary from '~/components/ReviewSummary';
import SectionHeading from '~/components/SectionHeading';
import TextMarker from '~/components/TextMarker';
import ImageSlider from '~/components/ImageSlider';
import Faqs from '~/components/Faqs.vue';

export default {
  name: 'LegalAdviceContent',
  components: {
    ArrowGraphic,
    BaseHeader,
    BaseTextBlock,
    BrandLogoSwitcher,
    MarkedText,
    OnboardingButton,
    ReviewSlider,
    ReviewSummary,
    SectionHeading,
    TextMarker,
    ImageSlider,
    Faqs,
  },
  props: {
    entry: {
      default: null,
      type: Object,
    },
    promoPage: {
      default: false,
      type: Boolean,
    },
    promoCode: {
      default: null,
      type: String,
    },
  },
  computed: {
    ...mapGetters(['hasMobileLayout', 'theme', 'partnership']),
    ...mapGetters('globals', ['phoneNumber', 'video', 'videoThumbnail']),
    onboardingTo() {
      return this.promoCode
        ? `${this.$config.safewillAppUrl}${this.localePath(
            `/get-started?coupon=${this.promoCode}`
          )}`
        : null;
    },
    logoStyles() {
      return this.theme
        ? {
            backgroundColor: this.theme.logoBackground,
          }
        : {};
    },
    markerStyles() {
      return this.theme &&
        this.partnership &&
        this.partnership.themedMarkerColour
        ? {
            color: this.theme.background,
          }
        : {};
    },
  },
};
</script>
