<template>
  <button
    class="ru:navigation__toggle text-navy-100"
    :class="openMenuClass"
    :aria-label="$t('components.menuButton.buttons.toggleMenu')"
    @click="$emit('click')"
  >
    <svg
      class="stroke-current"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g class="ru:navigation__toggle-open">
        <path
          stroke-linecap="round"
          stroke-width="2"
          d="M1 20h22M1 12h22M1 4h22"
        />
      </g>
      <g class="ru:navigation__toggle-close">
        <path
          stroke-linecap="round"
          stroke-width="2"
          d="M19.556 19.971 4 4.414M4 20.085 19.556 4.529"
        />
      </g>
    </svg>
  </button>
</template>

<script>
export default {
  name: 'MenuButton',
  props: {
    mobileMenuIsOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    openMenuClass() {
      return this.mobileMenuIsOpen ? '--mobile-menu-open' : null;
    },
  },
};
</script>

<style lang="scss">
.ru\: {
  &navigation__toggle {
    z-index: 50;
    position: fixed;
    right: #{calc(var(--gutter-outer) + var(--gutter-inner))};
    padding: 0.5rem;
    @include mq('min-lg') {
      display: none;
    }

    g {
      transform-origin: center;
    }

    &-open {
      opacity: 1;
      transition: --out();
    }

    &.--mobile-menu-open &-open {
      opacity: 0;
      transform: scale(0.75);
      transition: --in();
    }

    &-close {
      opacity: 0;
      transform: scale(0.75);
      transition: --out();
    }

    &.--mobile-menu-open &-close {
      opacity: 1;
      transform: none;
      transition: --in();
    }
  }
}
</style>
