<template>
  <div class="grid-container">
    <div class="grid-item w-full lg:w-7/12">
      <NuxtLink :to="localePath(`/${uri}`)" class="block mb-6">
        <BaseAsset :asset="headerImage[0]" border crop rounded />
      </NuxtLink>
    </div>
    <div class="grid-item w-full lg:w-5/12">
      <div class="flex items-baseline space-x-3 mb-3">
        <BlogItemType v-if="postType.length" class="bg-green-300 bg-opacity-50">
          {{ postType[0].title }}
        </BlogItemType>
        <span
          class="font-medium text-sm leading-none uppercase text-navy-100 text-opacity-30"
          v-text="readingTime"
        />
      </div>
      <div class="flex flex-col font-medium space-y-1 lg:space-y-2">
        <h3 class="text-4xl leading-tight" v-text="title" />
        <span
          class="text-base text-navy-100 text-opacity-60"
          v-text="author.fullName"
        />
        <p class="summary text-black text-opacity-80" v-text="summary" />
        <NuxtLink
          :to="localePath(`/${uri}`)"
          class="font-medium uppercase underline opacity-50 hover:opacity-100 transition-opacity duration-75"
        >
          {{ $t('components.blogItemFeatured.buttons.continueReading') }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script>
import BaseAsset from '~/components/BaseAsset';
import BlogItemType from '~/components/BlogItemType';

import blogItemProps from '~/modules/blog-item-props';

export default {
  name: 'BlogItemFeatured',
  components: {
    BaseAsset,
    BlogItemType,
  },
  props: { ...blogItemProps() },
};
</script>

<style lang="postcss" scoped>
.summary::after {
  @apply pl-2 text-green-300;
  content: '_';
  font-size: 1.5em;
}
</style>
