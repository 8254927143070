<template>
  <footer
    class="relative pt-10 pb-24 md:pt-16 md:pb-24 xl:py-20 bg-babyblue-50 text-black text-left selection-bg-babyblue-200"
  >
    <div class="container">
      <div class="grid-container">
        <div class="grid-item flex justify-between lg:order-2 w-full mb-12">
          <nuxt-link
            :to="localePath('/')"
            :aria-label="$t('components.footer.text.homepage')"
          >
            <Logo class="text-black" icon-color="black" />
          </nuxt-link>
          <div class="space-x-4 md:space-x-6 xl:space-x-8">
            <SocialButton
              v-for="(channel, index) in socialChannels"
              :key="index"
              :aria-label="channel.platform"
              v-bind="channel"
            />
          </div>
        </div>
        <div
          class="grid-item flex flex-col items-start lg:order-3 w-full lg:w-4/12 2xl:w-3/12 mb-12 lg:mb-16 xl:mb-20"
        >
          <nuxt-link :to="localePath('/how-it-works')" class="footer-link-lg">
            {{ $t('components.footer.text.howItWorks') }}
          </nuxt-link>
          <nuxt-link :to="localePath('/pricing')" class="footer-link-lg">
            {{ $t('common.titles.pricing') }}
          </nuxt-link>
          <a
            target="_blank"
            href="https://safewill.zendesk.com"
            rel="noreferrer noopener"
            class="footer-link-lg"
          >
            {{ $t('components.footer.titles.faqs') }}
          </a>
          <a
            :href="`https://app.safewill.com${localePath(`/log-in`)}`"
            rel="noreferrer"
            class="footer-link-lg"
          >
            {{ $t('common.links.login') }}
          </a>
        </div>
        <div
          class="grid-item flex flex-col items-start lg:order-4 w-2/4 lg:w-3/12 2xl:w-2/12 mb-12 lg:mb-16 xl:mb-20"
        >
          <nuxt-link :to="localePath('/about-us')" class="footer-link-sm">
            {{ $t('components.footer.links.aboutUs') }}
          </nuxt-link>
          <nuxt-link :to="localePath('/blog')" class="footer-link-sm">
            {{ $t('components.footer.links.blog') }}
          </nuxt-link>
          <nuxt-link :to="localePath('/guidance-notes')" class="footer-link-sm">
            {{ $t('components.footer.links.guidanceNotes') }}
          </nuxt-link>
          <nuxt-link :to="localePath('/charities')" class="footer-link-sm">
            {{ $t('components.footer.links.forCharities') }}
          </nuxt-link>
        </div>
        <div
          class="grid-item flex flex-col items-start lg:order-5 w-2/4 lg:w-3/12 2xl:w-2/12 mb-12 lg:mb-16 xl:mb-20"
        >
          <nuxt-link :to="localePath('/terms')" class="footer-link-sm">
            {{ $t('components.footer.links.termsOfUse') }}
          </nuxt-link>
          <nuxt-link :to="localePath('/privacy')" class="footer-link-sm">
            {{ $t('components.footer.links.privacyStatement') }}
          </nuxt-link>
          <nuxt-link :to="localePath('/security')" class="footer-link-sm">
            {{ $t('components.footer.links.security') }}
          </nuxt-link>
          <nuxt-link
            :to="localePath('/charities/directory')"
            class="footer-link-sm"
          >
            {{ $t('components.footer.links.charitiesDirectory') }}
          </nuxt-link>
        </div>
        <div
          class="grid-item lg:order-6 w-full md:w-8/12 lg:w-6/12 2xl:w-4/12 2xl:ml-auto mb-12 xl:mb-20"
        >
          <a href="tel:1800103310" class="footer-link-sm">
            {{ $t('components.footer.links.contactUsOn1800103310') }}
          </a>
          <p class="mb-1 lg:mb-2 lg:body-5 opacity-50">
            {{
              $t(
                'components.footer.text.theBestWayToContactOurCustomerCareTeamIs'
              )
            }}
            <button
              :aria-label="$t('common.buttons.openLiveChat')"
              class="text-black underline hover:no-underline"
              type="button"
              @click="$nuxt.$emit('showChat')"
            >
              {{ $t('components.footer.buttons.viaChat') }}
            </button>
            {{ $t('components.footer.text.weAreOpen7DaysAWeek') }}
          </p>
        </div>
        <div class="grid-item lg:order-1 w-full mb-12">
          <blockquote
            v-if="hasTestimonial"
            class="flex flex-col xl:flex-row items-start p-6 md:p-8 xl:p-10 rounded-xl bg-babyblue-200 bg-opacity-10"
          >
            <QuoteMarks
              class="flex-none h-8 mb-4 xl:mb-0 xl:mr-6 text-black text-opacity-10"
            />
            <transition
              :css="false"
              appear
              mode="out-in"
              @enter="enter"
              @leave="leave"
            >
              <div
                v-for="testimonial in currentTestimonial"
                :key="testimonial.customerName"
                class="flex flex-grow flex-col xl:flex-row items-start"
              >
                <p
                  class="mb-3 xl:mb-0 xl:mr-8 heading-7 md:heading-6 whitespace-pre-line"
                  v-text="testimonial.body"
                />
                <cite
                  class="flex-none inline-block xl:ml-auto xl:mt-auto not-italic text-black text-opacity-30"
                >
                  – {{ testimonial.customerName }},
                  {{ $t('common.text.safewillCustomer') }}
                </cite>
              </div>
            </transition>
          </blockquote>
        </div>
        <div
          class="grid-item lg:order-7 w-full xl:w-9/12 2xl:w-9/12 2xl:ml-auto"
        >
          <p class="md:body-7 text-black text-opacity-50">
            &copy;{{ currentYear }}
            {{ $t('components.footer.text.safeWillPtyLtd') }}, {{ address }}.
            {{ disclaimer }}
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import anime from 'animejs';
import { mapGetters } from 'vuex';

import Logo from '~/components/Logo';
import QuoteMarks from '~/components/QuoteMarks';
import SocialButton from '~/components/SocialButton';

export default {
  name: 'Footer',
  components: {
    Logo,
    QuoteMarks,
    SocialButton,
  },
  data() {
    return {
      currentTestimonialIndex: 0,
      interval: null,
    };
  },
  computed: {
    ...mapGetters('globals', [
      'address',
      'disclaimer',
      'phoneNumber',
      'socialChannels',
      'testimonials',
    ]),
    currentTestimonial() {
      return [this.testimonials[this.currentTestimonialIndex]];
    },
    currentYear() {
      return new Date().getFullYear();
    },
    hasTestimonial() {
      return typeof this.currentTestimonial[0] !== 'undefined';
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.currentTestimonialIndex =
        (this.currentTestimonialIndex + 1) % this.testimonials.length;
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    enter(targets, complete) {
      anime({
        targets,
        opacity: [0, 1],
        duration: 400,
        easing: 'easeInOutQuad',
        complete,
      });
    },
    leave(targets, complete) {
      anime({
        targets,
        opacity: [1, 0],
        duration: 400,
        easing: 'easeInOutQuad',
        complete,
      });
    },
  },
};
</script>
