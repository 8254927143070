<template>
  <div
    class="border-t-3 border-b-3 border-teal-200 divide-y-3 divide-teal-200 selection-bg-teal-200"
  >
    <FaqsItem
      v-for="(item, index) in items"
      ref="items"
      :key="index"
      :active="isItemActive(index)"
      v-bind="item"
      @click="toggleItemActive(index)"
    />
  </div>
</template>

<script>
import FaqsItem from '~/components/FaqsItem';

export default {
  name: 'Faqs',
  components: {
    FaqsItem,
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      activeItems: [],
    };
  },
  methods: {
    isItemActive(item) {
      return this.activeItems.includes(item);
    },
    toggleItemActive(item) {
      const index = this.activeItems.indexOf(item);
      if (index > -1) {
        this.activeItems.splice(index, 1);
      } else {
        this.activeItems.push(item);
      }
    },
  },
};
</script>
