<template>
  <div>
    <BaseHeader>
      <template #left>
        <h1 class="mb-6 xl:mb-16 heading-3 xl:heading-2">
          <MarkedText
            text="<p><em>Careers</em> at Safewill</p>"
            class="text-teal-100"
            type="circle-fill"
          />
        </h1>
        <p
          class="body-5 xl:body-3 whitespace-pre-line"
          v-text="
            `Thousands of Australians have written their Wills using Safewill, and we’re only just getting started. Join our awesome team and help us lead Australia’s end-of-life services platform.`
          "
        />

        <ArrowGraphic
          v-if="!hasMobileLayout"
          class="w-10 mt-10 xl:mt-16 text-teal-100"
          direction="down"
        />
      </template>
      <template #right>
        <BaseAsset
          :asset="headerImage"
          :crop="hasMobileLayout"
          class="mb-8 md:mb-12 xl:mb-0"
          rounded
          sizes="(min-width: 1440px) 600px, (min-width: 1280px) 42vw, (min-width: 1024px) 45vw, (min-width: 768px) 67vw, 85vw"
        />
        <div
          v-if="hasMobileLayout"
          class="flex justify-center md:justify-start"
        >
          <ArrowGraphic class="w-10 text-teal-100" direction="down" />
        </div>
      </template>
    </BaseHeader>
    <section>
      <div class="container content-padding mt-8">
        <h2 class="heading-5 font-medium text-center mb-8">
          {{ $t('pages.careers.titles.jobOpenings') }}
        </h2>

        <ul role="list" class="m-8">
          <li
            v-for="job in jobs"
            :key="job.title"
            class="grid md:grid-cols-5 grid-cols-1 md:gap-16 border-solid border-b border-grey-300 last:border-b-0"
          >
            <span class="col-span-2">
              <strong class="text-green-400 font-medium">{{
                job.title
              }}</strong>
              <br />
              <small class="text-black text-opacity-30">{{
                job.postedTime
              }}</small>
            </span>
            <span class="text-black text-opacity-30">{{ job.location }}</span>
            <span class="text-black text-opacity-30">{{ job.team }}</span>
            <span class="text-black text-opacity-30">{{
              job.workingTime
            }}</span>
          </li>
        </ul>
        <p class="heading-5 text-center m-8">
          {{ $t('pages.careers.text.ifYoudLikeToApplySendYourResumeTo') }}
          <a class="text-green-400" href="mailto: jobs@safewill.com">
            jobs@safewill.com
          </a>
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ArrowGraphic from '~/components/ArrowGraphic';
import BaseHeader from '~/components/BaseHeader';
import BaseAsset from '~/components/BaseAsset';
import MarkedText from '~/components/MarkedText';

export default {
  name: 'PageCareers',
  components: {
    BaseHeader,
    BaseAsset,
    MarkedText,
    ArrowGraphic,
  },
  data() {
    return {
      headerImage: {
        filename: 'groupshot-safewill.jpg',
        kind: 'image',
        lg: 'https://assets.safewill.com/images/_lg/groupshot-safewill.jpg',
        md: 'https://assets.safewill.com/images/_md/groupshot-safewill.jpg',
        original: 'https://assets.safewill.com/images/groupshot-safewill.jpg',
        sm: 'https://assets.safewill.com/images/_sm/groupshot-safewill.jpg',
        title: 'Groupshot safewill',
        xl: 'https://assets.safewill.com/images/_xl/groupshot-safewill.jpg',
        xs: 'https://assets.safewill.com/images/_xs/groupshot-safewill.jpg',
      },
      jobs: [
        {
          title: 'Senior Product Designer',
          location: 'Sydney, New South Wales, Australia',
          team: 'Product',
          workingTime: 'Full time',
          postedTime: 'Posted 2 months ago',
        },
        {
          title: 'Product Manager',
          location: 'Sydney, New South Wales, Australia',
          team: 'Product',
          workingTime: 'Full time',
          postedTime: 'Posted 2 months ago',
        },
        {
          title: 'Performance Marketer',
          location: 'Sydney, New South Wales, Australia',
          team: 'Growth',
          workingTime: 'Full time',
          postedTime: 'Posted 2 months ago',
        },
        {
          title: 'Sales Development Representative',
          location: 'Sydney, New South Wales, Australia',
          team: 'Partnerships',
          workingTime: 'Full time',
          postedTime: 'Posted 2 months ago',
        },
        {
          title: 'Sr. Fullstack Developer',
          location: 'Sydney, New South Wales, Australia',
          team: 'Engineering',
          workingTime: 'Full time',
          postedTime: 'Posted 2 months ago',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
  },
};
</script>
