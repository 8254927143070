<template>
  <div class="flex flex-1 flex-wrap xl:justify-between xl:items-center">
    <div
      v-for="(brand, index) in activeBrands"
      :key="index"
      ref="logos"
      class="flex justify-center items-center w-1/2 md:w-4/12 xl:w-2/12 h-8 xl:h-10 mb-6 xl:mb-0 xl:px-2"
    >
      <BrandLogos
        :brand="brand"
        :fill="require('~/tailwind.config.js').theme.colors.navy[100]"
        class="xl:mx-auto"
        @click.native="updatePicks(index)"
      />
    </div>
  </div>
</template>

<script>
import anime from 'animejs';
import { isNumber, remove } from 'lodash';
import { mapGetters } from 'vuex';

import BrandLogos from '~/components/BrandLogos';

export default {
  name: 'BrandLogoSwitcher',
  components: {
    BrandLogos,
  },
  data() {
    return {
      count: 6,
      duration: 3000,
      indexPicks: [],
      indexPool: [],
      timer: null,
    };
  },
  computed: {
    ...mapGetters(['brandSwitcherLogos']),
    activeBrands() {
      return this.indexPicks.map((index) => this.brandSwitcherLogos[index]);
    },
  },
  mounted() {
    this.indexPool = Array(this.brandSwitcherLogos.length)
      .fill()
      .map((_, i) => i);

    for (let i = 0; i < this.count; i++) {
      const pick = this.getRandomInt(0, this.indexPool.length - 1);

      this.indexPicks.push(
        remove(this.indexPool, (n) => n === this.indexPool[pick])[0]
      );
    }

    if (this.brandSwitcherLogos.length > this.count) {
      this.startTimer();
    }
  },
  beforeDestroy() {
    this.cancelTimer();
  },
  methods: {
    cancelTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    startTimer() {
      this.timer = setTimeout(this.updatePicks, this.duration);
    },
    updatePicks(index) {
      if (this.timer) {
        this.cancelTimer();
      }

      const oldIndex = isNumber(index)
        ? index
        : this.getRandomInt(0, this.indexPicks.length - 1);
      const oldValue = this.indexPicks[oldIndex];
      const newIndex = this.getRandomInt(0, this.indexPool.length - 1);
      const newValue = this.indexPool[newIndex];

      const targets = this.$refs.logos[oldIndex];

      anime({
        targets,
        opacity: 0,
        scale: 0.9,
        translateZ: 0,
        duration: 300,
        easing: 'easeInQuad',
        complete: () => {
          this.indexPicks.splice(oldIndex, 1, newValue);
          this.indexPool.splice(newIndex, 1, oldValue);

          anime({
            targets,
            opacity: [0, 1],
            scale: 1,
            translateZ: 0,
            duration: 300,
            delay: 100,
            easing: 'easeOutQuad',
            complete: () => {
              this.startTimer();
            },
          });
        },
      });
    },
  },
};
</script>
