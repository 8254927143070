<template>
  <div>
    <header class="selection-bg-babyblue-100">
      <div class="container">
        <div class="grid-container">
          <div class="grid-item w-full">
            <div class="rounded-xl overflow-hidden bg-babyblue-100 text-center">
              <div class="p-6 md:p-20 lg:p-16 xl:p-12">
                <h1 class="mb-6 xl:mb-16 heading-3 xl:heading-1">
                  <MarkedText
                    class="text-white"
                    :text="headerContent.heading"
                    type="circle-fill"
                  />
                </h1>
                <div
                  class="max-w-3xl mx-auto mb-6 xl:mb-10 body-5 xl:body-3"
                  v-text="headerContent.body"
                />
                <NewsletterForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section class="selection-bg-babyblue-100">
      <div class="container content-padding">
        <div class="grid-container">
          <div
            class="grid-item w-full md:w-10/12 mx-auto mb-8 xl:mb-16 text-center"
          >
            <SectionHeading>
              {{
                $t(
                  'pages.newsletter.text.weProvideThousandsOfArticlesAndToolsDesignedToHelp'
                )
              }}
            </SectionHeading>
          </div>
        </div>
      </div>
      <div class="container content-padding">
        <div
          class="grid-container lg:mb-16 space-y-24 md:space-y-20 xl:space-y-32"
        >
          <div
            v-for="(feature, index) in features"
            :key="index"
            class="grid-item w-full"
          >
            <div
              :class="index % 2 ? 'md:flex-row' : 'md:flex-row-reverse'"
              class="grid-container"
            >
              <div
                :class="index % 2 ? 'xl:ml-auto' : 'xl:mr-auto'"
                class="grid-item w-full md:w-10/12 lg:w-1/2 xl:w-5/12 mb-24 md:mx-auto"
              >
                <div class="relative">
                  <CharitiesGraphic :index="index" class="relative z-10" />
                  <div class="absolute inset-0 rounded-xl bg-babyblue-100" />
                </div>
              </div>
              <div
                :class="index % 2 ? 'xl:mr-auto' : 'xl:ml-auto'"
                class="grid-item w-full lg:w-1/2 xl:w-5/12 md:pt-8 lg:pt-12"
              >
                <BaseTextBlock
                  :heading="feature.heading"
                  :body="feature.body"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <header class="selection-bg-babyblue-100">
        <div class="container">
          <div class="grid-container">
            <div class="grid-item w-full">
              <div
                class="rounded-xl bg-babyblue-100 p-6 pt-0 md:p-20 md:pt-0 lg:p-16 lg:pt-0 xl:p-12 xl:pt-0"
              >
                <BaseHeader>
                  <template #left>
                    <h1 class="heading-3 mb-6">
                      <MarkedText
                        class="text-teal-100"
                        text="Our charity partnerships <em>in the news</em>"
                        type="circle-fill"
                      />
                    </h1>
                    <p class="mb-6 body-5 xl:body-3">
                      {{
                        $t(
                          'common.text.safewillWasFeaturedOn7newsNationallyForOurWorkSupp'
                        )
                      }}
                    </p>
                  </template>
                  <template #right>
                    <VideoCard
                      :video="video"
                      :video-thumbnail="videoThumbnail"
                      btn-text="Safewill on 7News"
                      class="lg:mt-12 xl:mt-16"
                    />
                  </template>
                </BaseHeader>
              </div>
            </div>
          </div>
        </div>
        <div class="container content-padding">
          <div class="grid-container">
            <div
              class="grid-item w-full md:w-10/12 lg:w-full mx-auto mb-8 xl:mb-16 text-center"
            >
              <SectionHeading>
                <TextMarker
                  class="text-teal-100"
                  stroke-width="9"
                  type="line-a"
                >
                  {{ $t('common.text.millions') }}
                </TextMarker>
                {{ $t('common.text.pledgedByOurCustomersMonthly') }}
              </SectionHeading>
            </div>
            <div class="grid-item w-full">
              <ReviewSlider />
            </div>
          </div>
        </div>
      </header>
    </section>
    <VideoModal :video="video" />
  </div>
</template>

<script>
import BaseHeader from '~/components/BaseHeader';
import NewsletterForm from '~/components/NewsletterForm';
import MarkedText from '~/components/MarkedText';
import SectionHeading from '~/components/SectionHeading';
import BaseTextBlock from '~/components/BaseTextBlock';
import CharitiesGraphic from '~/components/CharitiesGraphic';
import ReviewSlider from '~/components/ReviewSlider';
import VideoCard from '~/components/VideoCard';
import VideoModal from '~/components/VideoModal';
import TextMarker from '~/components/TextMarker';

import { home, seomatic, transition, partnerships } from '~/mixins';

export default {
  name: 'PageNewsletter',
  components: {
    BaseHeader,
    NewsletterForm,
    MarkedText,
    SectionHeading,
    BaseTextBlock,
    CharitiesGraphic,
    ReviewSlider,
    VideoCard,
    VideoModal,
    TextMarker,
  },
  mixins: [home, seomatic, transition, partnerships],
  data() {
    return {
      features: [
        {
          heading: 'Simplifying End-of-life:',
          body: "Twice a month, we'll send you actionable advice for you to learn and prepare end of life.",
        },
        {
          heading: 'Simple & Actionable',
          body: 'Actionable Recommendations Curated By Experts Straight to Your Email Once A Month',
        },
        {
          heading: '100% Free',
          body: 'Our Newsletter is 100% Free for everyone',
        },
      ],
      headerContent: {
        heading: 'Safewill Newsletter',
        body: 'A Bi-Monthly Newsletter Helping You Navigate The Complexities Of End Of Life Planning',
      },
      intro: '',
      video: {
        title:
          '7NEWS-How-Safewill-is-helping-charities-secure-their-financial-future1',
        filename:
          '7NEWS-How-Safewill-is-helping-charities-secure-their-financial-future1.mp4',
        kind: 'video',
        url: 'https://assets.safewill.com/videos/7NEWS-How-Safewill-is-helping-charities-secure-their-financial-future1.mp4',
        __typename: 's3Bucket_Asset',
      },
      videoThumbnail: {
        title: '7news-charities-thumb',
        filename: '7news-charities-thumb.png',
        kind: 'image',
        original:
          'https://assets.safewill.com/images/7news-charities-thumb.png',
        xs: 'https://assets.safewill.com/images/7news-charities-thumb.png',
        sm: 'https://assets.safewill.com/images/7news-charities-thumb.png',
        md: 'https://assets.safewill.com/images/7news-charities-thumb.png',
        lg: 'https://assets.safewill.com/images/7news-charities-thumb.png',
        xl: 'https://assets.safewill.com/images/7news-charities-thumb.png',
        __typename: 's3Bucket_Asset',
      },
      logos: [{ image: {}, size: '' }],
    };
  },
};
</script>
