import { render, staticRenderFns } from "./power-of-attorney.vue?vue&type=template&id=2ac6ff28&"
import script from "./power-of-attorney.vue?vue&type=script&lang=js&"
export * from "./power-of-attorney.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports