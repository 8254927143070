<template>
  <div
    class="inline-flex justify-center items-center px-3 font-medium text-sm leading-none uppercase text-navy-100 text-opacity-50"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlogItemType',
};
</script>

<style lang="postcss" scoped>
div {
  height: 22px;
  border-radius: 8px;
}
</style>
