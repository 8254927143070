<template>
  <div>
    <header>
      <div class="container">
        <div class="grid-container">
          <div
            class="grid-item flex flex-col w-full lg:w-10/12 xl:w-8/12 mx-auto mb-6 xl:mb-16 space-y-2 md:space-y-3"
          >
            <div class="flex items-baseline space-x-3 mb-3">
              <BlogItemType v-if="sections" class="bg-green-300 bg-opacity-50">
                {{ $t('common.text.guide') }}
              </BlogItemType>
              <BlogItemType
                v-else-if="postType.length"
                class="bg-green-300 bg-opacity-50"
              >
                {{ postType[0].title }}
              </BlogItemType>
              <span
                class="font-medium text-sm leading-none uppercase text-navy-100 text-opacity-30"
                v-text="readingTime"
              />
            </div>
            <h1
              class="xl:mb-4 font-medium heading-5 md:heading-4"
              v-text="title"
            />
            <p
              class="body-5 md:body-4 text-black text-opacity-70"
              v-text="summary"
            />
            <div>
              <slot name="header" />
            </div>
          </div>
        </div>
        <div class="grid-item w-full">
          <BaseAsset
            :asset="headerImage[0]"
            class="aspect-wide"
            crop
            rounded
            sizes="(min-width: 1440px) 720px, (min-width: 1280px) 57vw, (min-width: 1024px) 62vw, (min-width: 768px) 75vw, 85vw"
          />
        </div>
      </div>
    </header>
    <section>
      <div class="container content-padding">
        <div ref="container" class="grid-container">
          <div
            v-scroll="{ callback: onScroll }"
            class="grid-item hidden lg:block lg:w-4/12 space-y-12"
          >
            <div ref="sidebar" class="lg:space-y-8 xl:space-y-10">
              <div>
                <slot name="sidebar" />
              </div>
              <div class="space-y-4">
                <h4 class="font-medium">
                  {{ $t('components.blogTemplate.titles.author') }}
                </h4>
                <BlogAuthorSmall v-bind="author" />
              </div>
              <div v-if="topics.length" class="space-y-4">
                <h4 class="font-medium">
                  {{ $t('components.blogTemplate.titles.topics') }}
                </h4>
                <div>
                  <NuxtLink
                    v-for="topic in topics"
                    :key="topic.id"
                    :to="localePath(`/blog/posts?topic=${topic.id}`)"
                    class="inline-flex mr-2 mb-2"
                  >
                    <BlogTopic>
                      {{ topic.title }}
                    </BlogTopic>
                  </NuxtLink>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item w-full lg:w-8/12">
            <div class="max-w-3xl mb-12">
              <slot />
            </div>
            <div>
              <span
                class="date-updated font-medium uppercase text-navy-100 text-opacity-30"
              >
                {{
                  $t('components.blogTemplate.text.lastUpdated', {
                    dateUpdated: dateUpdated,
                  })
                }}
              </span>
            </div>
            <div
              class="pt-8 lg:pt-12 border-t border-navy-100 border-opacity-10 mt-8 lg:mt-12"
            >
              <BlogAuthorSmall v-if="hasMobileLayout" v-bind="author" />
              <BlogAuthorLarge v-else details v-bind="author" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseAsset from '~/components/BaseAsset';
import BlogAuthorSmall from '~/components/BlogAuthorSmall';
import BlogAuthorLarge from '~/components/BlogAuthorLarge';
import BlogItemType from '~/components/BlogItemType';
import BlogTopic from '~/components/BlogTopic';

import blogItemProps from '~/modules/blog-item-props';

export default {
  name: 'BlogTemplate',
  components: {
    BaseAsset,
    BlogAuthorSmall,
    BlogAuthorLarge,
    BlogItemType,
    BlogTopic,
  },
  props: {
    ...blogItemProps(),
    sections: {
      default: null,
      type: Array,
    },
    textContent: {
      default: null,
      type: String,
    },
    topics: {
      required: true,
      type: Array,
    },
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
  },
  methods: {
    onScroll() {
      if (!this.hasMobileLayout) {
        const rect = this.$rect(this.$refs.container);
        const maxY =
          this.$refs.container.offsetHeight - this.$refs.sidebar.offsetHeight;
        const translateY = Math.max(
          0,
          Math.min(maxY, this.$store.state.navBarHeight + 20 - rect.top)
        );

        this.$refs.sidebar.style.transform = `translate3d(0, ${translateY}px, 0)`;
      } else {
        this.$refs.sidebar.style.transform = null;
      }
    },
  },
  head() {
    const script = [];
    try {
      if (
        this.structuredDataType.includes('howTo') &&
        this.howToStructuredData &&
        this.howToStructuredDataName
      ) {
        const structuredData = {
          '@context': 'https://schema.org',
          '@type': 'HowTo',
          name: this.howToStructuredDataName,
          step: this.howToStructuredData.map((step, index) => ({
            '@type': 'HowToStep',
            name: step.name,
            text: step.text,
            step: (index + 1).toString(),
          })),
        };
        script.push({
          json: structuredData,
          type: 'application/ld+json',
        });
      }

      if (this.structuredDataType.includes('faq') && this.faqStructuredData) {
        const structuredData = {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: this.faqStructuredData.map((question) => ({
            '@type': 'Question',
            name: question.question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: question.answer,
            },
          })),
        };
        script.push({
          json: structuredData,
          type: 'application/ld+json',
        });
      }

      if (
        this.structuredDataType.includes('custom') &&
        this.customStructuredData
      ) {
        const structuredData = JSON.parse(this.customStructuredData);
        script.push({
          json: structuredData,
          type: 'application/ld+json',
        });
      }
      if (script.length) {
        return {
          script,
        };
      }
      return {};
    } catch (err) {
      return {};
    }
  },
};
</script>

<style lang="postcss" scoped>
.date-updated {
  font-size: 14px;
}
</style>
