<template>
  <div>
    <BaseHeader>
      <template #left>
        <h1 class="mb-6 xl:mb-16 heading-3 xl:heading-2">
          <MarkedText
            class="text-teal-100"
            :text="headerContent.heading"
            type="circle-fill"
          />
        </h1>
        <p
          class="mb-6 body-5 xl:body-3 whitespace-pre-line"
          v-text="headerContent.body"
        />
      </template>
      <template #right>
        <VideoCard
          :video="video"
          :video-thumbnail="videoThumbnail"
          btn-text="Watch our explainer video"
          class="lg:mt-12 xl:mt-16"
        />
      </template>
    </BaseHeader>
    <section>
      <div class="container content-padding">
        <div class="grid-container space-y-12 md:space-y-20 xl:space-y-40">
          <div
            v-for="(step, index) in steps"
            :key="index"
            class="grid-item w-full selection-bg-teal-100"
          >
            <div class="grid-container">
              <div
                class="grid-item w-full md:w-10/12 lg:w-7/12 xl:w-6/12 mb-6 md:mb-16 lg:mb-0"
              >
                <span
                  class="inline-block lg:mb-4 counter text-teal-100"
                  v-text="`0${index + 1}`"
                />
                <SectionHeading>
                  {{ step.heading }}
                </SectionHeading>
                <p
                  class="mb-4 lg:mb-10 xl:mb-24 whitespace-pre-line"
                  v-text="step.body"
                />
                <div class="xl:pl-8 text-teal-100">
                  <ArrowGraphic
                    v-if="index !== steps.length - 1"
                    class="hidden lg:block w-10"
                    direction="down"
                  />
                  <Signature
                    v-else-if="!hasMobileLayout"
                    class="w-full lg:max-w-xs xl:max-w-sm"
                  />
                </div>
              </div>
              <div
                :class="index === 0 ? 'md:w-8/12' : null"
                class="relative grid-item w-full lg:w-5/12 xl:w-6/12"
              >
                <AppCardAnimation
                  v-if="index === 0"
                  class="md:pt-12 lg:pt-24 xl:pt-48"
                />
                <div
                  v-else
                  class="relative flex justify-center lg:pt-24 xl:pt-32"
                >
                  <Signature
                    v-if="hasMobileLayout && index === steps.length - 1"
                    class="absolute top-0 left-0 right-0 -mx-8 md:mx-8 mt-12 md:mt-32 text-teal-100"
                  />
                  <WillGraphic
                    :action="index === steps.length - 1 ? 'sign' : 'review'"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item w-full">
            <BaseParallaxBlock
              :asset="updates.backgroundImage[0]"
              :heading="updates.heading"
            >
              <p class="whitespace-pre-line" v-text="updates.body" />
            </BaseParallaxBlock>
          </div>
        </div>
      </div>
    </section>
    <VideoModal :video="video" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AppCardAnimation from '~/components/AppCardAnimation';
import ArrowGraphic from '~/components/ArrowGraphic';
import BaseHeader from '~/components/BaseHeader';
import BaseParallaxBlock from '~/components/BaseParallaxBlock';
import MarkedText from '~/components/MarkedText';
import SectionHeading from '~/components/SectionHeading';
import Signature from '~/components/Signature';
import VideoCard from '~/components/VideoCard';
import WillGraphic from '~/components/WillGraphic';
import VideoModal from '~/components/VideoModal';

import GET_HOW_IT_WORKS_QUERY from '~/graphql/queries/GetHowItWorks';
import { craft, seomatic, transition, partnerships } from '~/mixins';
import { collapseFieldArray } from '~/util';

export default {
  name: 'PageHowItWorks',
  components: {
    AppCardAnimation,
    ArrowGraphic,
    BaseHeader,
    BaseParallaxBlock,
    MarkedText,
    SectionHeading,
    Signature,
    VideoCard,
    WillGraphic,
    VideoModal,
  },
  mixins: [
    craft({
      query: GET_HOW_IT_WORKS_QUERY,
      transform({ entry }) {
        entry.headerContent = collapseFieldArray(entry.headerContent);
        entry.updates = collapseFieldArray(entry.updates);

        return entry;
      },
    }),
    seomatic,
    transition,
    partnerships,
  ],
  data() {
    return {
      headerContent: { heading: '', body: '' },
      steps: [],
      updates: [],
    };
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
    ...mapGetters('globals', ['video', 'videoThumbnail']),
  },
};
</script>
