<template>
  <a :href="href" :class="buttonStyle">
    <span v-if="arrow" class="mr-4 text-turquoise-200">
      <svg
        class="mt-px stroke-current"
        width="23"
        height="22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 11h20" stroke-width="3" stroke-miterlimit="10" />
        <path
          d="M12 3l8 8-8 8"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
      </svg>
    </span>
    <span class="text-center">
      <slot v-if="!isReturning">
        {{ label }}
      </slot>
      <span v-else>
        {{ label }}
      </span>
    </span>
  </a>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OnboardingButton',
  props: {
    arrow: {
      default: false,
      type: Boolean,
    },
    size: {
      default: 'small',
      type: String,
    },
    to: {
      type: String,
      default: '',
      required: false,
    },
    classes: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['appQuery', 'isReturning']),
    buttonSize() {
      switch (this.size) {
        case 'large':
          return 'button-lg rounded-lg';
        case 'medium':
          return 'button-md';
        case 'small':
          return 'button-sm';
        default:
          return null;
      }
    },
    buttonStyle() {
      return this.classes || `button button-navy ${this.buttonSize}`;
    },
    label() {
      return this.isReturning ? 'Finish your Will' : 'Get Started';
    },
    href() {
      return this.to
        ? this.to
        : this.isReturning
        ? `${this.$config.safewillAppUrl}${this.localePath(
            `/dashboard${this.appQuery}`
          )}`
        : `${this.$config.safewillAppUrl}${this.localePath(
            `/get-started${this.appQuery}`
          )}`;
    },
  },
};
</script>
