<template>
  <span>
    <!-- eslint-disable vue/no-v-html -->
    <span class="relative z-20 text-black" v-html="beforeText" /><TextMarker
      v-if="highlightText"
      :stroke-width="strokeWidth"
      :type="type"
    >
      {{ highlightText }}
    </TextMarker>
    <span
      v-if="afterText"
      class="relative z-20 text-black after-text"
      v-html="afterText"
    />
    <!--eslint-enable-->
  </span>
</template>

<script>
import striptags from 'striptags';

import TextMarker from '~/components/TextMarker';

export default {
  name: 'MarkedText',
  components: {
    TextMarker,
  },
  props: {
    strokeWidth: {
      default: null,
      type: [Number, String],
    },
    text: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
  },
  computed: {
    afterText() {
      return this.hasHighlight ? this.strippedText.split('</em>')[1] : '';
    },
    beforeText() {
      return this.hasHighlight
        ? this.strippedText.split('<em>')[0]
        : this.strippedText;
    },
    hasHighlight() {
      return this.strippedText.includes('<em>');
    },
    highlightText() {
      return this.hasHighlight
        ? this.strippedText.split('<em>')[1].split('</em>')[0]
        : '';
    },
    strippedText() {
      return striptags(this.text.replace('</p><p>', '<br>'), [
        'br',
        'em',
        'small',
      ]);
    },
  },
};
</script>

<style>
.after-text small {
  line-height: 0;
}

@media screen and (min-width: 768px) {
  .after-text small {
    font-size: 60%;
  }
}
</style>
