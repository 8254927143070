<template>
  <div class="flex flex-col items-center">
    <div
      class="h-40 xl:h-48 xl:h-56 rounded-full mb-4 xl:mb-6 overflow-hidden bg-teal-100"
    >
      <BaseAsset
        :asset="portrait[0]"
        fit="cover"
        sizes="(min-width: 1280px) 224px, 160px"
      />
    </div>
    <h3 class="body-5" v-text="name" />
    <h4 class="text-center body-7 text-black text-opacity-70" v-text="role" />
  </div>
</template>

<script>
import BaseAsset from '~/components/BaseAsset';

export default {
  name: 'TeamMember',
  components: {
    BaseAsset,
  },
  props: {
    /* eslint-disable-next-line vue/prop-name-casing */
    __typename: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    portrait: {
      default: null,
      type: Array,
    },
    role: {
      required: true,
      type: String,
    },
  },
};
</script>
