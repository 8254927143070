<template>
  <div class="inline-flex">
    <svg
      v-for="n in stars"
      :key="n"
      class="mr-1 last:mr-0 fill-current"
      :width="size"
      :height="size"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path :d="path" />
    </svg>
    <div class="inline-flex text-grey-200">
      <svg
        v-for="n in 5 - stars"
        :key="n"
        class="mr-1 last:mr-0 fill-current"
        :width="size"
        :height="size"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path :d="path" />
      </svg>
    </div>
  </div>
</template>

<script>
import reviewsData from '~/reviews.json';

export default {
  name: 'ReviewStars',
  props: {
    large: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    path() {
      return this.large
        ? 'M16 23l-9 6 4-10-9-6h10l4-10 4 10h10l-9 6 4 10z'
        : 'M6.534 1.193a.5.5 0 01.932 0l1.449 3.712a.5.5 0 00.437.318l3.978.23a.5.5 0 01.288.887l-3.083 2.525a.5.5 0 00-.167.513l1.01 3.855a.5.5 0 01-.754.548L7.27 11.629a.5.5 0 00-.54 0l-3.354 2.152a.5.5 0 01-.754-.548l1.01-3.855a.5.5 0 00-.167-.513L.382 6.34a.5.5 0 01.288-.886l3.978-.231a.5.5 0 00.437-.318l1.45-3.712z';
    },
    size() {
      return this.large ? 32 : 14;
    },
    stars() {
      return Math.round(reviewsData.averageRating);
    },
  },
};
</script>
