<template>
  <div>
    <div class="relative mb-6">
      <div
        class="ml-2 md:ml-0 flex absolute top-0 left-0 bottom-0 justify-end items-center w-0"
      >
        <ArrowButton direction="<" @click="keen.prev()" />
      </div>
      <transition :css="false" appear @enter="enter" @leave="leave">
        <div
          ref="keen"
          :class="hasTouch ? 'lg:mx-16 overflow-visible' : 'lg:mx-0'"
          class="keen-slider mx-6 xl:mx-8"
        >
          <div
            v-for="(review, index) in reviews"
            :key="index"
            class="keen-slider__slide flex"
          >
            <ReviewCard
              :class="cardClass"
              :star-class="starClass"
              v-bind="review"
            />
          </div>
        </div>
      </transition>
      <div
        class="mr-2 md:mr-0 flex absolute top-0 bottom-0 right-0 items-center w-0"
      >
        <ArrowButton direction=">" @click="keen.next()" />
      </div>
    </div>
    <div class="text-center">
      <ReviewSummary />
    </div>
  </div>
</template>

<script>
import anime from 'animejs';
import KeenSlider from 'keen-slider';
import detectIt from 'detect-it';

import reviewsData from '~/reviews.json';

import ArrowButton from '~/components/ArrowButton';
import ReviewCard from '~/components/ReviewCard';
import ReviewSummary from '~/components/ReviewSummary';

export default {
  name: 'ReviewSlider',
  components: {
    ArrowButton,
    ReviewCard,
    ReviewSummary,
  },
  props: {
    cardClass: {
      default: 'bg-grey-100',
      type: String,
    },
    starClass: {
      default: 'text-teal-200',
      type: String,
    },
  },
  data() {
    return {
      hasTouch: false,
      keen: null,
    };
  },
  computed: {
    reviews() {
      return reviewsData.reviews;
    },
  },
  mounted() {
    this.hasTouch = detectIt.deviceType !== 'mouseOnly';

    this.buildKeen();
  },
  beforeDestroy() {
    if (this.keen) {
      this.keen.destroy();
    }
  },
  methods: {
    buildKeen() {
      if (this.reviews.length) {
        this.keen = new KeenSlider(this.$refs.keen, {
          breakpoints: {
            '(min-width: 768px)': {
              slidesPerView: 2,
              spacing: 24,
            },
            '(min-width: 1024px)': {
              slidesPerView: 2,
              spacing: 32,
            },
            '(min-width: 1280px)': {
              slidesPerView: 3,
              spacing: 32,
            },
            '(min-width: 1440px)': {
              slidesPerView: 4,
              spacing: 32,
            },
          },
          duration: 750,
          loop: this.hasTouch,
          spacing: 16,
        });
      }
    },
    enter(targets, complete) {
      anime({
        targets,
        opacity: [0, 1],
        duration: 300,
        easing: 'easeInOutQuad',
        complete,
      });
    },
    leave(targets, complete) {
      anime({
        targets,
        opacity: [1, 0],
        duration: 300,
        easing: 'easeInOutQuad',
        complete,
      });
    },
  },
};
</script>
