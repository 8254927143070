<template>
  <ContentPage v-bind="entry" />
</template>

<script>
import GET_PRIVACY_QUERY from '~/graphql/queries/GetPrivacy';

import ContentPage from '~/components/ContentPage';

import { craft, seomatic, transition, partnerships } from '~/mixins';

export default {
  name: 'PagePrivacy',
  components: {
    ContentPage,
  },
  mixins: [
    craft({
      query: GET_PRIVACY_QUERY,
    }),
    seomatic,
    transition,
    partnerships,
  ],
  data() {
    return {
      entry: null,
    };
  },
};
</script>
