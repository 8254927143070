<template>
  <div>
    <BaseHeader>
      <template #left>
        <h1 class="mb-6 xl:mb-16 heading-3 xl:heading-2">
          <MarkedText
            :text="headerContent.heading"
            class="text-teal-100"
            type="circle-fill"
          />
        </h1>
        <p
          class="body-5 xl:body-3 whitespace-pre-line"
          v-text="headerContent.body"
        />
        <ArrowGraphic
          v-if="!hasMobileLayout"
          class="w-10 mt-10 xl:mt-16 text-teal-100"
          direction="down"
        />
      </template>
      <template #right>
        <BaseAsset
          :asset="headerImage[0]"
          :crop="hasMobileLayout"
          class="mb-8 md:mb-12 xl:mb-0"
          rounded
          sizes="(min-width: 1440px) 600px, (min-width: 1280px) 42vw, (min-width: 1024px) 45vw, (min-width: 768px) 67vw, 85vw"
        />
        <div
          v-if="hasMobileLayout"
          class="flex justify-center md:justify-start"
        >
          <ArrowGraphic class="w-10 text-teal-100" direction="down" />
        </div>
      </template>
    </BaseHeader>
    <section class="selection-bg-teal-100">
      <div class="container content-padding">
        <div class="grid-container mb-16">
          <div class="grid-item w-full">
            <SectionHeading>
              <MarkedText
                :text="mission.heading"
                class="text-teal-100"
                stroke-width="9"
                type="circle-stroke"
              />
            </SectionHeading>
          </div>
          <div
            v-for="(statement, index) in mission.statements"
            :key="index"
            class="grid-item w-full md:w-10/12 xl:w-4/12 mb-6 md:mb-10 last:mb-0 xl:mb-0"
          >
            <!-- eslint-disable vue/no-v-html -->
            <p class="whitespace-pre-line" v-html="statement.body" />
            <!--eslint-enable-->
          </div>
        </div>
        <div class="grid-container">
          <div class="grid-item w-full flex justify-between items-center">
            <SectionHeading>
              {{ $t('pages.aboutUs.titles.meetOurTeam') }}
            </SectionHeading>
            <div class="hidden xl:flex items-center mb-8 space-x-8">
              <ArrowButton direction="<" @click="sliderPrev" />
              <ArrowButton direction=">" @click="sliderNext" />
            </div>
          </div>
        </div>
        <div class="md:mx-16 mb-20 xl:mb-32">
          <div class="md:px-6 w-full xl:mt-4">
            <TeamSlider
              ref="teamSlider"
              :team="team"
              :go="teamSliderDirection"
            />
          </div>
        </div>
        <div class="grid-container">
          <div class="grid-item w-full">
            <SectionHeading>
              {{ $t('pages.aboutUs.titles.wereInTheNews') }}
            </SectionHeading>
          </div>
          <div class="grid-item w-full mb-8 xl:mb-12">
            <NewsItem
              v-for="(item, index) in newsItems"
              :key="index"
              class="first:border-t border-b border-black border-opacity-10"
              v-bind="item"
            />
          </div>
          <div class="grid-item w-full text-center lg:text-left">
            <nuxt-link :to="localePath('/blog')" class="link">
              {{ $t('pages.aboutUs.links.readMoreInOurBlog') }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </section>
    <OnboardingSection />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ArrowButton from '~/components/ArrowButton';
import ArrowGraphic from '~/components/ArrowGraphic';
import BaseAsset from '~/components/BaseAsset';
import BaseHeader from '~/components/BaseHeader';
import MarkedText from '~/components/MarkedText';
import NewsItem from '~/components/NewsItem';
import OnboardingSection from '~/components/OnboardingSection';
import SectionHeading from '~/components/SectionHeading';
import TeamSlider from '~/components/TeamSlider';

import GET_ABOUT_US_QUERY from '~/graphql/queries/GetAboutUs';
import { craft, seomatic, transition, partnerships } from '~/mixins';
import { collapseFieldArray } from '~/util';

export default {
  name: 'PageAboutUs',
  components: {
    ArrowButton,
    ArrowGraphic,
    BaseAsset,
    BaseHeader,
    MarkedText,
    NewsItem,
    OnboardingSection,
    SectionHeading,
    TeamSlider,
  },
  mixins: [
    craft({
      query: GET_ABOUT_US_QUERY,
      transform({ entry }) {
        entry.headerContent = collapseFieldArray(entry.headerContent);
        entry.mission = collapseFieldArray(entry.mission);

        return entry;
      },
    }),
    seomatic,
    transition,
    partnerships,
  ],
  data() {
    return {
      headerContent: [{ heading: '', body: '' }],
      headerImage: [],
      mission: [{ heading: '', statements: [] }],
      newsItems: [],
      team: [],
      teamSliderDirection: null,
    };
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
  },
  methods: {
    sliderPrev() {
      this.$refs.teamSlider.keen.prev();
    },
    sliderNext() {
      this.$refs.teamSlider.keen.next();
    },
  },
};
</script>
