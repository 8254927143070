<template>
  <div
    :class="active ? 'bg-navy-100 text-white' : 'text-navy-100'"
    class="inline-flex justify-center items-center border-2 border-navy-100 px-3 rounded-full font-medium text-base leading-none transition-colors duration-100"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlogTopic',
  props: {
    active: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="postcss" scoped>
div {
  height: 34px;
}
</style>
