<template>
  <div>
    <BaseHeader>
      <template #left>
        <h1 class="mb-6 xl:mb-16 heading-3 xl:heading-2">
          <MarkedText
            :text="headerContent.heading"
            class="text-teal-100"
            type="circle-fill"
          />
        </h1>
        <p
          class="mb-6 xl:mb-10 body-5 xl:body-3 whitespace-pre-line"
          v-text="headerContent.body"
        />
        <ReviewSummary stars />
      </template>
      <template #right>
        <div
          class="flex flex-col items-center xl:items-start px-4 pt-8 pb-10 xl:p-20 rounded-xl bg-teal-100"
        >
          <PricingToggle v-model="pricingToggle" class="w-full mb-8 xl:mb-12" />
          <TextMarker
            class="mb-4 sm:mb-6 heading-3 text-white"
            stroke-width="10"
            type="line-c"
          >
            <div class="flex items-baseline">
              <h2>${{ currentPricing }}</h2>
              <div class="flex flex-col-reverse items-start ml-2 body-4">
                <span class="body-7 leading-none text-black text-opacity-50">
                  {{ $t('common.text.inclGst') }}
                </span>
                <span class="leading-none">{{ currentQuantity }}</span>
              </div>
            </div>
          </TextMarker>
          <div
            class="body-6 flex flex-wrap items-center justify-center xl:justify-start w-full mb-10 xl:mb-12"
          >
            <span>{{
              $t('pages.pricing.text.or4PaymentsOfWith', {
                afterpayPrice: afterpayPrice,
              })
            }}</span>
            <img
              src="~/assets/img/afterpay-badge-black-on-white.png"
              class="w-40 mt-2 sm:mt-0 sm:ml-3"
              :alt="$t('pages.pricing.text.afterpay')"
            />
          </div>
          <OnboardingButton class="w-full mb-8 xl:mb-12" arrow size="large">
            {{ $t('common.buttons.getStartedNow') }}
          </OnboardingButton>
          <div class="flex flex-col xl:flex-row items-center xl:body-6">
            <svg
              class="mb-2 xl:mb-0 xl:mr-4"
              width="32"
              height="32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                class="stroke-current"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="square"
              >
                <path
                  class="text-white fill-current stroke-current"
                  d="M17.833 30.667c7.088 0 12.834-5.746 12.834-12.834C30.667 10.746 24.92 5 17.833 5 10.746 5 5 10.746 5 17.833c0 7.088 5.746 12.834 12.833 12.834z"
                  fill="#fff"
                  stroke="#fff"
                />
                <path
                  d="M13.833 26.667c7.088 0 12.834-5.746 12.834-12.834C26.667 6.746 20.92 1 13.833 1 6.746 1 1 6.746 1 13.833c0 7.088 5.746 12.834 12.833 12.834zM13.834 12.667v7"
                  fill="none"
                />
              </g>
              <path
                class="fill-current"
                d="M13.833 9.166a1.167 1.167 0 100-2.333 1.167 1.167 0 000 2.333z"
              />
            </svg>
            <div class="text-center xl:text-left">
              <h3>{{ $t('pages.pricing.titles.liveChatPhoneSupport') }}</h3>
              <p class="text-black text-opacity-50">
                {{ $t('pages.pricing.text.wereOpen7DaysAWeek') }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </BaseHeader>
    <section>
      <div class="container content-padding">
        <div class="grid-container">
          <div class="grid-item w-full selection-bg-teal-100">
            <SectionHeading>
              <MarkedText
                :text="features.heading"
                class="text-teal-100"
                stroke-width="9"
                type="circle-stroke"
              />
            </SectionHeading>
          </div>
          <div
            v-for="(item, index) in features.items"
            :key="index"
            class="grid-item w-full md:w-8/12 xl:w-3/12 mb-8 md:mb-10 xl:mb-12"
          >
            <BaseTextBlock
              :body="item.body"
              :heading="item.heading"
              class="flex xl:flex-col items-baseline xl:items-start"
            >
              <div
                class="flex-none p-px rounded-full mr-3 bg-teal-100 text-black"
              >
                <div
                  class="flex flex-none justify-center items-center w-10 h-10 p-px m-px"
                >
                  <BaseGlyph :type="item.icon" />
                </div>
              </div>
            </BaseTextBlock>
          </div>
          <div v-if="testimonials.length" class="grid-item w-full mb-8">
            <Testimonials :testimonials="testimonials" />
          </div>
          <div class="grid-item w-full">
            <ContactCtaPanel />
          </div>
        </div>
      </div>
    </section>
    <OnboardingSection />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BaseGlyph from '~/components/BaseGlyph';
import BaseHeader from '~/components/BaseHeader';
import BaseTextBlock from '~/components/BaseTextBlock';
import ContactCtaPanel from '~/components/ContactCtaPanel';
import MarkedText from '~/components/MarkedText';
import OnboardingButton from '~/components/OnboardingButton';
import OnboardingSection from '~/components/OnboardingSection';
import PricingToggle from '~/components/PricingToggle';
import ReviewSummary from '~/components/ReviewSummary';
import SectionHeading from '~/components/SectionHeading';
import Testimonials from '~/components/Testimonials';
import TextMarker from '~/components/TextMarker';

import GET_PRICING_QUERY from '~/graphql/queries/GetPricing';
import { craft, seomatic, transition, partnerships } from '~/mixins';
import { collapseFieldArray, queryStringToObject } from '~/util';

export default {
  name: 'PagePricing',
  components: {
    BaseGlyph,
    BaseHeader,
    BaseTextBlock,
    ContactCtaPanel,
    MarkedText,
    OnboardingButton,
    OnboardingSection,
    PricingToggle,
    ReviewSummary,
    SectionHeading,
    Testimonials,
    TextMarker,
  },
  mixins: [
    craft({
      query: GET_PRICING_QUERY,
      transform({ entry }) {
        entry.headerContent = collapseFieldArray(entry.headerContent);
        entry.features = collapseFieldArray(entry.features);

        return entry;
      },
    }),
    seomatic,
    transition,
    partnerships,
  ],
  data() {
    return {
      couplesPricing: 0,
      headerContent: { heading: '', body: '' },
      individualPricing: 0,
      features: { heading: '', items: [] },
      pricingToggle: false,
      testimonials: [],
    };
  },
  computed: {
    currentPricing() {
      return this.pricingToggle ? this.couplesPricing : this.individualPricing;
    },
    currentQuantity() {
      return this.pricingToggle ? 'for two Wills' : 'AUD';
    },
    afterpayPrice() {
      return (this.currentPricing / 4).toFixed(2);
    },
    ...mapGetters(['appQuery']),
  },
  watch: {
    pricingToggle(isCouple) {
      const query = {
        ...(this.appQuery && queryStringToObject(this.appQuery)),
        hasPartner: true,
      };
      if (!isCouple) {
        delete query.hasPartner;
      }
      this.setAppQuery(query);
    },
  },
  methods: {
    ...mapActions(['setAppQuery']),
  },
};
</script>
