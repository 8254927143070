<template>
  <section>
    <div class="container content-padding">
      <PaginatedBlogPosts topic-filters />
    </div>
  </section>
</template>

<script>
import PaginatedBlogPosts from '~/components/PaginatedBlogPosts';

import { transition } from '~/mixins';

export default {
  name: 'PageBlogPosts',
  components: {
    PaginatedBlogPosts,
  },
  mixins: [transition],
};
</script>
