<template>
  <div
    class="flex flex-col lg:flex-row items-center space-y-6 lg:space-y-0 lg:space-x-4 xl:space-x-8 text-center lg:text-left"
  >
    <div
      :class="!photo && 'border border-black border-opacity-10'"
      class="photo flex-none rounded-full overflow-hidden bg-grey-100"
    >
      <BaseAsset
        v-if="photo"
        :asset="photo"
        sizes="64px"
        border
        fit="cover"
        class="rounded-full"
      />
    </div>
    <div class="flex flex-col space-y-2">
      <h5 class="text-2xl leading-none" v-text="fullName" />
      <h6
        v-if="role"
        class="font-medium text-base leading-none text-navy-100 text-opacity-60"
        v-text="role"
      />
      <div v-if="details" class="flex flex-col">
        <!-- eslint-disable vue/no-v-html -->
        <p v-if="byline" class="body-6 author-byline" v-html="byline" />
        <!--eslint-enable-->
        <NuxtLink
          :to="localePath(`/blog/author/${id}`)"
          class="mt-4 font-medium text-base uppercase underline text-navy-100 opacity-50 hover:opacity-100 transition-opacity duration-75"
        >
          {{ $t('components.blogAuthorLarge.buttons.viewArticles') }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script>
import BaseAsset from '~/components/BaseAsset';
import authorProps from '~/modules/author-props';

export default {
  name: 'BlogAuthorLarge',
  components: {
    BaseAsset,
  },
  props: {
    ...authorProps(),
    details: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="postcss" scoped>
.photo {
  width: 150px;
  height: 150px;
}
</style>
