<template>
  <section>
    <div class="container content-padding">
      <div class="grid-container">
        <div v-if="author" class="grid-item w-full">
          <div
            class="flex flex-col items-center pb-16 border-b border-navy-100 border-opacity-10 mb-8 xl:mb-16"
          >
            <div
              :class="!author.photo && 'border border-black border-opacity-10'"
              class="photo flex-none rounded-full mb-6 overflow-hidden bg-grey-100"
            >
              <BaseAsset
                v-if="author.photo"
                :asset="author.photo"
                sizes="150px"
                border
                fit="cover"
                class="rounded-full"
              />
            </div>
            <h2
              class="xl:font-medium text-2xl xl:text-4xl"
              v-text="author.fullName"
            />
            <h3
              v-if="author.role"
              class="font-medium body-7 xl:body-5 text-navy-100 text-opacity-60"
              v-text="author.role"
            />
            <!-- eslint-disable vue/no-v-html -->
            <p
              v-if="author.byline"
              class="mt-3 body-6 md:body-2 author-byline"
              v-html="author.byline"
            />
            <!--eslint-enable-->
          </div>
        </div>
        <div class="grid-item w-full mb-12">
          <h2 v-if="author" class="font-medium body-2">
            {{
              $t('pages.authorId.titles.articlesBy', {
                firstName: author.firstName,
              })
            }}
          </h2>
        </div>
      </div>
      <PaginatedBlogPosts :author-id="$route.params.id" />
    </div>
  </section>
</template>

<script>
import BaseAsset from '~/components/BaseAsset';
import PaginatedBlogPosts from '~/components/PaginatedBlogPosts';

import GET_AUTHOR_QUERY from '~/graphql/queries/GetAuthor';
import { transition } from '~/mixins';

export default {
  name: 'PageBlogPosts',
  components: {
    BaseAsset,
    PaginatedBlogPosts,
  },
  mixins: [transition],
  apollo: {
    author: {
      query: GET_AUTHOR_QUERY,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      update: (data) => data.author,
    },
  },
  computed: {
    filters() {
      return {
        authorId: this.$route.params.id,
      };
    },
  },
};
</script>

<style lang="postcss" scoped>
.photo {
  width: 150px;
  height: 150px;
}
</style>
