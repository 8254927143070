<template>
  <div :class="classes" class="relative flex">
    <div :class="imageClasses" class="flex overflow-hidden rounded-xl">
      <BaseAsset
        :asset="asset"
        fit="cover"
        sizes="(min-width: 1440px) 720px, (min-width: 1280px) 50vw, (min-width: 1024px) 45vw, (min-width: 768px) 75vw, 87vw"
      />
    </div>
  </div>
</template>

<script>
import BaseAsset from '~/components/BaseAsset';

export default {
  name: 'ProductGraphic',
  components: {
    BaseAsset,
  },
  props: {
    asset: {
      required: true,
      type: Object,
    },
    type: {
      required: true,
      type: String,
      validate(type) {
        return ['will', 'poa', 'cremation', 'probate'].includes(type);
      },
    },
  },
  computed: {
    classes() {
      return {
        'lg:pb-8': this.isWill,
        'lg:pl-16 xl:pl-24 2xl:pl-40': this.isPoa,
        'lg:pl-10 xl:pl-18 2xl:pl-32': this.isCremation,
        'lg:pt-12 xl:pt-20': this.isProbate,
      };
    },
    fit() {
      return this.type === 'poa' ? 'height' : 'width';
    },
    imageClasses() {
      return {
        'lg:rounded-t-none': this.isWill,
        'lg:rounded-none': this.isPoa,
        'lg:rounded-t-none lg:rounded-r-none': this.isCremation,
        'lg:rounded-b-none lg:rounded-r-none': this.isProbate,
      };
    },
  },
};
</script>
