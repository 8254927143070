<template>
  <NuxtLink
    :to="localePath(`/${uri}`)"
    class="relative block overflow-hidden text-white"
  >
    <div class="absolute inset-0">
      <BaseAsset
        :asset="headerImage[0]"
        border
        rounded
        fit="cover"
        class="w-full h-full"
      />
      <div class="gradient absolute inset-0 rounded-xl m-px opacity-80" />
    </div>
    <div class="relative z-10 flex flex-col justify-between h-full p-6 xl:p-8">
      <div>
        <BlogItemType class="bg-white bg-opacity-50">
          {{ $t('common.text.guide') }}
        </BlogItemType>
      </div>
      <div>
        <h3 class="heading-6 font-medium mb-3" v-text="title" />
        <p v-text="summary" />
      </div>
    </div>
  </NuxtLink>
</template>

<script>
import BaseAsset from '~/components/BaseAsset';
import BlogItemType from '~/components/BlogItemType';

export default {
  name: 'BlogItemGuide',
  components: {
    BaseAsset,
    BlogItemType,
  },
  props: {
    /* eslint-disable-next-line vue/prop-name-casing */
    __typename: {
      required: true,
      type: String,
    },
    author: {
      required: true,
      type: Object,
    },
    date: {
      required: true,
      type: String,
    },
    headerImage: {
      required: true,
      type: Array,
    },
    id: {
      required: true,
      type: String,
    },
    slug: {
      required: true,
      type: String,
    },
    summary: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    uri: {
      required: true,
      type: String,
    },
  },
};
</script>

<style lang="postcss" scoped>
a {
  height: 469px;
}

p {
  font-size: 22px;
  line-height: 28px;
}

@screen xl {
  a {
    height: 557px;
  }
}

.gradient {
  background-image: linear-gradient(
    180deg,
    rgb(0 0 0 / 0%) 0%,
    rgb(0 0 0 / 85%) 97.4%
  );
}
</style>
