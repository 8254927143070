<template>
  <div
    v-scroll="{ callback: onScroll }"
    class="pt-16 md:pt-0 whitespace-no-wrap"
  >
    <div
      v-for="(card, index) in cards"
      :key="index"
      ref="cards"
      class="relative inline-block"
      :class="[index !== 0 ? 'ml-4' : '', index === 1 ? 'z-10' : '']"
    >
      <AppCardModule
        :number="index + 1"
        :title="card.title"
        :description="card.description"
        :complete="card.complete"
      />
    </div>
  </div>
</template>

<script>
import anime from 'animejs';

import AppCardModule from '~/components/AppCardModule';

export default {
  name: 'AppCardAnimation',
  components: {
    AppCardModule,
  },
  data() {
    return {
      anim: null,
      cards: [
        {
          title: 'Guardians',
          complete: true,
        },
        {
          title: 'Assets',
          description: 'Add your assets and key possessions.',
        },
        {
          title: 'Estate',
          description: 'Decide how your inheritance is split.',
        },
        {
          title: 'Executors',
          description: 'Nominate someone to carry out your Will.',
        },
      ],
      isVisible: false,
      lift: null,
    };
  },
  mounted() {
    this.anim = anime.timeline({
      autoplay: false,
      complete: () => {
        this.$scroll.trigger();
      },
    });

    this.anim.add({
      targets: this.$refs.cards,
      translateX: {
        value: ['80%', 0],
        duration: 1000,
        easing: 'cubicBezier(0.4, 0, 0.2, 1)',
      },
      opacity: {
        value: [0, 1],
        duration: 400,
        easing: 'easeInOutQuad',
      },
      translateZ: 0,
      delay: anime.stagger(70),
    });
  },
  methods: {
    onScroll() {
      const { isVisible, visible } = this.$rect(this.$el, true);
      const liftCard = visible === 1;

      if (!this.isVisible && isVisible) {
        this.anim.play();
        this.isVisible = true;
      }

      if (this.anim.completed && liftCard) {
        anime({
          targets: this.$refs.cards,
          opacity: {
            value(el, i) {
              return i !== 1 ? 0.5 : 1;
            },
            duration: 350,
            easing: 'easeInOutQuad',
          },
          translateX: {
            value(el, i) {
              let res = 0;

              switch (i) {
                case 1:
                  res = '-60%';
                  break;
                case 2:
                case 3:
                  res = '-90%';
              }

              return res;
            },
            duration(el, i) {
              return i === 1 ? 750 : 1000;
            },
            easing: 'cubicBezier(0.4, 0, 0.2, 1)',
          },
          translateY: {
            value(el, i) {
              return i === 1 ? '-30%' : 0;
            },
            duration(el, i) {
              return i === 1 ? 750 : 1000;
            },
            easing: 'cubicBezier(0.4, 0, 0.2, 1)',
          },
          translateZ: 0,
          delay: anime.stagger(70),
        });
      }

      return this.isVisible && this.anim.completed && liftCard;
    },
  },
};
</script>
