<template>
  <BaseParallaxBlock
    :asset="contactCtaPanel.backgroundImage[0]"
    :heading="contactCtaPanel.heading"
  >
    <p class="mb-5 xl:mb-2 whitespace-pre-line" v-text="contactCtaPanel.body" />
    <div class="space-x-6">
      <a
        :href="`tel:${phoneNumber.replace(/\s/g, '')}`"
        class="link text-white"
        v-text="phoneNumber"
      />
      <button
        :aria-label="$t('common.buttons.openLiveChat')"
        class="link text-white"
        type="button"
        @click="$nuxt.$emit('showChat')"
      >
        {{ $t('components.contactCtaPanel.text.liveChat') }}
      </button>
    </div>
  </BaseParallaxBlock>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseParallaxBlock from '~/components/BaseParallaxBlock';

export default {
  name: 'ContactCtaPanel',
  components: {
    BaseParallaxBlock,
  },
  computed: {
    ...mapGetters('globals', ['contactCtaPanel', 'phoneNumber']),
  },
};
</script>
