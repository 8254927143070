<template>
  <div v-if="video && videoThumbnail" class="relative">
    <BaseAsset
      :asset="videoThumbnail"
      rounded
      sizes="(min-width: 1440px) 600px, (min-width: 1280px) 42vw, (min-width: 1024px) 45vw, (min-width: 768px) 67vw, 87vw"
    />
    <div
      class="absolute left-0 right-0 bottom-0 text-center"
      style="padding-bottom: 7%"
    >
      <button
        :aria-label="$t('components.videoCard.buttons.watchVideo')"
        class="button button-sm button-white button-float pl-0"
        @click="toggleVideoModal"
      >
        <div
          class="flex justify-center items-center w-12 h-12 rounded-full overflow-hidden"
        >
          <svg
            class="fill-current"
            width="14"
            height="18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.545 8.319L1.272.137A.819.819 0 000 .817v16.365a.819.819 0 001.272.68L13.545 9.68a.82.82 0 000-1.361z"
            />
          </svg>
        </div>
        <span>{{ btnText }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import BaseAsset from '~/components/BaseAsset';

export default {
  name: 'VideoCard',
  components: {
    BaseAsset,
  },
  props: {
    video: {
      type: Object,
      required: true,
    },
    videoThumbnail: {
      type: Object,
      required: true,
    },
    btnText: {
      type: String,
      default: 'Watch video',
    },
  },
  methods: {
    ...mapActions(['toggleVideoModal']),
  },
};
</script>
