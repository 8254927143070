<template>
  <div v-if="content.body">
    <BaseHeader>
      <template #left>
        <h1 class="mb-6 xl:mb-16 heading-3 xl:heading-2">
          {{ $t('pages.offersOffer.text.writeYourWillOnlineTodayFor') }}
          <Strikethrough :style="strikethroughStyles">$160</Strikethrough> ${{
            price
          }}.
        </h1>
        <p
          class="mb-6 xl:mb-10 body-5 xl:body-3 whitespace-pre-line"
          v-text="headerText"
        />
        <div class="mb-4 lg:mb-8">
          <OnboardingButton arrow size="large">
            {{ $t('common.buttons.getStartedNow') }}
          </OnboardingButton>
        </div>
        <ReviewSummary stars />
      </template>
      <template #right>
        <BaseAsset
          :asset="headerImage"
          :crop="hasMobileLayout"
          rounded
          sizes="(min-width: 1440px) 600px, (min-width: 1280px) 42vw, (min-width: 1024px) 45vw, (min-width: 768px) 67vw, 85vw"
        />
      </template>
      <template #extra>
        <div class="grid-item w-full mt-8 md:mt-16">
          <div
            class="relative pt-8 xl:py-12 border-t border-b border-navy-100 border-opacity-10"
          >
            <div
              class="absolute top-0 left-0 right-0 flex justify-center items-center h-0 text-lg xl:text-xl leading-none text-center"
            >
              <span
                class="inline px-6 xl:px-8 pb-1 bg-water text-navy-100 text-opacity-50"
              >
                {{ $t('common.text.asSeenOn') }}
              </span>
            </div>
            <BrandLogoSwitcher />
          </div>
        </div>
      </template>
    </BaseHeader>
    <section class="selection-bg-teal-100">
      <div class="container content-padding">
        <div class="grid-container">
          <div class="grid-item w-full">
            <div
              :style="themeStyles"
              class="p-6 pt-8 md:p-20 lg:p-16 rounded-xl"
            >
              <div class="h-8 md:h-10 xl:h-12 mb-4 xl:mb-12">
                <BaseAsset :asset="content.logo[0]" fit="height" />
              </div>
              <div class="xl:flex">
                <div class="max-w-sm xl:mr-16 mb-8">
                  <SectionHeading>
                    {{ content.heading }}
                  </SectionHeading>
                  <OnboardingButton v-if="!hasMobileLayout" arrow large>
                    {{ $t('common.buttons.getStartedNow') }}
                  </OnboardingButton>
                </div>
                <div class="flex-1">
                  <p class="mb-4 whitespace-pre-line" v-text="content.body" />
                  <ul class="flex flex-wrap mb-8 md:mb-16 md:body-5">
                    <li
                      v-for="(feature, index) in content.features"
                      :key="index"
                      :style="featureStyles"
                      class="flex items-baseline md:w-2/3 lg:w-1/2 mt-8 lg:odd:pr-6 lg:even:pl-6"
                    >
                      <div class="h-5">
                        <svg
                          width="32"
                          height="32"
                          class="flex-none mr-4 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16 0a16 16 0 1016 16A16.047 16.047 0 0016 0zm-3 22.414L7.586 17 9 15.586l4 4 10-10L24.414 11 13 22.414z"
                          />
                        </svg>
                      </div>
                      <p v-text="feature.body" />
                    </li>
                  </ul>
                  <OnboardingButton v-if="hasMobileLayout" arrow large>
                    {{ $t('common.buttons.getStartedNow') }}
                  </OnboardingButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container content-padding">
        <div class="grid-container">
          <div
            class="grid-item w-full md:w-10/12 lg:w-full mx-auto mb-8 xl:mb-16 text-center"
          >
            <SectionHeading>
              {{ $t('common.text.australias') }}
              <TextMarker class="text-teal-100" stroke-width="9" type="line-a">
                {{ $t('pages.offersOffer.text.mostTrusted') }}
              </TextMarker>
              {{ $t('pages.offersOffer.text.onlineWillWritingService') }}
            </SectionHeading>
          </div>
          <div class="grid-item w-full">
            <ReviewSlider />
          </div>
        </div>
      </div>
    </section>
    <OnboardingSection />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BaseAsset from '~/components/BaseAsset';
import BaseHeader from '~/components/BaseHeader';
import BrandLogoSwitcher from '~/components/BrandLogoSwitcher';
import OnboardingButton from '~/components/OnboardingButton';
import OnboardingSection from '~/components/OnboardingSection';
import ReviewSlider from '~/components/ReviewSlider';
import ReviewSummary from '~/components/ReviewSummary';
import SectionHeading from '~/components/SectionHeading';
import Strikethrough from '~/components/Strikethrough';
import TextMarker from '~/components/TextMarker';

import GET_OFFER_QUERY from '~/graphql/queries/GetOffer';
import { craft, seomatic, transition } from '~/mixins';
import { collapseFieldArray } from '~/util';

export default {
  name: 'PageOffer',
  components: {
    BaseAsset,
    BaseHeader,
    BrandLogoSwitcher,
    OnboardingButton,
    OnboardingSection,
    ReviewSlider,
    ReviewSummary,
    SectionHeading,
    Strikethrough,
    TextMarker,
  },
  mixins: [
    craft({
      query: GET_OFFER_QUERY,
      transform({ entry }) {
        if (entry) {
          entry.content = collapseFieldArray(entry.content);
          entry.headerImage = collapseFieldArray(entry.headerImage);
        }

        return entry;
      },
      variables({ route }) {
        return {
          slug: route.name.split('___')[0],
        };
      },
    }),
    seomatic,
    transition,
  ],
  data() {
    return {
      appQueryParams: {},
      bannerLogo: [],
      bannerText: '',
      content: {
        body: '',
        features: [],
        heading: '',
      },
      price: 0,
      strikethroughColour: '',
      themeColours: {},
    };
  },
  computed: {
    ...mapGetters(['hasMobileLayout', 'theme']),
    featureStyles() {
      return this.theme
        ? {
            color: this.theme.textAlternate,
          }
        : {};
    },
    strikethroughStyles() {
      return {
        color: this.strikethroughColour,
      };
    },
    themeStyles() {
      return this.theme
        ? {
            backgroundColor: this.theme.background,
            color: this.theme.text,
          }
        : {};
    },
  },
  mounted() {
    if (this.content.body) {
      this.setAppQuery(this.appQueryParams);
      this.setBannerLogo(this.bannerLogo);
      this.setBannerText(this.bannerText);
      this.setHasOffer(true);
      this.setTheme(this.themeColours);
    } else if (!this.loadingPartners) {
      this.$router.push({ path: this.localePath('/') });
    }
  },
  beforeDestroy() {
    this.setAppQuery([]);
    this.setBannerLogo('');
    this.setBannerText('');
    this.setHasOffer(false);
    this.setTheme({});
  },
  methods: {
    ...mapActions(['setAppQuery', 'setHasOffer', 'setTheme']),
    ...mapActions('banner', ['setBannerLogo', 'setBannerText']),
  },
};
</script>
