<template>
  <div
    class="card flex flex-col items-start p-5 md:p-8 rounded md:rounded-xl bg-white shadow-graphic whitespace-normal select-none cursor-default"
  >
    <div class="relative w-8 md:w-12 h-8 md:h-12 mb-2">
      <span
        class="absolute flex justify-center items-center w-full h-full title font-medium text-teal-200"
        v-text="number"
      />
      <div class="absolute inset-0">
        <svg
          class="absolute inset-0"
          viewBox="0 0 48 48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" stroke-width="2" stroke-linecap="round">
            <circle
              class="text-black text-opacity-10 stroke-current"
              stroke-dasharray="2,4"
              vector-effect="non-scaling-stroke"
              cx="24"
              cy="24"
              r="23"
            />
          </g>
        </svg>
        <div
          v-if="complete"
          class="absolute inset-0 flex justify-center items-center rounded-full bg-teal-200 text-white"
        >
          <svg class="w-4 md:w-6 h-4 md:h-6" viewBox="0 0 24 24" fill="none">
            <path
              class="stroke-current"
              d="M3 12.357l5.696 5.697L20.75 6"
              stroke-width="3"
            />
          </svg>
        </div>
      </div>
    </div>
    <h5 class="mb-2 title" v-text="title" />
    <p
      :class="complete ? 'text-teal-200' : 'text-black text-opacity-40'"
      class="w-full"
      v-text="complete ? 'Edit' : description"
    />
    <div
      :class="complete ? '' : 'text-right'"
      class="w-full mt-auto text-black text-opacity-20"
    >
      <div v-if="complete">
        <h6>
          <span
            class="inline-block align-baseline h-3 md:h-4 pt-1 md:pt-1/2 md:mr-1/2"
          >
            <svg
              class="h-full"
              viewBox="0 0 13 15"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g class="fill-current">
                <circle cx="6.3" cy="1.8" r="1.8" />
                <path
                  d="M11.7 4.5H.9a.9.9 0 0 0 0 1.8h2.7v7.2a.9.9 0 1 0 1.8 0V9.9h1.8v3.6a.9.9 0 1 0 1.8 0V6.3h2.7a.9.9 0 1 0 0-1.8z"
                />
              </g>
            </svg>
          </span>
          {{ $t('components.appCardModule.text.wilson') }}
        </h6>
        <p class="text-black text-opacity-40">
          {{ $t('components.appCardModule.text.kimmyBrown') }}
        </p>
      </div>
      <p v-else class="text-4xs md:text-2xs">
        {{ $t('components.appCardModule.text.twoToThreeMins') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppCardModule',
  props: {
    number: {
      required: true,
      type: Number,
    },
    title: {
      required: true,
      type: String,
    },
    description: {
      default: null,
      type: String,
    },
    complete: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="postcss" scoped>
.card {
  @apply leading-normal;
  width: 144px;
  height: 200px;
  font-size: 9px;
}

.title {
  font-size: 13px;
}

@screen md {
  .card {
    @apply text-base;
    width: 256px;
    height: 360px;
  }

  .title {
    font-size: 22px;
  }
}
</style>
