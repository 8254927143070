<template>
  <div class="aspect-standard rounded-xl bg-babyblue-100">
    <div class="absolute inset-0 -my-10">
      <div class="w-4/5 mx-auto">
        <div v-if="index === 0" class="relative">
          <div
            class="absolute left-0 bottom-0 w-full pt-24 md:pt-32 lg:pt-24 pr-24 md:pr-32 lg:pr-24"
          >
            <AppCardCharityInvite type="a" />
          </div>
          <div class="pl-24 md:pl-32 lg:pl-24 pb-24 md:pb-32 lg:pb-24">
            <AppCardCharityInvite type="b" />
          </div>
        </div>
        <div v-if="index === 1">
          <AppCardCharityGift />
        </div>
        <div v-if="index === 2" class="w-4/5 mx-auto lg:pt-20">
          <AppCardCharityEstate />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppCardCharityEstate from '~/components/AppCardCharityEstate';
import AppCardCharityGift from '~/components/AppCardCharityGift';
import AppCardCharityInvite from '~/components/AppCardCharityInvite';

export default {
  name: 'CharitiesGraphic',
  components: {
    AppCardCharityEstate,
    AppCardCharityGift,
    AppCardCharityInvite,
  },
  props: {
    index: {
      required: true,
      type: Number,
    },
  },
};
</script>
