<template>
  <div>
    <BaseAsset
      v-if="assets.length === 1"
      :asset="assets[0]"
      :crop="hasMobileLayout"
      sizes="(min-width: 1440px) 600px, (min-width: 1280px) 42vw, (min-width: 1024px) 45vw, (min-width: 768px) 67vw, 85vw"
      rounded
    />
    <div
      v-else
      ref="keen"
      class="keen-slider w-full mb-6 mr-32 md:mr-16 2xl:mr-20 overflow-visible rounded-xl"
      @mouseover="setPause(true)"
      @mouseout="setPause(false)"
    >
      <div
        v-for="(asset, i) in assets"
        :key="i"
        class="keen-slider__slide flex"
      >
        <BaseAsset
          :asset="asset"
          :crop="hasMobileLayout"
          sizes="(min-width: 1440px) 600px, (min-width: 1280px) 42vw, (min-width: 1024px) 45vw, (min-width: 768px) 67vw, 85vw"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import KeenSlider from 'keen-slider';

import BaseAsset from '~/components/BaseAsset';

export default {
  name: 'ImageSlider',
  components: {
    BaseAsset,
  },
  props: {
    go: {
      default: null,
      type: String,
    },
    assets: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      keen: null,
      pause: false,
      interval: null,
    };
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
  },
  watch: {
    go(go) {
      if (this.keen && ['<', '>'].includes(go)) {
        this.keen[go === '<' ? 'prev' : 'next']();
      }
    },
  },
  mounted() {
    this.keen = new KeenSlider(this.$refs.keen, {
      slidesPerView: 1,
      centered: true,
      duration: 750,
      loop: true,
      mode: 'snap',
      dragStart: () => {
        this.setPause(true);
      },
      dragEnd: () => {
        this.setPause(false);
      },
    });
    this.setInterval();
  },
  beforeDestroy() {
    if (this.keen) {
      this.keen.destroy();
    }
  },
  methods: {
    setPause(active) {
      this.pause = active;
      this.setInterval();
    },
    resetInterval() {
      clearInterval(this.interval);
    },
    setInterval() {
      this.resetInterval();
      this.interval = setInterval(() => {
        if (!this.pause && this.assets.length > 1) {
          this.keen.next();
        }
      }, 3000);
    },
  },
};
</script>
