<template>
  <HomeContent :entry="entry" />
</template>

<script>
import { mapActions } from 'vuex';

import HomeContent from '~/components/HomeContent';

import GET_HOME_QUERY from '~/graphql/queries/GetHome';
import { craft, home, seomatic, transition, partnerships } from '~/mixins';
import { transformHomeData } from '~/util';

export default {
  name: 'PageHome',
  components: {
    HomeContent,
  },
  mixins: [
    craft({
      query: GET_HOME_QUERY,
      transform: transformHomeData,
    }),
    home,
    seomatic,
    transition,
    partnerships,
  ],
  data() {
    return {
      entry: null,
    };
  },
  mounted() {
    this.setIsReturning(true);
  },
  methods: {
    ...mapActions(['setIsReturning']),
  },
};
</script>
