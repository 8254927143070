<template>
  <div class="pt-10">
    <BlogTemplate v-bind="entry">
      <template #header>
        <div class="hidden md:block pl-6 border-l-3 border-teal-100 mt-12">
          <h4 class="mb-6 font-medium text-green-300">
            {{ $t('pages.guidesSlug.titles.inThisGuide') }}
          </h4>
          <ul>
            <li v-for="(section, index) in entry.sections" :key="index">
              <button
                type="button"
                @click="scrollToSection(index)"
                v-text="section.heading"
              />
            </li>
          </ul>
        </div>
      </template>
      <div class="space-y-8 xl:space-y-12">
        <div
          v-for="(section, index) in entry.sections"
          ref="sections"
          :key="index"
        >
          <h2 class="mb-5 font-medium" v-text="section.heading" />
          <!-- eslint-disable vue/no-v-html -->
          <div class="rich-text" v-html="section.textContent" />
          <!--eslint-enable-->
        </div>
      </div>
      <template #sidebar>
        <h4 class="mb-6 font-medium">
          {{ $t('pages.guidesSlug.titles.inThisGuide') }}
        </h4>
        <div
          v-scroll="{ callback: onScroll }"
          class="relative text-xl leading-tight"
        >
          <ul class="pl-4 border-l-3 border-teal-100 space-y-3">
            <li
              v-for="(section, index) in entry.sections"
              ref="listItems"
              :key="index"
            >
              <button
                type="button"
                @click="scrollToSection(index)"
                v-text="section.heading"
              />
            </li>
          </ul>
          <div
            ref="marker"
            class="section-marker absolute top-0 left-0 bg-green-500"
          >
            &nbsp;
          </div>
        </div>
      </template>
    </BlogTemplate>
  </div>
</template>

<script>
import anime from 'animejs';
import { mapGetters } from 'vuex';

import BlogTemplate from '~/components/BlogTemplate';

import GET_GUIDE_QUERY from '~/graphql/queries/GetGuide';
import { craft, seomatic, transition } from '~/mixins';

export default {
  name: 'PageGuide',
  components: {
    BlogTemplate,
  },
  mixins: [
    craft({
      query: GET_GUIDE_QUERY,
      variables({ params }) {
        return {
          slug: params.slug,
        };
      },
    }),
    seomatic,
    transition,
  ],
  data() {
    return {
      currentSectionIndex: 0,
      entry: null,
    };
  },
  computed: {
    ...mapGetters(['navBarHeight']),
  },
  watch: {
    currentSectionIndex(currentSectionIndex) {
      anime.remove(this.$refs.marker);

      anime({
        targets: this.$refs.marker,
        translateY: this.$refs.listItems[currentSectionIndex].offsetTop,
        translateZ: 0,
        duration: 200,
        easing: 'easeInOutQuad',
      });
    },
  },
  methods: {
    onScroll() {
      if (!this.$store.state.hasMobileLayout) {
        for (let i = 0; i < this.$refs.sections.length; i++) {
          if (this.$rect(this.$refs.sections[i]).top < this.navBarHeight * 2) {
            this.currentSectionIndex = i;
          } else {
            i = this.$refs.sections.length;
          }
        }
      }
    },
    scrollToSection(index) {
      const rect = this.$rect(this.$refs.sections[index]);

      anime({
        targets: this.$scroll.element,
        scrollTop: rect.top + this.$scroll.top() - this.navBarHeight,
        duration: 1500,
        easing: 'easeInOutQuad',
      });
    },
  },
};
</script>

<style lang="postcss" scoped>
h2 {
  font-size: 34px;
  line-height: 40px;
}

h4 {
  font-size: 22px;
}

.section-marker {
  width: 3px;
}
</style>
