<template>
  <component
    :is="element"
    :to="to"
    :href="to"
    v-bind="newTabAttributes"
    class="ru:navigation__item ru:navigation__child"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'NavigationItemChild',
  props: {
    to: {
      required: false,
      type: String,
      default: '',
    },
  },
  computed: {
    element() {
      if (this.isExternalLink) {
        return 'a';
      }
      return 'nuxt-link';
    },
    isExternalLink() {
      return this.to.substr(0, 1) !== '/';
    },
    newTabAttributes() {
      if (this.isExternalLink) {
        return {
          target: '_blank',
          rel: 'noreferrer',
        };
      }
      return null;
    },
  },
};
</script>
