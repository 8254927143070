<template>
  <div class="relative overflow-hidden bg-water">
    <Header />
    <Viewport>
      <div class="container">
        <div class="grid-container">
          <div class="grid-item w-full">
            <Banner class="mb-8 xl:mb-16" />
          </div>
        </div>
      </div>
      <nuxt />
      <Footer />
    </Viewport>
    <GridOverlay />
    <PortalTarget name="modal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PortalTarget } from 'portal-vue';

import Banner from '~/components/Banner';
import Footer from '~/components/Footer';
import GridOverlay from '~/components/GridOverlay';
import Header from '~/components/organisms/Header';
import Viewport from '~/components/Viewport';

import layout from '~/mixins/layout';
import utm from '~/mixins/utm';

export default {
  name: 'LayoutDefault',
  components: {
    Banner,
    Footer,
    GridOverlay,
    Header,
    Viewport,
    PortalTarget,
  },
  mixins: [layout, utm],
  data() {
    return {
      fontsLoaded: false,
    };
  },
  computed: {
    ...mapGetters(['theme']),
  },
  watch: {
    theme(theme) {
      if (theme) {
        this.buildSelectStyles();
      }
    },
  },
  mounted() {
    if (this.theme) {
      this.buildSelectStyles();
    }
  },
  methods: {
    buildSelectStyles() {
      const stylesheet = document.createElement('style');
      stylesheet.type = 'text/css';

      stylesheet.innerHTML = `.selection-partner ::selection { background-color: ${this.theme.background}; }`;
      document.getElementsByTagName('head')[0].appendChild(stylesheet);
    },
  },
};
</script>
