<template>
  <HomeContent :entry="entry" />
</template>

<script>
import HomeContent from '~/components/HomeContent';

import GET_HOME_QUERY from '~/graphql/queries/GetHome';
import { craft, home, seomatic, transition } from '~/mixins';
import { transformHomeData } from '~/util';

export default {
  name: 'PageHome',
  components: {
    HomeContent,
  },
  mixins: [
    craft({
      query: GET_HOME_QUERY,
      transform: transformHomeData,
    }),
    home,
    seomatic,
    transition,
  ],
  data() {
    return {
      entry: null,
    };
  },
  head() {
    const partnerName = this.$data?.partner?.name;
    const seomaticTitle = this.seomatic?.title;
    return {
      title:
        (partnerName || '') +
          (partnerName && seomaticTitle ? ' | ' : '') +
          (seomaticTitle || '') || 'Safewill',
    };
  },
};
</script>
