<template>
  <component
    :is="element"
    :type="type"
    :to="to"
    class="ru:navigation__item ru:navigation__parent"
    :class="`${isChildlessClass} ${isActiveClass}`"
  >
    <span class="ru:navigation__parent-content" @click="$emit('click')">
      <slot />
    </span>
  </component>
</template>

<script>
export default {
  name: 'NavigationItemParent',
  props: {
    hasChildren: {
      default: false,
      type: Boolean,
    },
    isActive: {
      default: false,
      type: Boolean,
    },
    to: {
      type: String,
      default: null,
    },
  },
  computed: {
    isChildlessClass() {
      if (this.hasChildren) {
        return '';
      }
      return 'ru:navigation__parent--childless';
    },
    isActiveClass() {
      if (this.isActive) {
        return 'ru:navigation__parent--active';
      }
      return '';
    },
    element() {
      if (this.hasChildren) {
        return 'button';
      }
      return 'nuxt-link';
    },
    type() {
      if (this.hasChildren) {
        return 'button';
      }
      return null;
    },
  },
};
</script>
