<template>
  <div>
    <slot name="promoBanner"></slot>
    <BaseHeader>
      <template #left>
        <h1
          :class="partnership ? 'xl:heading-2' : 'xl:heading-1'"
          class="mb-6 xl:mb-16 heading-3"
        >
          <MarkedText
            class="text-teal-100"
            :style="markerStyles"
            :text="mergedHeaderContent.heading"
            type="circle-fill"
          />
        </h1>
        <p
          class="mb-6 xl:mb-10 body-5 xl:body-3 whitespace-pre-line"
          v-text="mergedHeaderContent.body"
        />
        <div class="mb-4 lg:mb-8">
          <OnboardingButton :to="onboardingTo" arrow size="large">
            {{ $t('common.buttons.getStartedNow') }}
          </OnboardingButton>
        </div>
        <ReviewSummary stars />
      </template>
      <template #right>
        <ImageSlider :assets="[mergedHeaderImage]" />
      </template>
      <template #extra>
        <div class="grid-item w-full mt-8 md:mt-16">
          <div
            class="relative pt-8 xl:py-12 border-t border-b border-navy-100 border-opacity-10"
          >
            <div
              class="absolute top-0 left-0 right-0 flex justify-center items-center h-0 text-lg xl:text-xl leading-none text-center"
            >
              <span
                class="inline px-6 xl:px-8 pb-1 bg-water text-navy-100 text-opacity-50"
              >
                {{ $t('common.text.asSeenOn') }}
              </span>
            </div>
            <BrandLogoSwitcher />
          </div>
        </div>
      </template>
    </BaseHeader>
    <section class="selection-bg-teal-100">
      <div class="container content-padding">
        <div class="grid-container">
          <div
            class="grid-item w-full md:w-10/12 lg:w-full mx-auto mb-8 xl:mb-16 text-center"
          >
            <SectionHeading>
              <TextMarker class="text-teal-100" stroke-width="9" type="line-a">
                {{ $t('common.text.trustedByThousands') }}
              </TextMarker>
              {{ $t('common.text.ofAustraliansEachMonth') }}
            </SectionHeading>
          </div>
          <div class="grid-item w-full">
            <ReviewSlider />
          </div>
        </div>
      </div>
    </section>
    <template v-if="!promoPage">
      <section class="selection-bg-teal-100">
        <div class="container content-padding">
          <div class="grid-container">
            <div class="grid-item w-full">
              <div
                v-if="hasPartnerVideo"
                class="flex flex-col lg:flex-row lg:justify-between lg:items-center p-6 pt-8 md:p-20 lg:p-16 rounded-xl bg-teal-100"
                :style="themeStyles"
              >
                <div class="w-full lg:w-7/12 mr-8 mb-8 xl:mb-0">
                  <SectionHeading>
                    {{ partnership.intro[0].heading }}
                  </SectionHeading>
                  <p
                    class="whitespace-pre-line"
                    v-text="partnership.intro[0].body"
                  />
                </div>
                <div class="w-full lg:w-5/12">
                  <VideoCard
                    :video="partnerVideo"
                    :video-thumbnail="partnerVideoThumbnail"
                  />
                </div>
              </div>
              <div
                v-else-if="partnership"
                :style="themeStyles"
                class="flex flex-col lg:flex-row items-center md:items-start lg:items-center p-6 pt-8 md:p-20 lg:p-16 rounded-xl selection-partner"
              >
                <div
                  :style="logoStyles"
                  class="flex-none w-32 md:w-40 h-32 md:h-40 p-3 md:p-4 rounded-full mb-8 lg:mr-16 overflow-hidden"
                >
                  <BaseAsset
                    :asset="partnership.intro[0].logo[0]"
                    fit="contain"
                  />
                </div>
                <div class="max-w-3xl">
                  <SectionHeading class="w-4/5 md:w-auto">
                    {{ partnership.intro[0].heading }}
                  </SectionHeading>
                  <p class="whitespace-pre-line">
                    {{ partnership.intro[0].body }}
                  </p>
                </div>
              </div>
              <div
                v-else
                class="flex flex-col lg:flex-row lg:justify-between lg:items-center p-6 pt-8 md:p-20 lg:p-16 rounded-xl bg-teal-100"
              >
                <div
                  class="lg:flex flex-col max-w-sm md:max-w-none xl:max-w-lg mr-8 mb-8 xl:mb-0"
                >
                  <SectionHeading>
                    <MarkedText
                      :text="entry.intro.heading"
                      class="text-white"
                      stroke-width="20"
                      type="line-b"
                    />
                  </SectionHeading>
                  <p
                    class="text-black text-opacity-70 whitespace-pre-line"
                    v-text="entry.intro.body"
                  />
                </div>
                <div class="max-w-sm md:max-w-none xl:max-w-lg">
                  <VideoCard :video="video" :video-thumbnail="videoThumbnail" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="selection-bg-teal-100">
        <div class="container content-padding">
          <div class="grid-container">
            <div class="grid-item w-full md:w-8/12 lg:w-full">
              <SectionHeading>
                <MarkedText
                  :text="entry.steps.heading"
                  class="text-teal-100"
                  stroke-width="9"
                  type="circle-stroke"
                />
              </SectionHeading>
            </div>
            <div
              v-for="(item, index) in entry.steps.items"
              :key="index"
              class="grid-item w-full md:w-8/12 lg:w-4/12 mb-12 xl:mb-0"
            >
              <BaseTextBlock :heading="item.heading" :body="item.body">
                <div
                  :class="
                    index === entry.steps.items.length - 1
                      ? null
                      : 'justify-between'
                  "
                  class="flex items-baseline selection-text-white"
                >
                  <span
                    class="inline-block counter text-teal-100"
                    v-text="`0${index + 1}`"
                  />
                  <ArrowGraphic
                    v-if="!hasMobileLayout"
                    :class="
                      index === entry.steps.items.length - 1 ? 'ml-4' : null
                    "
                    :direction="
                      index === entry.steps.items.length - 1 ? null : 'right'
                    "
                    class="w-32 xl:w-40 mr-4 text-teal-100"
                  />
                </div>
              </BaseTextBlock>
            </div>
            <div class="grid-item w-full xl:mt-6 text-center md:text-left">
              <nuxt-link
                class="link"
                :to="localePath('/how-it-works')"
                :aria-label="$t('common.links.howItWorks')"
              >
                {{ $t('components.homeContent.links.seeHowItWorks') }}
              </nuxt-link>
            </div>
          </div>
        </div>
      </section>
      <section v-if="hasMobileLayout" class="flex flex-col">
        <div
          v-for="(feature, index) in orderedWillFeatures"
          :key="index"
          :class="feature.classes"
          class="container py-12"
        >
          <div class="grid-container">
            <div class="grid-item w-full md:w-10/12">
              <WillFeatureText
                :title="feature.title"
                :heading="getMergedHeading(feature)"
                :body="getMergedBody(feature)"
                class="mb-16 md:mb-20"
              />
              <WillFeatureGraphic
                :asset="getMergedImage(feature)"
                :type="feature.imageType"
              />
            </div>
          </div>
        </div>
      </section>
      <section v-else>
        <div class="container">
          <div class="grid-container">
            <WillFeatureSwitcher :features="entry.willFeatures" />
          </div>
        </div>
      </section>
      <section class="selection-bg-teal-100">
        <div class="container lg:my-20">
          <div class="grid-container lg:flex-col xl:flex-row">
            <div
              v-for="(feature, index) in entry.appFeatures"
              :key="index"
              class="grid-item flex flex-col w-full md:w-8/12 lg:w-6/12 xl:w-4/12 mb-16 md:mb-20 md:last:mb-0 xl:mb-0"
            >
              <BaseTextBlock
                :heading="feature.heading"
                :body="feature.body"
                class="mb-5 xl:mb-6"
              >
                <BaseIcon :type="feature.icon" class="text-emerald-100" />
              </BaseTextBlock>
              <div class="mt-auto">
                <template v-if="feature.icon === 'chat'">
                  <button
                    :aria-label="$t('common.buttons.openLiveChat')"
                    class="link"
                    type="button"
                    @click="$nuxt.$emit('showChat')"
                  >
                    {{ $t('common.buttons.startLiveChat') }}
                  </button>
                  <span
                    class="inline-block px-px mx-1 text-navy-100 text-opacity-30"
                  >
                    {{ $t('common.text.or') }}
                  </span>
                  <a
                    :href="`tel:${phoneNumber.replace(/\s/g, '')}`"
                    class="link"
                    v-text="`${$t('common.text.call')} ${phoneNumber}`"
                  />
                </template>
                <template v-else-if="feature.icon === 'click'">
                  <nuxt-link
                    class="link"
                    :to="localePath('/how-it-works')"
                    :aria-label="$t('common.links.howItWorks')"
                  >
                    {{ $t('common.links.learnMore') }}
                  </nuxt-link>
                </template>
                <template v-else-if="feature.icon === 'money'">
                  <nuxt-link class="link" :to="localePath('/pricing')">
                    {{ $t('common.links.seeOurPricing') }}
                  </nuxt-link>
                </template>
              </div>
            </div>
          </div>
        </div>
      </section>
      <OnboardingSection />
      <VideoModal v-if="hasPartnerVideo" :video="partnerVideo" />
      <VideoModal v-else :video="video" />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ArrowGraphic from '~/components/ArrowGraphic';
import BaseAsset from '~/components/BaseAsset';
import BaseHeader from '~/components/BaseHeader';
import BaseIcon from '~/components/BaseIcon';
import BaseTextBlock from '~/components/BaseTextBlock';
import BrandLogoSwitcher from '~/components/BrandLogoSwitcher';
import MarkedText from '~/components/MarkedText';
import OnboardingButton from '~/components/OnboardingButton';
import OnboardingSection from '~/components/OnboardingSection';
import ReviewSlider from '~/components/ReviewSlider';
import ReviewSummary from '~/components/ReviewSummary';
import SectionHeading from '~/components/SectionHeading';
import TextMarker from '~/components/TextMarker';
import VideoCard from '~/components/VideoCard';
import WillFeatureGraphic from '~/components/WillFeatureGraphic';
import WillFeatureSwitcher from '~/components/WillFeatureSwitcher';
import WillFeatureText from '~/components/WillFeatureText';
import ImageSlider from '~/components/ImageSlider';
import VideoModal from '~/components/VideoModal';

import { collapseFieldArray } from '~/util';

export default {
  name: 'HomeContent',
  components: {
    ArrowGraphic,
    BaseAsset,
    BaseHeader,
    BaseIcon,
    BaseTextBlock,
    BrandLogoSwitcher,
    MarkedText,
    OnboardingButton,
    OnboardingSection,
    ReviewSlider,
    ReviewSummary,
    SectionHeading,
    TextMarker,
    VideoCard,
    WillFeatureGraphic,
    WillFeatureSwitcher,
    WillFeatureText,
    ImageSlider,
    VideoModal,
  },
  props: {
    entry: {
      default: null,
      type: Object,
    },
    promoPage: {
      default: false,
      type: Boolean,
    },
    promoCode: {
      default: null,
      type: String,
    },
  },
  computed: {
    ...mapGetters(['hasMobileLayout', 'theme', 'partnership']),
    ...mapGetters('globals', ['phoneNumber', 'video', 'videoThumbnail']),
    onboardingTo() {
      return this.promoCode
        ? `${this.$config.safewillAppUrl}${this.localePath(
            `/get-started?coupon=${this.promoCode}`
          )}`
        : null;
    },
    hasPartnerVideo() {
      return this.partnership && this.partnership.partnerVideo.length;
    },
    partnerVideo() {
      if (!this.hasPartnerVideo) {
        return null;
      }

      return this.partnership.partnerVideo[0];
    },
    partnerVideoThumbnail() {
      if (!this.hasPartnerVideo) {
        return null;
      }

      return this.partnership.partnerVideoThumbnail[0];
    },
    willFeatureOverrideContent() {
      return this.partnership
        ? collapseFieldArray(this.partnership.willFeatureContent)
        : null;
    },
    mergedHeaderContent() {
      return this.partnership
        ? this.partnership.headerContent[0]
        : this.entry.headerContent;
    },
    mergedHeaderImage() {
      return this.partnership
        ? this.partnership.headerImage[0]
        : this.entry.headerImage[0];
    },
    orderedWillFeatures() {
      const willFeatures = this.entry.willFeatures;
      const order = ['charity', 'guardians', 'gift', 'pets'];
      return this.partnership
        ? willFeatures.sort(
            (a, b) => order.indexOf(a.imageType) - order.indexOf(b.imageType)
          )
        : willFeatures;
    },
    logoStyles() {
      return this.theme
        ? {
            backgroundColor: this.theme.logoBackground,
          }
        : {};
    },
    markerStyles() {
      return this.theme &&
        this.partnership &&
        this.partnership.themedMarkerColour
        ? {
            color: this.theme.background,
          }
        : {};
    },
    themeStyles() {
      return this.theme
        ? {
            backgroundColor: this.theme.background,
            color: this.theme.text,
          }
        : {};
    },
  },
  methods: {
    ...mapActions(['setTheme', 'setAppQuery']),
    hasOverride(feature) {
      return (
        this.partnership &&
        feature.slug === this.partnership.willFeatureOverride
      );
    },
    getMergedBody(feature) {
      let body = feature.body;

      if (this.hasOverride(feature)) {
        body = this.willFeatureOverrideContent.body || body;
      }

      return body;
    },
    getMergedHeading(feature) {
      let heading = feature.heading;

      if (this.hasOverride(feature)) {
        heading = this.willFeatureOverrideContent.heading || heading;
      }

      return heading;
    },
    getMergedImage(feature) {
      let image = feature.image;

      if (this.hasOverride(feature)) {
        image = this.willFeatureOverrideContent.image[0] || image;
      }

      return image;
    },
  },
};
</script>
