<template>
  <div :class="classes" class="relative flex">
    <div :class="imageClasses" class="flex overflow-hidden rounded-xl">
      <BaseAsset
        :asset="asset"
        fit="cover"
        sizes="(min-width: 1440px) 720px, (min-width: 1280px) 50vw, (min-width: 1024px) 45vw, (min-width: 768px) 75vw, 87vw"
      />
    </div>
    <div
      class="absolute -ml-16 w-11/12 lg:ml-0 left-0 lg:inset-0 flex justify-center items-center"
    >
      <div :class="cardClasses" class="flex justify-center">
        <AppCardGuardians v-if="type === 'guardians'" class="w-full" />
      </div>
    </div>
    <div
      class="absolute -mr-16 w-11/12 lg:mr-0 right-0 lg:inset-0 flex justify-center items-center"
    >
      <div :class="cardClasses" class="flex justify-center">
        <AppCardCharityPartner
          v-if="type === 'charity' && partnership"
          class="w-full"
        />
      </div>
    </div>
    <div class="absolute inset-0 flex justify-center items-center">
      <div :class="cardClasses" class="flex justify-center">
        <AppCardCharity
          v-if="type === 'charity' && !partnership"
          class="w-full"
        />
        <AppCardGift v-else-if="type === 'gift'" class="w-full" />
        <AppCardPets v-else-if="type === 'pets'" class="w-full" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AppCardCharity from '~/components/AppCardCharity';
import AppCardCharityPartner from '~/components/AppCardCharityPartner';
import AppCardGift from '~/components/AppCardGift';
import AppCardGuardians from '~/components/AppCardGuardians';
import AppCardPets from '~/components/AppCardPets';
import BaseAsset from '~/components/BaseAsset';

export default {
  name: 'WillFeatureGraphic',
  components: {
    AppCardCharity,
    AppCardCharityPartner,
    AppCardGift,
    AppCardGuardians,
    AppCardPets,
    BaseAsset,
  },
  props: {
    asset: {
      required: true,
      type: Object,
    },
    type: {
      required: true,
      type: String,
      validate(type) {
        return ['charity', 'gift', 'guardians', 'pets'].includes(type);
      },
    },
  },
  computed: {
    ...mapGetters(['partnership']),
    cardClasses() {
      return {
        'w-4/5 lg:w-2/3 -mt-8 md:-mt-12 lg:mt-auto lg:ml-auto lg:mr-4 xl:mr-1 mb-auto lg:mb-10 xl:mb-32':
          this.isCharity && !this.hasOverride('charities'),
        'w-1/2 lg:w-2/5 xl:w-1/2 -mt-8 md:-mt-12 lg:mt-0 lg:ml-40 xl:ml-64 mb-auto lg:mb-0 lg:mt-16 xl:mt-24':
          this.isCharity && this.hasOverride('charities'),
        'w-4/5 lg:w-2/3 -mt-8 md:-mt-12 lg:mt-0 mb-auto lg:mb-0 lg:mr-auto':
          this.isGift,
        'w-1/2 lg:w-2/5 xl:w-1/2 -mt-8 md:-mt-12 lg:mt-0 mb-auto lg:mb-0 lg:mt-16 xl:mt-24':
          this.isGuardians,
        'md:w-11/12 -mx-2 md:mx-0 -mt-12 md:-mt-16 lg:mt-4 xl:mt-8 mb-auto':
          this.isPets,
      };
    },
    classes() {
      return {
        'lg:pb-20': this.isCharity,
        'xl:pr-20': this.isCharity && !this.partnership,
        'lg:pl-16 xl:pl-24 2xl:pl-40': this.isGift,
        'lg:pb-12 xl:pb-20': this.isGuardians,
        'lg:pt-12 xl:pt-20': this.isPets,
      };
    },
    fit() {
      return this.type === 'gift' ? 'height' : 'width';
    },
    imageClasses() {
      return {
        'lg:rounded-t-none': this.isCharity,
        'lg:rounded-br-none': this.isCharity && this.partnership,
        'lg:rounded-none': this.isGift,
        'lg:rounded-t-none lg:rounded-r-none': this.isGuardians,
        'lg:rounded-b-none lg:rounded-r-none': this.isPets,
      };
    },
    isCharity() {
      return this.type === 'charity';
    },
    isGift() {
      return this.type === 'gift';
    },
    isGuardians() {
      return this.type === 'guardians';
    },
    isPets() {
      return this.type === 'pets';
    },
  },
  methods: {
    hasOverride(type) {
      return this.partnership && type === this.partnership.willFeatureOverride;
    },
  },
};
</script>
