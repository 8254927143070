<template>
  <Portal to="modal">
    <BaseModal v-if="showVideoModal" dismissable @close="toggleVideoModal">
      <div class="relative aspect-wide bg-black">
        <div class="absolute inset-0 w-full h-full">
          <BaseAsset :asset="video" />
        </div>
      </div>
    </BaseModal>
  </Portal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Portal } from 'portal-vue';

import BaseModal from '~/components/BaseModal';
import BaseAsset from '~/components/BaseAsset';

export default {
  name: 'VideoModal',
  components: {
    BaseModal,
    BaseAsset,
    Portal,
  },
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['showVideoModal']),
  },
  methods: {
    ...mapActions(['toggleVideoModal']),
  },
};
</script>
