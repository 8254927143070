<template>
  <div
    v-scroll="{ callback: onScroll }"
    :class="isLocked ? 'h-screen' : null"
    class="relative w-screen overflow-hidden"
  >
    <div
      ref="inner"
      :style="style"
      class="relative w-full ru:navigation-offset"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Viewport',
  data() {
    return {
      height: 0,
      isLocked: false,
      scrollTop: 0,
    };
  },
  computed: {
    style() {
      return {
        top: this.isLocked ? `-${this.scrollTop}px` : null,
        height: this.isLocked ? `${this.height}px` : null,
      };
    },
  },
  mounted() {
    this.$nuxt.$on('lockViewport', this.lock);
    this.$nuxt.$on('unlockViewport', this.unlock);
  },
  methods: {
    lock() {
      this.height = this.$el.offsetHeight;
      this.isLocked = true;
    },
    onScroll(scrollTop) {
      if (!this.isLocked) {
        this.scrollTop = scrollTop;
      }
    },
    unlock() {
      this.isLocked = false;
      this.$scroll.trigger();
    },
  },
};
</script>

<style lang="scss">
$navigation-max-width: 1010px;
.ru\: {
  &navigation-offset {
    padding-top: 8.5rem;
    @media (min-width: 1281px) {
      padding-top: 13rem;
    }
  }
}
</style>
