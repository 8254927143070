<template>
  <section>
    <div class="container content-padding">
      <div class="grid-container">
        <div class="grid-item w-full">
          <div
            v-if="hasMobileLayout"
            class="px-6 md:px-12 py-12 -mx-6 md:-mx-12 bg-orange-100"
          >
            <div class="mb-10">
              <h1 class="heading-6 font-medium mb-2">
                {{ $t('common.titles.guides') }}
              </h1>
              <p class="mb-5 text-black text-opacity-50">
                {{
                  $t(
                    'pages.guidesIndex.text.detailedGuidesOnImportantTopicsEveryAustralianShou'
                  )
                }}
              </p>
            </div>
            <BlogGuideSlider :items="guides" />
          </div>
          <div v-else class="relative py-10">
            <div class="absolute inset-0 rounded-xl -mx-12 bg-orange-100" />
            <div class="grid-container relative z-10">
              <div class="grid-item w-full xl:w-8/12">
                <h1 class="heading-6 font-medium mb-2">
                  {{ $t('common.titles.guides') }}
                </h1>
                <p class="mb-5 text-black text-opacity-50">
                  {{
                    $t(
                      'pages.guidesIndex.text.detailedGuidesOnImportantTopicsEveryAustralianShou'
                    )
                  }}
                </p>
              </div>
            </div>
            <div class="grid-container">
              <div
                v-for="(guide, index) in guides"
                :key="index"
                class="grid-item md:w-6/12 lg:w-4/12"
              >
                <BlogItemGuide v-bind="guide" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import BlogGuideSlider from '~/components/BlogGuideSlider';
import BlogItemGuide from '~/components/BlogItemGuide';

import GET_GUIDES_INDEX_QUERY from '~/graphql/queries/GetGuidesIndex';
import { craft, transition } from '~/mixins';

export default {
  name: 'PageGuides',
  components: {
    BlogGuideSlider,
    BlogItemGuide,
  },
  mixins: [
    craft({
      query: GET_GUIDES_INDEX_QUERY,
    }),
    transition,
  ],
  data() {
    return {
      guides: [],
    };
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
  },
};
</script>
