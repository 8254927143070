<template>
  <button
    class="h-16 p-2 rounded-full bg-black bg-opacity-10 text-navy-100"
    type="button"
    @click="modelValue = !modelValue"
  >
    <div class="relative flex h-full">
      <div
        :class="modelValue ? 'transform translate-x-full' : null"
        class="absolute top-0 left-0 w-1/2 h-full rounded-full bg-white transition transition-transform duration-300"
      />
      <div class="relative z-10 flex flex-1 justify-center items-center">
        <svg
          class="mr-2 fill-current"
          width="15"
          height="18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.096 10.517c-.778.457-1.653.733-2.596.733-.944 0-1.818-.276-2.596-.733A5.253 5.253 0 000 15.75v1.297l.521.168c.1.031 2.507.785 6.979.785 4.471 0 6.878-.754 6.979-.785l.52-.168V15.75a5.253 5.253 0 00-4.903-5.233zM7.499 9.75c2.534 0 4.5-2.822 4.5-5.25 0-2.482-2.019-4.5-4.5-4.5a4.504 4.504 0 00-4.5 4.5c0 2.428 1.965 5.25 4.5 5.25z"
          />
        </svg>
        <span>{{ $t('components.pricingToggle.text.individuals') }}</span>
      </div>
      <div class="relative z-10 flex flex-1 justify-center items-center">
        <svg
          class="mr-2 fill-current"
          width="22"
          height="20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.585 5.5a2.75 2.75 0 100-5.5 2.75 2.75 0 000 5.5zM17.416 5.5a2.75 2.75 0 100-5.5 2.75 2.75 0 000 5.5zM18.333 6.875c-2.635 0-3.995 1.893-4.812 3.047a9.489 9.489 0 01-1.685 1.96 2.058 2.058 0 01.902 2.188 8.776 8.776 0 001.929-1.844v6.566a.917.917 0 00.916.916h5.5a.917.917 0 00.917-.916v-8.25a3.667 3.667 0 00-3.667-3.667zM11.246 12.587a7.242 7.242 0 01-2.767-2.665c-.816-1.154-2.177-3.047-4.812-3.047A3.667 3.667 0 000 10.542v8.25a.917.917 0 00.917.916h5.5a.917.917 0 00.916-.916v-6.566a8.364 8.364 0 002.887 2.411 1.147 1.147 0 001.026-2.05z"
          />
        </svg>
        <span>{{ $t('components.pricingToggle.text.couples') }}</span>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'PricingToggle',
  props: {
    value: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      modelValue: null,
    };
  },
  watch: {
    modelValue(modelValue) {
      this.$emit('input', modelValue);
    },
    value(value) {
      this.modelValue = value;
    },
  },
  mounted() {
    this.modelValue = this.value;
  },
};
</script>
