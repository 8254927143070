<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <div
      v-if="showGrid"
      class="absolute inset-0 z-grid w-screen pointer-events-none"
    >
      <div class="vertical-grid absolute inset-0 opacity-10" />
      <div class="container h-full">
        <div class="grid-container h-full">
          <div
            v-for="n in 12"
            :key="n"
            class="grid-item w-1/4 md:w-1/12 h-full"
          >
            <div class="bg-grid opacity-10 h-full" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import anime from 'animejs';
import { mapGetters } from 'vuex';

export default {
  name: 'GridOverlay',
  computed: {
    ...mapGetters(['showGrid']),
  },
  methods: {
    enter(targets, complete) {
      anime({
        targets,
        opacity: [0, 1],
        duration: 150,
        easing: 'linear',
        complete,
      });
    },
    leave(targets, complete) {
      anime({
        targets,
        opacity: [1, 0],
        duration: 150,
        easing: 'linear',
        complete,
      });
    },
  },
};
</script>

<style scoped>
.vertical-grid {
  background-image: url("data:image/svg+xml,%3Csvg width='1' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23F00' d='M0 0h1v4H0z'/%3E%3C/svg%3E");
}
</style>
