var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"selection-bg-babyblue-100"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-item w-full"},[_c('div',{staticClass:"rounded-xl overflow-hidden bg-babyblue-100 text-center"},[_c('LogoBubbles',{attrs:{"logos":_vm.logos}}),_vm._v(" "),_c('div',{staticClass:"p-6 pt-0 md:p-20 md:pt-0 lg:p-16 lg:pt-0 xl:p-12 xl:pt-0"},[_c('h1',{staticClass:"mb-6 xl:mb-16 heading-3 xl:heading-1"},[_c('MarkedText',{staticClass:"text-white",attrs:{"text":_vm.headerContent.heading,"type":"circle-fill"}})],1),_vm._v(" "),_c('p',{staticClass:"max-w-3xl mx-auto mb-6 xl:mb-10 body-5 xl:body-3",domProps:{"textContent":_vm._s(_vm.headerContent.body)}}),_vm._v(" "),_c('CharitiesContactForm')],1)],1)])])])]),_vm._v(" "),_c('section',{staticClass:"selection-bg-babyblue-100"},[_c('div',{staticClass:"container content-padding"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-item w-full md:w-10/12 mx-auto mb-8 xl:mb-16 text-center"},[_c('SectionHeading',[_c('TextMarker',{staticClass:"text-babyblue-100",attrs:{"stroke-width":"9","type":"line-a"}},[_vm._v("\n              "+_vm._s(_vm.$t('pages.charitiesIndex.text.tenTimes'))+"\n            ")]),_vm._v("\n            "+_vm._s(_vm.$t(
                'pages.charitiesIndex.text.asManyAustraliansWouldLeaveAGiftToCharityIfItWasEa'
              ))+"\n          ")],1)],1)])]),_vm._v(" "),_c('div',{staticClass:"container content-padding"},[_c('div',{staticClass:"grid-container lg:mb-16 space-y-24 md:space-y-20 xl:space-y-32"},_vm._l((_vm.features),function(feature,index){return _c('div',{key:index,staticClass:"grid-item w-full"},[_c('div',{staticClass:"grid-container",class:index % 2 ? 'md:flex-row' : 'md:flex-row-reverse'},[_c('div',{staticClass:"grid-item w-full md:w-10/12 lg:w-1/2 xl:w-5/12 mb-24 md:mx-auto",class:index % 2 ? 'xl:ml-auto' : 'xl:mr-auto'},[_c('div',{staticClass:"relative"},[_c('CharitiesGraphic',{staticClass:"relative z-10",attrs:{"index":index}}),_vm._v(" "),_c('div',{staticClass:"absolute inset-0 rounded-xl bg-babyblue-100"})],1)]),_vm._v(" "),_c('div',{staticClass:"grid-item w-full lg:w-1/2 xl:w-5/12 md:pt-8 lg:pt-12",class:index % 2 ? 'xl:mr-auto' : 'xl:ml-auto'},[_c('BaseTextBlock',{attrs:{"heading":feature.heading,"body":feature.body}})],1)])])}),0)]),_vm._v(" "),_c('header',{staticClass:"selection-bg-babyblue-100"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-item w-full"},[_c('div',{staticClass:"rounded-xl bg-babyblue-100 p-6 pt-0 md:p-20 md:pt-0 lg:p-16 lg:pt-0 xl:p-12 xl:pt-0"},[_c('BaseHeader',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('h1',{staticClass:"heading-3 mb-2 xl:mb-3"},[_c('MarkedText',{staticClass:"text-teal-100",attrs:{"text":"Our charity partnerships <em>in the news</em>","type":"circle-fill"}})],1),_vm._v(" "),_c('p',{staticClass:"mb-6 body-5 xl:body-3"},[_vm._v("\n                    "+_vm._s(_vm.$t(
                        'common.text.safewillWasFeaturedOn7newsNationallyForOurWorkSupp'
                      ))+"\n                  ")])]},proxy:true},{key:"right",fn:function(){return [_c('VideoCard',{staticClass:"lg:mt-12 xl:mt-16",attrs:{"video":_vm.video,"video-thumbnail":_vm.videoThumbnail,"btn-text":"Safewill on 7News"}})]},proxy:true}])})],1)])])]),_vm._v(" "),_c('div',{staticClass:"container content-padding"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-item w-full md:w-10/12 lg:w-full mx-auto mb-8 xl:mb-16 text-center"},[_c('SectionHeading',[_c('i18n',{attrs:{"path":"common.text.pledgedByOurCustomersMonthly"}},[_c('TextMarker',{staticClass:"text-teal-100",attrs:{"stroke-width":"9","type":"line-a"}},[_vm._v("\n                  "+_vm._s(_vm.$t('common.text.millions'))+"\n                ")])],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"grid-item w-full"},[_c('ReviewSlider')],1)])])])]),_vm._v(" "),_c('section',{staticClass:"bg-teal-100 selection-bg-babyblue-100"},[_c('div',{staticClass:"container content-padding"},[_c('div',{staticClass:"grid-item w-full"},[_c('div',{staticClass:"max-w-xl mx-auto space-y-4 md:space-y-6 text-center"},[_c('SectionHeading',[_vm._v("\n            "+_vm._s(_vm.$t(
                'pages.charitiesIndex.text.partnerWithUsToGrowAustraliasLegacy'
              ))+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"text-black text-opacity-70"},[_vm._v("\n            "+_vm._s(_vm.$t(
                'pages.charitiesIndex.text.getInTouchAndLetsMakeItEasierThanEverForAustralian'
              ))+"\n          ")]),_vm._v(" "),_c('CharitiesContactForm'),_vm._v(" "),_c('ReviewSummary',{attrs:{"stars":""}})],1)])])]),_vm._v(" "),_c('VideoModal',{attrs:{"video":_vm.video}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }