<template>
  <div v-scroll="{ callback: onScroll }">
    <svg
      class="w-full"
      viewBox="0 0 412 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        ref="path"
        class="stroke-current"
        d="M7 66.368c11.88-17.53 25.082-32.711 40.285-47.51C51.51 14.745 57.454 7.678 63.103 5.01c.982-.463-6.236 14.805-6.623 15.654-11.011 24.16-19.61 49.22-32.95 72.305-.979 1.693-10.502 17.066-12.042 8.046-2.51-14.704 8.96-33.266 18.61-43.186C38.2 49.501 56.359 33.326 69.89 36.592c4.523 1.092-2.727 18.857-3.339 20.58-1.304 3.675-3.03 7.338-4.05 11.112-1.122 4.15 12.289-2.899 12.589-3.01 4.328-1.62 29.948-13.196 34.099-7.937 2.357 2.985-7.949 12.067-5.199 14.942 1.33 1.39 8.566-.585 8.648-.602 18.022-3.657 35.843-8.314 53.804-12.26 8.435-1.854 16.871-3.616 25.451-4.653 2.587-.313 12.303-2.677 14.888-.766.378.28.301 2.206.493 2.627 2.103 4.627 10.187 6.068 14.121 6.787 43.22 7.898 88.117 6.491 131.308-.328 16.704-2.638 32.79-7.117 49.207-10.947 1.747-.408 3.318-.548 5.09-.548"
        stroke-width="9"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
import anime from 'animejs';

export default {
  name: 'Signature',
  mounted() {
    anime.set(this.$refs.path, {
      strokeDashoffset: anime.setDashoffset,
    });
  },
  methods: {
    onScroll() {
      const rect = this.$rect(this.$el, true);

      if (rect.isVisible) {
        anime({
          targets: this.$refs.path,
          strokeDashoffset: 0,
          duration: 1500,
          easing: 'easeInOutCubic',
        });
      }

      return rect.isVisible;
    },
  },
};
</script>
