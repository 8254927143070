<template>
  <div>
    <div
      class="grid-item flex justify-end w-full mb-8 xl:mb-12 heading-5 xl:heading-4 space-x-8 xl:space-x-12"
    >
      <button
        v-for="(product, index) in products"
        :key="index"
        :aria-label="$t('common.buttons.showSection')"
        :class="[
          product.buttonClasses,
          index === currentIndex ? 'text-black' : null,
        ]"
        :style="getMarkerStyles(product)"
        type="button"
        @click="currentIndex = index"
      >
        <TextMarker :active="index === currentIndex" type="circle-fill">
          {{ product.label }}
        </TextMarker>
      </button>
    </div>
    <div class="grid-item w-full">
      <div
        v-for="(product, index) in currentProduct"
        :key="index"
        :class="product.classes"
        :style="getStyles(product)"
        class="rounded-xl overflow-hidden"
      >
        <div class="grid-container">
          <div
            class="grid-item flex flex-col w-6/12 xl:w-5/12 p-16 xl:p-20 pr-0"
          >
            <WillFeatureText
              :title="product.title"
              :heading="getMergedHeading(product)"
              :body="getMergedBody(product)"
              class="mb-8 xl:mb-12"
            />
            <div>
              <OnboardingButton
                class="px-12"
                :to="getStartedPageUrl"
                arrow
                size="large"
              >
                {{ $t('common.buttons.getStarted') }}
              </OnboardingButton>
            </div>
          </div>
          <div class="grid-item flex flex-col w-6/12 xl:w-7/12">
            <ProductGraphic
              :asset="getMergedImage(product)"
              :type="product.imageType"
            />
            <div class="flex space-x-8 justify-center text-black my-4 lg:my-8">
              <ArrowButton direction="<" @click="prev" />
              <ArrowButton direction=">" @click="next" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ArrowButton from '~/components/ArrowButton';
import TextMarker from '~/components/TextMarker';
import ProductGraphic from '~/components/ProductGraphic';
import WillFeatureText from '~/components/WillFeatureText';
import OnboardingButton from '~/components/OnboardingButton.vue';

import { collapseFieldArray } from '~/util';

export default {
  name: 'ProductSwitcher',
  components: {
    ArrowButton,
    TextMarker,
    ProductGraphic,
    WillFeatureText,
    OnboardingButton,
  },
  props: {
    products: {
      required: true,
      type: Array,
    },
    promoCode: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    ...mapGetters(['partnership']),
    currentProduct() {
      return [this.products[this.currentIndex]];
    },
    productOverrideContent() {
      return this.partnership
        ? collapseFieldArray(this.partnership.willFeatureContent)
        : null;
    },
    getStartedPageUrl() {
      const currentPageUrl = this.currentProduct[0]?.getStartedPageUrl;
      if (!currentPageUrl) {
        return null;
      }
      if (!this.promoCode) {
        return currentPageUrl;
      }

      return `${currentPageUrl}?coupon=${this.promoCode}`;
    },
  },
  watch: {
    partnership(partnership) {
      if (partnership) {
        this.currentIndex = this.products.findIndex(
          (feature) => feature.slug === partnership.willFeatureOverride
        );
      }
    },
  },
  mounted() {
    if (this.partnership) {
      this.currentIndex = this.products.findIndex(
        (feature) => feature.slug === this.partnership.willFeatureOverride
      );
    }
  },
  methods: {
    getMergedBody(feature) {
      let body = feature.body;

      if (this.hasOverride(feature)) {
        body = this.willFeatureOverrideContent.body || body;
      }

      return body;
    },
    getMergedHeading(feature) {
      let heading = feature.heading;

      if (this.hasOverride(feature)) {
        heading = this.willFeatureOverrideContent.heading || heading;
      }

      return heading;
    },
    getMergedImage(feature) {
      let image = feature.image;

      if (this.hasOverride(feature)) {
        image = this.willFeatureOverrideContent.image[0] || image;
      }

      return image;
    },
    getStyles(feature) {
      return this.hasOverride(feature) &&
        this.willFeatureOverrideContent.backgroundColour
        ? {
            backgroundColor: this.willFeatureOverrideContent.backgroundColour,
            color: 'rgba(0, 0, 0, 0.5)',
          }
        : {};
    },
    getMarkerStyles(feature) {
      return this.hasOverride(feature) &&
        this.willFeatureOverrideContent.backgroundColour
        ? {
            color: this.willFeatureOverrideContent.backgroundColour,
          }
        : {};
    },
    next() {
      this.currentIndex = (this.currentIndex + 1) % this.products.length;
    },
    prev() {
      this.currentIndex =
        this.currentIndex === 0
          ? this.products.length - 1
          : (this.currentIndex - 1) % this.products.length;
    },
    hasOverride(feature) {
      return (
        this.partnership &&
        feature.slug === this.partnership.willFeatureOverride
      );
    },
  },
};
</script>
