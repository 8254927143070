<template>
  <HomePageContent :entry="entry" />
</template>

<script>
import reviewsData from '~/reviews.json';

import HomePageContent from '~/components/HomePageContent';

import GET_HOME_QUERY from '~/graphql/queries/GetHome';
import { craft, home, seomatic, transition, partnerships } from '~/mixins';
import { transformHomeData } from '~/util';

export default {
  name: 'PageHome',
  components: {
    HomePageContent,
  },
  mixins: [
    craft({
      query: GET_HOME_QUERY,
      transform: transformHomeData,
    }),
    home,
    seomatic,
    transition,
    partnerships,
  ],
  data() {
    return {
      entry: null,
    };
  },
  computed: {
    structuredWill() {
      const latestReview = reviewsData.reviews[0];
      return {
        '@type': 'Product',
        name: 'Safewill',
        description: `Australia's most trusted digital Will-writing platform. We've taken the stress, hassle and cost out of writing your bespoke legal Will.`,
        image: 'https://assets.safewill.com/images/groupshot-safewill.jpg',
        telephone: '1800103310',
        address: {
          '@type': 'PostalAddress',
          streetAddress: 'Level 7/28 Foveaux St',
          addressLocality: 'Australia',
          addressRegion: 'Surry Hills',
          postalCode: '2010',
          addressCountry: 'AU',
        },
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: reviewsData.averageRating,
          bestRating: '5',
          ratingCount: reviewsData.totalReviews,
        },
        review: {
          '@type': 'Review',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: `${latestReview.rating}`,
            bestRating: '5',
          },
          author: {
            '@type': 'Person',
            name: latestReview.reviewer,
          },
          reviewBody: latestReview.comments,
        },
      };
    },
  },
  head() {
    return {
      title: 'Make a Will Online Australia - Secure & Simple | Safewill',
      script: [
        {
          json: {
            '@context': 'https://schema.org',
            '@type': 'Product',
            ...this.structuredWill,
          },
          type: 'application/ld+json',
        },
      ],
    };
  },
};
</script>
