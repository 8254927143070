<template>
  <NuxtLink :to="localePath(`/${uri}`)" class="flex flex-col">
    <BaseAsset
      :asset="headerImage[0]"
      sizes="(min-width: 1440px) 390px, (min-width: 1280px) 27vw, (min-width: 1024px) 45vw, (min-width: 768px) 44vw, 87vw"
      border
      crop
      rounded
      class="mb-6"
    />
    <div class="flex items-baseline space-x-3 mb-3">
      <BlogItemType v-if="postType.length" class="bg-green-300 bg-opacity-50">
        {{ postType[0].title }}
      </BlogItemType>
      <span
        class="font-medium text-sm leading-none uppercase text-navy-100 text-opacity-30"
        v-text="readingTime"
      />
    </div>
    <div class="flex flex-col font-medium space-y-1 xl:space-y-2">
      <h3 class="body-3 font-medium" v-text="title" />
      <span
        class="text-base text-navy-100 text-opacity-60"
        v-text="author.fullName"
      />
    </div>
  </NuxtLink>
</template>

<script>
import BaseAsset from '~/components/BaseAsset';
import BlogItemType from '~/components/BlogItemType';

import blogItemProps from '~/modules/blog-item-props';

export default {
  name: 'BlogItem',
  components: {
    BaseAsset,
    BlogItemType,
  },
  props: { ...blogItemProps() },
};
</script>
