<template>
  <div class="pt-10">
    <BlogTemplate v-bind="entry">
      <!-- eslint-disable vue/no-v-html -->
      <div class="rich-text" v-html="entry.textContent" />
      <!--eslint-enable-->
    </BlogTemplate>
  </div>
</template>

<script>
import BlogTemplate from '~/components/BlogTemplate';

import GET_BLOG_POST_QUERY from '~/graphql/queries/GetBlogPost';
import { craft, seomatic, transition } from '~/mixins';

export default {
  name: 'PageBlogPost',
  components: {
    BlogTemplate,
  },
  mixins: [
    craft({
      query: GET_BLOG_POST_QUERY,
      variables({ route }) {
        const slug = route.path.split('/').pop();
        return {
          slug,
        };
      },
    }),
    seomatic,
    transition,
  ],
  data() {
    return {
      entry: null,
    };
  },
};
</script>

<style lang="postcss" scoped>
.date-updated {
  font-size: 14px;
}
</style>
