<template>
  <div>
    <div
      class="grid-item flex justify-end w-full mb-8 xl:mb-12 heading-5 xl:heading-4 space-x-8 xl:space-x-12"
    >
      <button
        v-for="(feature, index) in features"
        :key="index"
        :aria-label="$t('common.buttons.showSection')"
        :class="[
          feature.buttonClasses,
          index === currentIndex ? 'text-black' : null,
        ]"
        :style="getMarkerStyles(feature)"
        type="button"
        @click="currentIndex = index"
      >
        <TextMarker :active="index === currentIndex" type="circle-fill">
          {{ feature.label }}
        </TextMarker>
      </button>
    </div>
    <div class="grid-item w-full">
      <div
        v-for="(feature, index) in currentFeature"
        :key="index"
        :class="feature.classes"
        :style="getStyles(feature)"
        class="rounded-xl overflow-hidden"
      >
        <div class="grid-container">
          <div
            class="grid-item flex flex-col w-6/12 xl:w-5/12 p-16 xl:p-20 pr-0"
          >
            <WillFeatureText
              :title="feature.title"
              :heading="getMergedHeading(feature)"
              :body="getMergedBody(feature)"
              class="mb-8 xl:mb-12"
            />
            <div class="flex space-x-8 text-black">
              <ArrowButton direction="<" @click="prev" />
              <ArrowButton direction=">" @click="next" />
            </div>
          </div>
          <div class="grid-item flex w-6/12 xl:w-7/12">
            <WillFeatureGraphic
              :asset="getMergedImage(feature)"
              :type="feature.imageType"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ArrowButton from '~/components/ArrowButton';
import TextMarker from '~/components/TextMarker';
import WillFeatureGraphic from '~/components/WillFeatureGraphic';
import WillFeatureText from '~/components/WillFeatureText';

import { collapseFieldArray } from '~/util';

export default {
  name: 'WillFeatureSwitcher',
  components: {
    ArrowButton,
    TextMarker,
    WillFeatureGraphic,
    WillFeatureText,
  },
  props: {
    features: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    ...mapGetters(['partnership']),
    currentFeature() {
      return [this.features[this.currentIndex]];
    },
    willFeatureOverrideContent() {
      return this.partnership
        ? collapseFieldArray(this.partnership.willFeatureContent)
        : null;
    },
  },
  watch: {
    partnership(partnership) {
      if (partnership) {
        this.currentIndex = this.features.findIndex(
          (feature) => feature.slug === partnership.willFeatureOverride
        );
      }
    },
  },
  mounted() {
    if (this.partnership) {
      this.currentIndex = this.features.findIndex(
        (feature) => feature.slug === this.partnership.willFeatureOverride
      );
    }
  },
  methods: {
    getMergedBody(feature) {
      let body = feature.body;

      if (this.hasOverride(feature)) {
        body = this.willFeatureOverrideContent.body || body;
      }

      return body;
    },
    getMergedHeading(feature) {
      let heading = feature.heading;

      if (this.hasOverride(feature)) {
        heading = this.willFeatureOverrideContent.heading || heading;
      }

      return heading;
    },
    getMergedImage(feature) {
      let image = feature.image;

      if (this.hasOverride(feature)) {
        image = this.willFeatureOverrideContent.image[0] || image;
      }

      return image;
    },
    getStyles(feature) {
      return this.hasOverride(feature) &&
        this.willFeatureOverrideContent.backgroundColour
        ? {
            backgroundColor: this.willFeatureOverrideContent.backgroundColour,
            color: 'rgba(0, 0, 0, 0.5)',
          }
        : {};
    },
    getMarkerStyles(feature) {
      return this.hasOverride(feature) &&
        this.willFeatureOverrideContent.backgroundColour
        ? {
            color: this.willFeatureOverrideContent.backgroundColour,
          }
        : {};
    },
    next() {
      this.currentIndex = (this.currentIndex + 1) % this.features.length;
    },
    prev() {
      this.currentIndex =
        this.currentIndex === 0
          ? this.features.length - 1
          : (this.currentIndex - 1) % this.features.length;
    },
    hasOverride(feature) {
      return (
        this.partnership &&
        feature.slug === this.partnership.willFeatureOverride
      );
    },
  },
};
</script>
