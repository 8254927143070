<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <div class="fixed inset-0 z-50">
      <div ref="background" class="absolute inset-0">
        <div class="h-full bg-teal-100 bg-blur" />
      </div>
      <div class="w-full h-full overflow-y-auto">
        <div ref="container" class="container">
          <div class="grid-container">
            <div class="grid-item w-full mx-auto">
              <div
                v-on-dismiss="{ callback: dismissable ? close : null }"
                class="relative my-6 md:my-16 xl:my-24 rounded-xl overflow-hidden bg-white"
              >
                <div>
                  <slot />
                </div>
                <button
                  v-if="dismissable"
                  class="absolute top-0 right-0 z-10 flex justify-center items-center w-8 h-8 border border-grey-100 hover:border-grey-300 rounded-full m-6 bg-white transition transition-colors duration-200"
                  type="button"
                  :aria-label="$t('common.buttons.close')"
                  @click.prevent="close"
                >
                  <svg
                    class="stroke-current"
                    width="12"
                    height="12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M11 1L1 11" />
                      <path d="M1 1l10 10" />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import anime from 'animejs';
import VDismiss from 'vue-dismiss';

export default {
  name: 'BaseModal',
  mixins: [VDismiss],
  props: {
    classes: {
      default: '',
      type: String,
    },
    dismissable: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    enter(_, complete) {
      const timeline = anime.timeline({
        autoplay: false,
        complete,
      });

      timeline
        .add({
          targets: this.$refs.background,
          opacity: [0, 1],
          duration: 300,
          easing: 'easeOutQuad',
        })
        .add(
          {
            targets: this.$refs.container,
            translateY: {
              value: [window.innerWidth / 10, 0],
              duration: 900,
              easing: 'easeOutExpo',
            },
            translateZ: 0,
            opacity: {
              value: [0, 1],
              duration: 200,
              easing: 'easeOutQuad',
            },
          },
          '-=300'
        );

      timeline.play();
    },
    leave(targets, complete) {
      anime({
        targets,
        opacity: [1, 0],
        duration: 300,
        easing: 'easeOutQuad',
        complete,
      });
    },
  },
};
</script>
