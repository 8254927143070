import { render, staticRenderFns } from "./BlogItemFeatured.vue?vue&type=template&id=27381912&scoped=true&"
import script from "./BlogItemFeatured.vue?vue&type=script&lang=js&"
export * from "./BlogItemFeatured.vue?vue&type=script&lang=js&"
import style0 from "./BlogItemFeatured.vue?vue&type=style&index=0&id=27381912&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27381912",
  null
  
)

export default component.exports