<template>
  <div
    v-scroll="{ callback: onScroll }"
    class="will relative inline-flex flex-col items-start w-40 p-6 md:px-10 md:py-12 bg-white shadow-graphic text-base text-black text-opacity-10 select-none cursor-default"
  >
    <!-- Masthead -->
    <div class="h-4 md:h-6 mb-4 md:mb-8" style="max-width: 20px">
      <svg
        class="h-full text-black text-opacity-10 fill-current"
        viewBox="0 0 37 46"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.107 32.1l3.819 3.778c3.845 3.804 10.071 3.804 13.917 0a9.707 9.707 0 000-13.827l-6.455-6.385-2.733 2.763 6.454 6.385a5.82 5.82 0 010 8.3c-2.33 2.307-6.119 2.307-8.45 0l-3.819-3.776L8.107 32.1z"
        />
        <path
          d="M28.73 13.825l-3.818-3.778c-3.845-3.804-10.072-3.804-13.917 0a9.707 9.707 0 000 13.827l5.073 5.018 1.381 1.367 2.734-2.763-6.455-6.385a5.82 5.82 0 010-8.3c2.331-2.307 6.12-2.307 8.45 0l3.82 3.777 2.733-2.763z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M36.838 27.684c0 10.08-8.252 18.241-18.42 18.241C8.252 45.925 0 37.764 0 27.686V18.24C0 8.162 8.251 0 18.419 0c10.168 0 18.419 8.162 18.419 18.241v9.443zm-32.951 0c0 7.923 6.501 14.355 14.532 14.355 8.03 0 14.532-6.432 14.532-14.355v-9.443c0-7.923-6.502-14.354-14.532-14.354-8.03 0-14.532 6.431-14.532 14.354v9.443z"
        />
      </svg>
    </div>
    <!-- Text -->
    <div class="w-full mb-4 md:mb-8">
      <svg
        class="w-full"
        viewBox="0 0 202 196"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          ref="lines"
          class="stroke-current"
          fill="none"
          stroke-width="5"
          stroke-linecap="round"
        >
          <path d="M3 2.5h36.702" />
          <path d="M3 32.5h196.022" />
          <path d="M3 45.5h196.022" />
          <path d="M3 58.5h196.022" />
          <path d="M3 71.5h196.022" />
          <path d="M3 84.5h196.022" />
          <path d="M3 97.5h196.022" />
          <path d="M3 127.5h100.01" />
          <path d="M3 154.5h196.022" />
          <path d="M3 167.5h196.022" />
          <path d="M3 180.5h196.022" />
          <path d="M3 193.5h86.001" />
        </g>
      </svg>
    </div>
    <!-- Signature -->
    <div
      :class="action === 'sign' ? null : 'hidden'"
      class="mb-1 md:mb-3 text-teal-200"
    >
      <svg class="w-1/3" viewBox="0 0 73 23" xmlns="http://www.w3.org/2000/svg">
        <path
          ref="signature"
          class="stroke-current"
          d="M1.445 14.263C6.69 10.617 11.751 6.69 17.178 3.327c1.389-.86 4.696-3.302 4.605-1.667-.148 2.66-3.345 4.14-4.864 6.325-1.915 2.755-3.314 5.874-5.427 8.48-.476.586-.125-1.664.364-2.238 1.252-1.468 2.866-2.643 4.567-3.546a5.254 5.254 0 0 1 3.139-.542c.703.1 1.163 1.044-.405 4.003-1.076 2.031-2.453 3.899-3.885 5.695-.468.588-1.432 2.071-1.766 1.397-1.558-3.146 7.547-10.82 8.798-9.821 1.876 1.5.312 5.935 4.008 5.546 4.345-.458 9.063-4.652 12.869-2.498l1.52-.023c4.503 2.548-1.118 3.398 7.426 2.472 1.969-.213 6.942-2.508 9.536-2.07 4.705.792 9.46 4.924 14.22.306"
          fill="none"
          stroke-width="2"
        />
      </svg>
    </div>
    <!-- Magnifying Glass -->
    <div
      ref="lens"
      :class="action === 'review' ? null : 'hidden'"
      class="absolute top-0 left-0 w-10 md:w-20 h-10 md:h-20 mt-16 md:mt-24 -ml-5 md:-ml-10 rounded-full whitespace-no-wrap"
    >
      <div
        class="flex items-center h-full rounded-full overflow-hidden translate-none backface-hidden"
      >
        <span
          ref="text"
          class="will-text inline-block px-8 md:px-20 ml-5 md:ml-10 font-serif text-black text-opacity-40 bg-white"
        >
          {{ $t('components.willGraphic.text.theLastWillAndTestamentOf') }}
        </span>
      </div>
      <div
        class="glass absolute top-0 left-0 w-full h-full border-teal-200 rounded-full"
      />
      <div
        class="handle absolute w-2/3 h-2 md:h-3 -ml-3 md:-ml-6 -mt-3 md:-mt-6 bg-teal-200"
        style="transform: rotate(45deg); transform-origin: bottom left"
      />
    </div>
  </div>
</template>

<script>
import anime from 'animejs';

export default {
  name: 'WillGraphic',
  props: {
    action: {
      required: true,
      type: String,
      validate(action) {
        return ['review', 'sign'].includes(action);
      },
    },
  },
  data() {
    return {
      anim: null,
      actionAnim: null,
      isVisible: false,
    };
  },
  mounted() {
    this.anim = anime.timeline({
      autoplay: false,
      complete: () => {
        this.$scroll.trigger();
      },
    });

    this.anim
      .add({
        targets: this.$el,
        translateY: {
          value: [90, 0],
          duration: 850,
          easing: 'easeOutQuart',
        },
        opacity: {
          value: [0, 1],
          duration: 250,
          easing: 'easeInOutQuad',
        },
        translateZ: 0,
      })
      .add(
        {
          targets: this.$refs.lines.children,
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 650,
          easing: 'easeInOutQuad',
          delay: anime.stagger(40, { start: 200 }),
        },
        '-=800'
      );

    this.actionAnim = anime.timeline({ autoplay: false });

    switch (this.action) {
      case 'review':
        this.actionAnim
          .add({
            targets: this.$refs.lens,
            translateX: [-50, 0],
            translateY: [30, 0],
            translateZ: 0,
            rotate: [-20, 0],
            opacity: {
              value: [0, 1],
              duration: 250,
              easing: 'easeInOutQuad',
            },
            transformOrigin: 'bottom right',
            easing: 'easeOutCubic',
            duration: 650,
          })
          .add({
            targets: this.$refs.text,
            opacity: [0, 1],
            duration: 1,
          })
          .add({
            targets: this.$refs.lens,
            translateX: [0, `${this.$el.offsetWidth}px`],
            translateZ: 0,
            duration: 3000,
            easing: 'easeInOutQuad',
          })
          .add(
            {
              targets: this.$refs.text,
              translateX: '-100%',
              translateZ: 0,
              duration: 3000,
              easing: 'easeInOutQuad',
            },
            '-=3000'
          );
        break;
      case 'sign':
        this.actionAnim.add({
          targets: this.$refs.signature,
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutQuad',
        });
        break;
      default:
        break;
    }
  },
  methods: {
    onScroll() {
      const { isVisible, visible } = this.$rect(this.$el, true);
      const playAction = visible === 1;

      if (!this.isVisible && isVisible) {
        this.anim.play();
        this.isVisible = true;
      }

      if (this.anim.completed && playAction) {
        this.actionAnim.play();
      }

      return this.isVisible && this.anim.completed && playAction;
    },
  },
};
</script>

<style lang="postcss" scoped>
.glass {
  border-width: 6px;
}

.handle {
  top: 100%;
  left: 100%;
}

.will {
  @apply leading-normal;
}

.will-text {
  font-size: 22px;
}

@screen md {
  .glass {
    border-width: 10px;
  }

  .will {
    width: 280px;
  }

  .will-text {
    font-size: 42px;
  }
}
</style>
