<template>
  <HomeContent :entry="entry" promo-page :promo-code="promotion.couponCode">
    <template #promoBanner>
      <div class="container mb-10 lg:mb-20">
        <div class="grid-container">
          <div class="grid-item w-full">
            <a
              :href="`${$config.safewillAppUrl}/get-started?coupon=${promotion.couponCode}`"
              :aria-label="promotion.bannerAltText"
              class="rounded-xl p-5 block"
              :style="`background-color: ${promotion.backgroundColour}`"
            >
              <img
                :src="promotion.desktopBannerImage[0].original"
                class="promotion__banner promotion__banner--desktop"
                alt=""
              />
              <img
                :src="promotion.mobileBannerImage[0].original"
                class="promotion__banner promotion__banner--mobile"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </template>
  </HomeContent>
</template>

<script>
import HomeContent from '~/components/HomeContent';

import GET_HOME_QUERY from '~/graphql/queries/GetHome';
import { craft, home, seomatic, transition } from '~/mixins';
import { transformHomeData } from '~/util';

export default {
  name: 'PagePromotion',
  components: {
    HomeContent,
  },
  mixins: [
    craft({
      query: GET_HOME_QUERY,
      transform: transformHomeData,
    }),
    home,
    seomatic,
    transition,
  ],
  data() {
    return {
      entry: null,
    };
  },
  head() {
    const promotionTitle = this.$data?.promotion?.title;
    const seomaticTitle = this.seomatic?.title;
    return {
      title:
        (promotionTitle || '') +
          (promotionTitle && seomaticTitle ? ' | ' : '') +
          (seomaticTitle || '') || 'Safewill',
    };
  },
};
</script>
<style lang="postcss">
.promotion__banner {
  display: none;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .promotion__banner--mobile {
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  .promotion__banner--desktop {
    display: block;
  }
}
</style>
