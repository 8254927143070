<template>
  <div class="flex flex-col w-full px-5 py-6 rounded-md body-7">
    <div class="flex items-center mb-5">
      <ReviewStars :rating="rating" :class="starClass" />
      <span class="ml-auto text-black text-opacity-40" v-text="timeago" />
    </div>
    <p class="mb-1" v-text="truncatedComment" />
    <span class="mt-auto text-black text-opacity-30" v-text="reviewer" />
  </div>
</template>

<script>
import ReviewStars from '~/components/ReviewStars';

export default {
  name: 'ReviewCard',
  components: {
    ReviewStars,
  },
  props: {
    comment: {
      required: true,
      type: String,
    },
    date: {
      required: true,
      type: String,
    },
    rating: {
      required: true,
      type: Number,
    },
    reviewer: {
      required: true,
      type: String,
    },
    starClass: {
      default: 'text-teal-200',
      type: String,
    },
    timeago: {
      required: true,
      type: String,
    },
  },
  computed: {
    truncatedComment() {
      const truncateLength = 70;
      let truncatedComment = this.comment.substr(0, truncateLength);
      if (truncatedComment.length === truncateLength) {
        truncatedComment =
          truncatedComment.substr(0, truncatedComment.lastIndexOf(' ')) + '...';
      }
      return truncatedComment;
    },
  },
};
</script>
