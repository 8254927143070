<template>
  <NuxtLink :to="localePath(`/${uri}`)" class="flex space-x-3 xl:space-x-5">
    <div class="flex flex-col flex-grow">
      <div class="flex items-baseline space-x-3 mb-3">
        <BlogItemType v-if="postType.length" class="bg-green-300 bg-opacity-50">
          {{ postType[0].title }}
        </BlogItemType>
        <span
          class="font-medium text-sm leading-none uppercase text-navy-100 text-opacity-30"
          v-text="readingTime"
        />
      </div>
      <h3 class="mb-2 font-medium" v-text="title" />
      <span
        class="font-medium text-base leading-none text-navy-100 text-opacity-60"
        v-text="author.fullName"
      />
    </div>
    <div class="image flex-none">
      <div class="w-full">
        <BaseAsset :asset="headerImage[0]" border crop rounded />
      </div>
    </div>
  </NuxtLink>
</template>

<script>
import BaseAsset from '~/components/BaseAsset';
import BlogItemType from '~/components/BlogItemType';

import blogItemProps from '~/modules/blog-item-props';

export default {
  name: 'BlogItemCompact',
  components: {
    BaseAsset,
    BlogItemType,
  },
  props: { ...blogItemProps() },
};
</script>

<style lang="postcss" scoped>
h3 {
  font-size: 18px;
  line-height: 24px;
}

@screen md {
  h3 {
    font-size: 24px;
    line-height: 32px;
  }
}

@screen lg {
  h3 {
    font-size: 18px;
    line-height: 24px;
  }
}

.image {
  width: 140px;
}

@screen md {
  .image {
    width: 240px;
  }
}

@screen lg {
  .image {
    width: 175px;
  }
}

@screen xl {
  .image {
    width: 175px;
  }
}
</style>
