<template>
  <div ref="keen" class="keen-slider w-full">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="keen-slider__slide flex"
    >
      <BlogItemGuide v-bind="item" />
    </div>
  </div>
</template>

<script>
import KeenSlider from 'keen-slider';

import BlogItemGuide from '~/components/BlogItemGuide';

export default {
  name: 'BlogGuideSlider',
  components: {
    BlogItemGuide,
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      keen: null,
    };
  },
  mounted() {
    this.keen = new KeenSlider(this.$refs.keen, {
      breakpoints: {
        '(min-width: 768px)': {
          slidesPerView: 2.1,
          spacing: 32,
          centered: false,
        },
      },
      slidesPerView: 1.1,
      spacing: 16,
      duration: 750,
      mode: 'free-snap',
    });
  },
};
</script>

<style lang="postcss" scoped>
.keen-slider {
  @apply overflow-visible;
}
</style>
