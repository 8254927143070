<template>
  <HomeContent :entry="entry" />
</template>

<script>
import { capitalize } from 'lodash';
import { mapActions } from 'vuex';

import HomeContent from '~/components/HomeContent';

import GET_HOME_QUERY from '~/graphql/queries/GetHome';
import GET_INVITE_QUERY from '~/graphql/queries/GetInvite';
import { craft, home, seomatic, transition, partnerships } from '~/mixins';
import { transformHomeData, saveAnonymousProfile } from '~/util';

export default {
  name: 'PageHome',
  components: {
    HomeContent,
  },
  mixins: [
    craft({
      query: GET_HOME_QUERY,
      transform: transformHomeData,
    }),
    home,
    seomatic,
    transition,
    partnerships,
  ],
  async fetch() {
    try {
      const { data } = await this.$apollo.query({
        query: GET_INVITE_QUERY,
        client: 'safewill',
        variables: {
          code: this.$route.params.code,
        },
      });
      const invite = data.getInvite && data.getInvite.invite;
      const name = invite.code.split('-')[0];
      this.setAppQuery({ coupon: invite.code });
      if (invite.type === 'PROMO') {
        this.setBannerText(
          'Welcome to Safewill. Click "Get started" to write your Will today.'
        );
      } else {
        this.setBannerText(
          `${capitalize(name)} has invited you to get ${
            invite.type === 'PARTNER' ? 50 : 20
          }% off`
        );
      }

      saveAnonymousProfile({ couponCode: this.$route.params.code });

      this.setTheme([
        {
          background: '#CCF6E8',
          logoBackground: '#CCF6E8',
          text: '#0f1548',
          textAlternate: '#000000',
        },
      ]);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
      this.$router.push({
        path: this.localePath('/'),
        query: this.$route.query,
      });
    }
  },
  data() {
    return {
      entry: null,
    };
  },
  methods: {
    ...mapActions(['setTheme', 'setAppQuery']),
    ...mapActions('banner', ['setBannerText']),
  },
};
</script>
