<template>
  <div>
    <data
      id="mj-w-res-data"
      data-token="e3b665046d25d5a114b73e72bb87fc60"
      class="mj-w-data"
      data-apikey="71Ek"
      data-w-id="NhP"
      data-lang="en_US"
      data-base="https://app.mailjet.com"
      data-width="640"
      data-height="328"
      data-statics="statics"
      value=""
    ></data>
    <BaseHeader>
      <template #left>
        <h1 class="mb-6 xl:mb-16 heading-3 xl:heading-2">
          <MarkedText
            :text="headerContent.heading"
            class="text-teal-100"
            type="circle-fill"
          />
        </h1>
        <p
          class="body-5 xl:body-3 whitespace-pre-line"
          v-text="headerContent.body"
        />
        <div class="mt-10">
          <div v-if="isRegistered">
            <section class="bg-teal-100 selection-bg-babyblue-100 rounded-xl">
              <div class="p-5">
                <p class="text-2xl">
                  <MarkedText
                    text="<p>Thanks! We’ll keep you <em>updated.</em></p>"
                    class="text-white"
                    stroke-width="20"
                    type="line-b"
                  />
                </p>
              </div>
            </section>
          </div>
          <div v-else>
            <iframe
              class="mj-w-res-iframe"
              title="mj-widget-iframe"
              src="https://app.mailjet.com/widget/iframe/71Ek/NkA"
              width="100%"
            ></iframe>
          </div>
        </div>
      </template>
      <template #right>
        <BaseAsset
          :asset="headerImage[0]"
          :crop="hasMobileLayout"
          class="mb-8 md:mb-12 xl:mb-0"
          rounded
          sizes="(min-width: 1440px) 600px, (min-width: 1280px) 42vw, (min-width: 1024px) 45vw, (min-width: 768px) 67vw, 85vw"
        />
      </template>
    </BaseHeader>
    <section class="selection-bg-teal-100">
      <div class="container content-padding">
        <div class="grid-container">
          <div
            class="grid-item w-full md:w-10/12 lg:w-full mx-auto mb-8 xl:mb-16 text-center"
          >
            <SectionHeading>
              <TextMarker class="text-teal-100" stroke-width="9" type="line-a">
                {{ $t('common.text.trustedByThousands') }}
              </TextMarker>
              {{ $t('common.text.ofAustraliansEachMonth') }}
            </SectionHeading>
          </div>
          <div class="grid-item w-full">
            <ReviewSlider />
          </div>
        </div>
      </div>
    </section>
    <section v-if="hasMobileLayout" class="flex flex-col">
      <div class="container py-12">
        <div class="grid-container">
          <div class="grid-item w-full">
            <div
              class="rounded-xl bg-salmon-100 text-salmon-300 selection-bg-salmon-100 p-6 pt-5"
            >
              <h4 class="heading-5">
                <MarkedText
                  text="<em>Crypto</em> and NFT’s"
                  class="text-white"
                  stroke-width="20"
                  type="line-b"
                />
              </h4>
              <p class="whitespace-pre-line">
                {{
                  $t(
                    'pages.vault.text.everWonderHowYouCanPassOnDetailsOfYourCryptocurren'
                  )
                }}
              </p>
              <div class="relative flex lg:pb-20 mt-20">
                <div
                  class="flex overflow-hidden rounded-xl lg:rounded-t-none w-full"
                >
                  <BaseAsset
                    :asset="contentImage[0]"
                    fit="cover"
                    sizes="(min-width: 1440px) 720px, (min-width: 1280px) 50vw, (min-width: 1024px) 45vw, (min-width: 768px) 75vw, 87vw"
                  />
                </div>
                <div class="absolute inset-0 flex justify-center items-center">
                  <div class="flex justify-center w-4/5 lg:w-2/3 mb-20">
                    <AppCardVault class="w-2/3" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-else>
      <div class="container mb-4 md:mb-8">
        <div class="grid-container">
          <div class="grid-item w-full">
            <div
              class="rounded-xl overflow-hidden bg-salmon-100 text-salmon-300 selection-bg-salmon-100"
            >
              <div class="grid-container">
                <div
                  class="grid-item flex flex-col w-6/12 xl:w-5/12 p-16 xl:p-20 pr-0"
                >
                  <h3 class="mb-4 xl:mb-8">
                    {{ $t('pages.vault.titles.digitalAssets') }}
                  </h3>
                  <div class="text-black">
                    <h4
                      class="mb-5 xl:mb-8 heading-5 md:heading-4 lg:heading-5 xl:heading-4"
                    >
                      <MarkedText
                        text="<em>Crypto</em> and NFT’s"
                        class="text-white"
                        stroke-width="20"
                        type="line-b"
                      />
                    </h4>
                    <p class="whitespace-pre-line">
                      {{
                        $t(
                          'pages.vault.text.everWonderHowYouCanPassOnDetailsOfYourCryptocurren'
                        )
                      }}
                    </p>
                  </div>
                </div>
                <div class="grid-item flex w-6/12 xl:w-7/12">
                  <div class="relative flex lg:pb-20">
                    <div
                      class="flex overflow-hidden rounded-xl lg:rounded-t-none w-full"
                    >
                      <BaseAsset
                        :asset="contentImage[0]"
                        fit="cover"
                        class="w-full"
                      />
                    </div>
                    <div
                      class="absolute inset-0 flex justify-center items-center"
                    >
                      <div
                        class="flex justify-center w-4/5 lg:w-2/3 -mt-8 md:-mt-20 lg:mt-auto lg:ml-auto lg:mr-4 xl:mr-1 mb-auto lg:mb-10 xl:mb-32"
                      >
                        <AppCardVault class="w-4/5" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container mb-4 md:mb-8">
      <div class="grid-container">
        <div class="grid-item w-full">
          <div
            class="rounded-xl bg-teal-100 p-6 pt-0 md:p-20 md:pt-0 lg:p-16 lg:pt-0 xl:p-12 xl:pt-0"
          >
            <BaseHeader>
              <template #left>
                <h4
                  class="mb-5 xl:mb-8 heading-5 md:heading-4 lg:heading-5 xl:heading-4"
                >
                  <MarkedText
                    text="Digital vaults for modern <em>life.</em>"
                    class="text-white"
                    stroke-width="20"
                    type="line-b"
                  />
                </h4>
                <p class="body-5 xl:body-3">
                  {{
                    $t(
                      'pages.vault.text.inTheModernAgeOfDigitalAccountsSocialMediaBankingA'
                    )
                  }}
                </p>
              </template>
              <template #right>
                <BaseAsset
                  v-if="isRegistered"
                  :asset="headerImage[0]"
                  :crop="hasMobileLayout"
                  class="lg:mt-12 xl:mt-16"
                  rounded
                  sizes="(min-width: 1440px) 600px, (min-width: 1280px) 42vw, (min-width: 1024px) 45vw, (min-width: 768px) 67vw, 85vw"
                />
                <div v-else class="lg:mt-12 xl:mt-16 flex md:justify-center">
                  <div class="flex flex-col justify-start space-y-3">
                    <p>{{ $t('pages.vault.text.signupNowToFindOutMore') }}</p>
                    <iframe
                      class="mj-w-res-iframe"
                      title="mj-widget-iframe-footer"
                      src="https://app.mailjet.com/widget/iframe/71Ek/NkA"
                      width="100%"
                    ></iframe>
                  </div>
                </div>
              </template>
            </BaseHeader>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseHeader from '~/components/BaseHeader';
import MarkedText from '~/components/MarkedText';
import BaseAsset from '~/components/BaseAsset';
import SectionHeading from '~/components/SectionHeading';
import TextMarker from '~/components/TextMarker';
import ReviewSlider from '~/components/ReviewSlider';
import AppCardVault from '~/components/AppCardVault';

export default {
  name: 'Vault',
  components: {
    BaseHeader,
    MarkedText,
    BaseAsset,
    SectionHeading,
    TextMarker,
    ReviewSlider,
    AppCardVault,
  },
  data() {
    return {
      email: '',
      hasError: false,
      isLoading: false,
      isRegistered: false,
      headerContent: {
        heading: '<p>Secure your digital <em>assets</em></p>',
        body: 'Safewill will be releasing a way for you to secure your digital assets, passwords, logins and other digital items. These will only be seen and accessed by your benefactor upon your passing. Be the first to know when we release this.',
      },
      headerImage: [
        {
          filename: 'Vault-Header-Image.png',
          kind: 'image',
          lg: 'https://assets.safewill.com/images/Vault-Header-Image.png',
          md: 'https://assets.safewill.com/images/Vault-Header-Image.png',
          original: 'https://assets.safewill.com/images/Vault-Header-Image.png',
          sm: 'https://assets.safewill.com/images/Vault-Header-Image.png',
          title: 'Vault Header Image',
          xl: 'https://assets.safewill.com/images/Vault-Header-Image.png',
          xs: 'https://assets.safewill.com/images/Vault-Header-Image.png',
        },
      ],
      contentImage: [
        {
          filename: 'Vault-Content-Image.png',
          kind: 'image',
          lg: 'https://assets.safewill.com/images/Vault-Content-Image-01.png',
          md: 'https://assets.safewill.com/images/Vault-Content-Image-01.png',
          original:
            'https://assets.safewill.com/images/Vault-Content-Image-01.png',
          sm: 'https://assets.safewill.com/images/Vault-Content-Image-01.png',
          title: 'Vault Content Image',
          xl: 'https://assets.safewill.com/images/Vault-Content-Image-01.png',
          xs: 'https://assets.safewill.com/images/Vault-Content-Image-01.png',
        },
      ],
      mission: [{ heading: '', statements: [] }],
      newsItems: [],
      team: [],
      teamSliderDirection: null,
    };
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
    payload() {
      return {
        email: this.email,
      };
    },
  },
  mounted() {
    const hash = this.$route?.hash.substr(1);
    if (hash === 'registered') {
      this.isRegistered = true;
    }
  },
  head() {
    return {
      script: [
        {
          src: 'https://app.mailjet.com/statics/js/iframeResizer.min.js',
        },
      ],
    };
  },
};
</script>

<style>
.mj-w-res-iframe {
  padding: 0;
  margin: 0;
  width: 100%;
}
</style>
