<template>
  <LegalAdviceContent :entry="entry" />
</template>

<script>
import LegalAdviceContent from '~/components/LegalAdviceContent';

import GET_LEGAL_ADVICE_QUERY from '~/graphql/queries/GetLegalAdvice';
import { craft, home, seomatic, transition, partnerships } from '~/mixins';
import { transformLegalAdviceData } from '~/util';

export default {
  name: 'PageHome',
  components: {
    LegalAdviceContent,
  },
  mixins: [
    craft({
      query: GET_LEGAL_ADVICE_QUERY,
      transform: transformLegalAdviceData,
    }),
    home,
    seomatic,
    transition,
    partnerships,
  ],
  data() {
    return {
      entry: null,
    };
  },
};
</script>
