<template>
  <div v-scroll="{ callback: onScroll }" class="ru:header">
    <div class="ru:header__content container">
      <nuxt-link
        :to="logoLink"
        :aria-label="logoAriaLabel"
        class="text-navy-200 flex-shrink-0"
      >
        <LogoBlog v-if="hasBlogLayout" />
        <Logo v-else class="text-navy-200" />
      </nuxt-link>
      <Navigation
        :mobile-menu-is-open="mobileMenuIsOpen"
        @toggleMobileMenu="toggleMobileMenu"
      />
      <MenuButton
        :mobile-menu-is-open="mobileMenuIsOpen"
        @click="toggleMobileMenu"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Logo from '~/components/Logo';
import LogoBlog from '~/components/LogoBlog';
import MenuButton from '~/components/MenuButton';
import Navigation from '~/components/organisms/Navigation';

export default {
  name: 'OrganismsHeader',
  components: {
    Logo,
    LogoBlog,
    MenuButton,
    Navigation,
  },
  data() {
    return {
      hasScrolled: false,
      mobileMenuIsOpen: false,
    };
  },
  computed: {
    ...mapGetters(['hasOffer', 'isReturning']),
    hasBlogLayout() {
      return this.$route.fullPath.includes('/blog');
    },
    logoAriaLabel() {
      return this.hasBlogLayout ? 'blog' : 'homepage';
    },
    logoLink() {
      return this.localePath(this.hasBlogLayout ? '/blog' : '/');
    },
    text() {
      return this.hasBlogLayout
        ? 'Use Safewill to write your own will online. Today.'
        : 'Australia’s most trusted will writing platform.';
    },
  },
  watch: {
    activeLinkIndex(to, from) {
      this.setTransitionDirection({ from, to });
    },
  },
  mounted() {
    this.$scroll.trigger();
  },
  methods: {
    ...mapActions(['setTransitionDirection']),
    toggleMobileMenu() {
      this.mobileMenuIsOpen = !this.mobileMenuIsOpen;
    },
    onScroll(scrollTop) {
      document.documentElement.classList.toggle(
        '--has-scrolled',
        scrollTop > 0
      );
    },
  },
};
</script>

<style lang="scss">
$navigation-max-width: 1010px;
.ru\: {
  &header {
    transition: --out();
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 40;
    font-size: 1.125rem;
    @include mq('min-lg') {
      font-size: 1.25rem;
    }

    &::before {
      transition: --out();
      display: block;
      content: '';
      position: absolute;
      inset: 0;
      z-index: -1;
      background-image: linear-gradient(
        to bottom,
        --rgba(water) #{calc(50% + 1px)},
        --rgba(water, 0.75)
      );
      backdrop-filter: blur(1rem);
      @at-root .--has-scrolled & {
        transition: --in();
        transform: translateY(-2rem);
      }
    }

    &__content {
      position: relative;
      display: flex;
      align-items: center;
      height: 6.5rem;
      padding: 0 #{calc(var(--gutter-outer) + var(--gutter-inner))};
      transition: --out();
      @include mq('min-lg') {
        height: 9rem;
      }
      @at-root .--has-scrolled & {
        transition: --in();
        transform: translateY(-1rem);
      }
    }
  }
}
</style>
